import React, { useEffect, useState } from 'react';
import router from 'next/router';
import { Label, RadioGroup } from '@ariessolutionsio/primitives-richproducts';
import { useAsio } from '@ariessolutionsio/providers-richproducts';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import useSWR, { mutate } from 'swr';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';
import { useAccount } from 'frontastic/provider';
import Sidemenu from '../details/side-menu';

const EditEmailSubscriptionTastic: React.FC = () => {
  const queryClient = useQueryClient();
  const [emailSubscription, setEmailSubscription] = useState(undefined);
  const { account } = useAccount();
  const [{ customers }] = useAsio();
  const [loading, setLoading] = useState(false);
  const { data: meData } = useSWR('me', () => customers.me());

  const subscribeToKlaviyo = async (emailAddress: string) => {
    {
      const res = await fetchApiHub('/action/klaviyo/emailSubscription', { method: 'POST' }, { email: emailAddress });
      if (res.success) {
        return true;
      }
      return false;
    }
  };

  const unSubscribeToKlaviyo = async (emailAddress: string) => {
    {
      const res = await fetchApiHub('/action/klaviyo/emailUnSubscription', { method: 'POST' }, { email: emailAddress });
      if (res.success) {
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    if (meData) {
      const { isSubscribedToNewsletter } = meData?.metadata ?? {
        meData: { metadata: { isSubscribedToNewsletter: undefined } },
      };
      if (typeof isSubscribedToNewsletter === 'boolean') {
        setEmailSubscription(isSubscribedToNewsletter ? 'true' : 'false');
      } else {
        setEmailSubscription(undefined);
      }
    }
  }, [meData]);

  const onSubmit = async () => {
    setLoading(true);
    if (meData) {
      try {
        const response = await customers.updateProfileInfo({
          customer: {
            firstName: meData.firstName,
            lastName: meData.lastName,
            middleName: '',
            dateOfBirth: meData.dateOfBirth,
            version: meData?.version || 1,
            metadata: {
              isSubscribedToNewsletter: JSON.parse(emailSubscription || 'false'),
            },
          },
          customerVersion: meData?.version || 1,
        } as any);
        if (response) {
          // Get the updated account data for my dashboard page, once the email subscription is updated
          // Todo; need to check better way, instead of mutating the account data in this component.
          await mutate('/action/account/getAccount', {
            ...account,
            isSubscribedToNewsletter: JSON.parse(emailSubscription || 'false'),
          });
          let emailSubRes;
          if (emailSubscription == 'true') {
            emailSubRes = await subscribeToKlaviyo(meData.email);
          } else {
            emailSubRes = await unSubscribeToKlaviyo(meData.email);
          }

          setLoading(false);
          if (emailSubRes) {
            queryClient.invalidateQueries({ queryKey: ['get:account'] });
            toast.success('Email subscription updated');
            router.push('/my-account');
          } else {
            setLoading(false);
            toast.error('Error updating Email subscription');
          }
        } else {
          setLoading(false);
          toast.error('Error updating Email subscription');
        }
      } catch (error) {
        setLoading(false);
        toast.error('Error updating Email subscription');
      }
    }
    setLoading(false);
  };
  return (
    <>
      <Sidemenu backButton={{ title: 'My Account', href: '/my-account' }}>
        <div className="mt-0 space-y-11 bg-white p-[30px] lg:ml-6 lg:space-y-[54px] lg:p-[60px]">
          <div>
            <p className="mb-[30px] font-['Akshar'] text-4xl font-bold lg:mb-10">Email Newsletter Subcription</p>
            <div className="space-y-5">
              <div className="my-4 flex h-auto w-full flex-col flex-wrap items-start rounded-sm border lg:flex-row lg:items-center">
                <RadioGroup
                  defaultValue="standard"
                  value={emailSubscription}
                  className="flex flex-col flex-wrap gap-0 lg:flex-row"
                  onValueChange={(value) => setEmailSubscription(value)}
                >
                  <div className="flex w-full flex-row items-center space-x-5 border-b p-6 text-gray-900">
                    <RadioGroup.Item className="border-gray-900 text-gray-900" value={'true'} id="r1"></RadioGroup.Item>
                    <Label htmlFor="r1" className="flex flex-row items-center">
                      <p className={'text-[16px] text-gray-900'}>
                        <b>Send Me Email Newsletter Updates.</b>
                      </p>
                    </Label>
                  </div>
                  <div className="flex w-full flex-row items-center space-x-5  p-6 text-gray-900">
                    <RadioGroup.Item
                      className="border-gray-900 text-gray-900"
                      value={'false'}
                      id="r2"
                    ></RadioGroup.Item>
                    <Label htmlFor="r2" className="flex flex-row items-center">
                      <p className={'text-[16px] text-gray-900'}>No, I do not wish to receive emails.</p>
                    </Label>
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>
          <nav className="flex w-full space-x-4">
            <Button
              variant="outlined"
              onClick={() => router.push('/my-account')}
              palette="black"
              className="font-semibold"
              type="reset"
            >
              Cancel
            </Button>
            <Button
              palette="black"
              type="submit"
              onClick={onSubmit}
              disabled={loading}
              className={`bg-gray-900 text-white ${loading ? 'pointer-events-none cursor-not-allowed' : ''}`}
            >
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </nav>
        </div>
      </Sidemenu>
    </>
  );
};

export default EditEmailSubscriptionTastic;
