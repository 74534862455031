import { FC, useState } from 'react';
import { Address } from '@ariessolutionsio/providers-richproducts';
// import { a } from '@ariessolutionsio/providers-richproducts/dist/index-a27a2ef1';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { LineItem } from '@ariessolutionsio/react-ecomm-ui/dist/types/cart/LineItem';
import { Product } from '@ariessolutionsio/react-ecomm-ui/dist/types/product/Product';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useRecipientsBookDialog } from 'hooks/dialogs/recipients-book/recipients-book';
import _, { add } from 'lodash';
import { useCart } from 'frontastic/provider';
import { OrderDeliveryItem, useCheckoutContext } from 'frontastic/tastics/checkout/contexts/checkout.context';

export interface ProductTileProps {
  orderDeliveryItem: OrderDeliveryItem;
  provinces: any[];
}

export const ProductTile: FC<ProductTileProps> = ({ orderDeliveryItem, provinces }) => {
  const [selectedQty, setSelectedQty] = useState(orderDeliveryItem.quantity);
  const cart = useCart();
  const [{ orderDelivery }, { setOrderDelivery }] = useCheckoutContext();

  const quantityPlanning = orderDeliveryItem.shippingPlanning.reduce((acc, item) => acc + item.quantity, 0);

  const RecipientsBookDialog = useRecipientsBookDialog({
    title: 'Select Recipients',
    states: provinces,
    orderDeliveryItem,
    onSingleSelect: (address) => {
      const idx = orderDelivery.findIndex((item) => item.lineItem.lineItemId === orderDeliveryItem.lineItem.lineItemId);
      const newOrderDelivery = [...orderDelivery];
      newOrderDelivery[idx].address = address;
      setOrderDelivery(newOrderDelivery);
    },
    onSelectedItemInMultiSelect(address: Address, quantity: number) {
      const idx = orderDelivery.findIndex((item) => item.lineItem.lineItemId === orderDeliveryItem.lineItem.lineItemId);
      const newOrderDelivery: OrderDeliveryItem = orderDelivery[idx];

      const idxAddress = newOrderDelivery.shippingPlanning.findIndex((item) => item.address.id === address.id);

      if (idxAddress === -1) {
        newOrderDelivery.shippingPlanning.push({
          address,
          quantity,
        });
      }

      if (idxAddress !== -1) {
        newOrderDelivery.shippingPlanning[idxAddress].quantity = quantity;
      }

      if (idxAddress !== -1 && quantity === 0) {
        newOrderDelivery.shippingPlanning.splice(idxAddress, 1);
      }

      const _orderDelivery = [...orderDelivery];
      _orderDelivery[idx] = newOrderDelivery;

      setOrderDelivery(_orderDelivery);
    },
    onChangeSelectable(address: Address, checked: boolean) {
      const idx = orderDelivery.findIndex((item) => item.lineItem.lineItemId === orderDeliveryItem.lineItem.lineItemId);
      const newOrderDelivery = [...orderDelivery];
      const _address = newOrderDelivery[idx].shippingPlanning.find((item) => item.address.id === address.id);

      if (!_address && checked) {
        newOrderDelivery[idx].shippingPlanning.push({
          address,
          quantity: 1,
        });
      }
      if (address && !checked) {
        const idxAddress = newOrderDelivery[idx].shippingPlanning.findIndex((item) => item.address.id === address.id);
        newOrderDelivery[idx].shippingPlanning.splice(idxAddress, 1);
      }

      setOrderDelivery(newOrderDelivery);
    },
  });

  const IncrementDecrement = () => {
    return (
      <div className="flex items-center justify-center">
        <button
          type="button"
          className="h-12 w-12 rounded-l-lg border bg-white active:bg-gray-100"
          onClick={() => {
            if (quantityPlanning < selectedQty && selectedQty > 1) {
              cart.updateItem(orderDeliveryItem.lineItem.lineItemId, selectedQty - 1);
              setSelectedQty(selectedQty - 1);
            } else 1;
          }}
        >
          -
        </button>

        <div className="flex h-12 w-12 items-center justify-center border-y ">
          <input
            className="h-12 w-12 border-y text-center !outline-none "
            pattern="\d*"
            disabled
            onChange={(e) => !isNaN(e.target.value as any) && setSelectedQty(parseInt(e.target.value))}
            value={isNaN(selectedQty) ? 0 : selectedQty}
          ></input>
        </div>

        <button
          type="button"
          onClick={() => {
            cart.updateItem(orderDeliveryItem.lineItem.lineItemId, selectedQty + 1);
            setSelectedQty(selectedQty + 1);
          }}
          className="h-12 w-12 rounded-r-lg border bg-white active:bg-gray-100"
        >
          +
        </button>
      </div>
    );
  };

  const ShippingTo = () => {
    return (
      <div className="grid grid-cols-12">
        <section className="col-span-4 lg:col-span-2">
          <div>Shipping To</div>
          <div>{`(${orderDeliveryItem.shippingPlanning.map((s) => s.quantity).reduce((acc, val) => (acc += val))}/${
            orderDeliveryItem.quantity
          })`}</div>
        </section>
        <section className="col-span-8 lg:col-span-5">
          <Typography variant="body" className="font-bold">
            {orderDeliveryItem.shippingPlanning
              .map((sp) => sp.address.firstName + ' ' + sp.address.lastName)
              .join(', ')}
          </Typography>
        </section>
        <section className="col-span-12 flex justify-center lg:col-span-5">
          <ButtonAdd />
        </section>
      </div>
    );
  };

  const ButtonAdd: FC<{
    showIcon?: boolean;
  }> = ({ showIcon = false }) => {
    return (
      <div
        onClick={() => RecipientsBookDialog.open()}
        className="flex w-fit cursor-pointer flex-row rounded-md border bg-[#2B2A2D] px-6 py-4 font-semibold text-white hover:bg-[#2B2A2D] hover:text-white"
      >
        {showIcon && <PlusIcon className="mr-1 h-4 w-4" />}
        <p className="text-sm">{!showIcon ? 'ADD OR EDIT RECIPIENT' : 'ASSIGN RECIPIENT'}</p>
      </div>
    );
  };

  return (
    <>
      <div className=" rounded-lg border  ">
        <div className="flex space-x-3 px-6 py-8">
          {orderDeliveryItem.lineItem.variant?.images && (
            <div className="h-[80px] w-[80px] flex-none">
              <img
                src={orderDeliveryItem.lineItem.variant?.images[0]}
                alt={orderDeliveryItem.lineItem.name}
                className="h-full w-full object-cover"
              />
            </div>
          )}
          <div className="flex w-full flex-col space-y-1 lg:flex-row lg:justify-between">
            <div>
              <p className="text-lg">{orderDeliveryItem.lineItem.name}</p>
              {orderDeliveryItem.lineItem.variant?.attributes?.cookie_flavor?.label && (
                <p className="text-sm">
                  <span>Assortment: </span>
                  <span className="font-semibold">
                    {orderDeliveryItem.lineItem.variant?.attributes.cookie_flavor.label}
                  </span>
                </p>
              )}
              {orderDeliveryItem.lineItem.variant?.attributes?.tin_color?.label && (
                <p className="text-sm">
                  <span>Tin Color: </span>
                  <span className="font-semibold">
                    {orderDeliveryItem.lineItem.variant?.attributes.tin_color.label}
                  </span>
                </p>
              )}
            </div>
            <div>
              <IncrementDecrement />
            </div>
          </div>
        </div>
        <div className="border-t"></div>
        <div className="px-6 py-8">
          {!_.isEmpty(orderDeliveryItem.shippingPlanning) && <ShippingTo />}
          {_.isEmpty(orderDeliveryItem.shippingPlanning) && (
            <div className="flex w-full justify-center">
              <ButtonAdd showIcon />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
