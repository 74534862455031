import { useCart } from 'frontastic/provider';

export const ProductTiles = () => {
  const { data: order } = useCart();

  return (
    <>
      {order && order.lineItems.length > 0 && (
        <div className="mt-5 space-y-4">
          {order.lineItems.map((item) => (
            <>
              <div className="rounded-lg border border-[#B4B2B7] p-2.5 lg:px-8 lg:py-9">
                <div className="flex">
                  <img
                    src={item.variant.images[0]}
                    alt={item.name}
                    className="mr-3 h-[60px] w-[60px] object-cover lg:h-[83px] lg:w-[83px]"
                  />
                  <div>
                    <p className="leading-6">{item.name}</p>
                    <div className="mt-1.5">
                      {item.variant.attributes.cookie_flavor && (
                        <div className="space-x-1 text-xs">
                          <span>Assortment:</span>
                          <span>{item.variant.attributes.cookie_flavor.label}</span>
                        </div>
                      )}
                      {item.variant.attributes.tin_color && (
                        <div className="space-x-1 text-xs">
                          <span>Tin Color:</span>
                          <span>{item.variant.attributes.tin_color.label}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      )}
    </>
  );
};
