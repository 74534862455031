import { Button, Dialog, useDialogContext, useDialog } from '@ariessolutionsio/primitives-richproducts';

const ErrorMessageDialog = () => {
  const [, { close }] = useDialogContext();
  return (
    <Dialog>
      <Dialog.Content className="border-black bg-white">
        <Dialog.Header>
          <Dialog.Title>Tile</Dialog.Title>
        </Dialog.Header>
        sdfasdf
      </Dialog.Content>
    </Dialog>
  );
};

export function useErrorDialog() {
  return useDialog(<ErrorMessageDialog />);
}
