import { FC } from 'react';
import { TitleWithPanels } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/incentives/title-with-panels';

export const IncentiveTitleWithPanels: FC = (props: any) => {
  const {
    data: { title, description, panels, theme },
  } = props;

  return (
    <div>
      <TitleWithPanels
        data={{
          title,
          description,
          panels,
          theme,
        }}
      />
    </div>
  );
};
