import { Dialog, Form, useDialogContext, Button as AsioButton } from '@ariessolutionsio/primitives-richproducts';
import { useToast } from '@ariessolutionsio/react-ecomm-ui/dist';
import { AddressForm } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/forms/address';
import { addressFormSchema } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/forms/schemas/address.schema';
import { cn } from '@ariessolutionsio/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { genGenIDNumber } from './utils';
import { useAddAddress } from '@/hooks/my-account/address/use-add-address';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import useScreenOrientation from '@/hooks/useOrientation';

const schema = yup
  .object()
  .shape({
    addressFormValues: addressFormSchema, //MONKEYPATCH: @Variaban - this is the only way to get this to work. Please check this before merging.
  })
  .required();

type AddressFormSchemaType = yup.InferType<typeof schema>;

export type CreateAddressDialogProps = {
  states: any[];
  onClose?: () => void;
};

export const CreateAddressDialog = ({ states, onClose, ...props }: CreateAddressDialogProps) => {
  const { mutateAsync: addAddress, isLoading: isAddAddressLoading } = useAddAddress();
  const [, { close }] = useDialogContext();

  const { isMobile } = useDeviceDetect();
  const orientation = useScreenOrientation();

  const { toast } = useToast();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      addressFormValues: {},
    },
  });

  const onSubmit = async (values: AddressFormSchemaType) => {
    const addressId = await genGenIDNumber('ADDRESS', toast);
    const { addressFormValues } = values;

    const { company, streetAddress: streetName, zipCode: postalCode, apartment, ...formValues } = addressFormValues;

    const responseAddAddress = await addAddress({
      ...formValues,
      company,
      streetName,
      streetNumber: '',
      postalCode,
      country: 'US',
      additionalStreetInfo: apartment,
      city: addressFormValues.city,
      state: addressFormValues.state,
      firstName: addressFormValues.firstName,
      lastName: addressFormValues.lastName,
      key: addressId.toString() ?? undefined,
    });

    if (!responseAddAddress.isError) {
      if (onClose) onClose();
      close();
    }
  };

  return (
    <FormProvider {...form}>
      <Dialog {...props}>
        <Dialog.Content
          className={cn(
            orientation === 'landscape-primary' && isMobile
              ? 'top-0 flex h-full flex-initial flex-col overflow-scroll'
              : 'h-full max-h-fit min-w-fit md:h-auto',
          )}
        >
          <Dialog.Header className="my-3 flex flex-row justify-center md:my-6">
            <Dialog.Title className="text-3xl leading-6">Add New Recipient/Address</Dialog.Title>
          </Dialog.Header>
          <Form
            onSubmit={form.handleSubmit(onSubmit)}
            actions={
              <nav className="flex flex-row justify-center">
                <AsioButton type="button" variant="link" onClick={close} className="mr-2 py-6">
                  Cancel
                </AsioButton>
                <AsioButton loading={isAddAddressLoading} type="submit" className="bg-gray-900 text-white">
                  {isAddAddressLoading ? 'submitting...' : 'SAVE CHANGES'}
                </AsioButton>
              </nav>
            }
          >
            <AddressForm form={form} schemaKey="addressFormValues" states={states} title="" />
          </Form>
        </Dialog.Content>
      </Dialog>
    </FormProvider>
  );
};
