import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Select } from '@ariessolutionsio/primitives-richproducts';
import { cn } from '@ariessolutionsio/react-ecomm-ui/dist/utils/classNames';
import { AccountTab } from './side-menu';

export interface Props {
  hash: string | undefined;
  tabs: AccountTab[];
  className?: string;
}

const AccountTabsMobile: FC<Props> = ({ hash, tabs, className = '' }) => {
  const forms = ['#edit-personal-info', '#edit-newsletter', '#edit-address', '#change-password', '#delete-account'];
  const accountNavButtonClassNames = cn([
    hash && forms.includes(hash) ? 'hidden' : 'relative lg:hidden',
    'px-3',
    className,
  ]);
  const router = useRouter();
  const [selected, setSelected] = useState<AccountTab | undefined>(
    tabs.find((tab) => tab.value === router.asPath) ?? tabs[0],
  );

  const onChange = (path: string) => {
    router.push(path);
  };

  useEffect(() => {
    setSelected(tabs.find((tab) => tab.value === router.asPath) ?? tabs[0]);
  }, [router.asPath, tabs]);

  return (
    <div className={accountNavButtonClassNames}>
      <Select value={selected.label} onValueChange={onChange} autoComplete="on">
        <Select.Trigger
          aria-label="tabs"
          className="mx-auto w-full rounded border border-gray-400 focus:outline-none sm:w-1/2"
        >
          {selected.label}
        </Select.Trigger>

        <Select.Content className="z-50 -my-1 max-h-64 overflow-y-auto">
          <Select.Group>
            {tabs.map((tab, index) => (
              <Select.Item key={`${tab.label}-${index}`} value={tab.value}>
                <div>{tab.label}</div>
              </Select.Item>
            ))}
          </Select.Group>
        </Select.Content>
      </Select>
    </div>
  );
};

export default AccountTabsMobile;
