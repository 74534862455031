import { FC } from 'react';
// import { With5050Split } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/incentives/with-5050-split';
import { Markdown } from '@ariessolutionsio/primitives-richproducts';
import { LinkButton } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/LinkButton';
import { useSignInDialog } from 'hooks/dialogs/auth/sign-in';
import { useAccount, useCart } from 'frontastic/provider';

export const IncentiveWith5050Split: FC<any> = (props) => {
  const { loggedIn } = useAccount();
  const { data } = useCart();
  const SignIn = () => {
    if (!loggedIn) {
      return (
        <>
          <LinkButton
            size="md"
            palette="black"
            shape="md"
            className="my-4 w-full text-xs text-[#2B2A2D] ring-[#2B2A2D] md:w-auto"
            variant="outlined"
            onClick={() => signInDialog.open()}
          >
            Sign In
          </LinkButton>
          <p className="text-xs md:text-base">
            No Account? No problem,
            <a href="/my-account/create" className="text-primary-800">
              {' '}
              create one here!
            </a>
          </p>
        </>
      );
    }
  };
  const signInDialog = useSignInDialog({
    title: 'Sign In to Your Account',
  });

  const styleClass = data?.lineItems?.length > 0 ? 'lg:ml-8 mt-14 px-6 lg:px-8' : 'mt-14 px-6 lg:px-8';

  return (
    <div className={styleClass}>
      <div className="p-5">
        <div className="space-y-2 md:my-8 md:mt-11 md:space-y-1 md:text-center">
          <p className="font-galanoClassic text-xl font-semibold md:text-2xl">{props.data?.title}</p>
          <p>{props.data?.description}</p>
        </div>
        <div className="mt-4 md:mx-20 md:mb-14 md:flex">
          <div className="flex-1">
            {props.data?.leftText ? <Markdown className="markDownTitle prose">{props.data?.leftText}</Markdown> : null}
            <SignIn />
          </div>
          <div className="border-black mx-16 md:border md:border-l-0"></div>
          <hr className="border-black my-4 md:hidden" />
          <div className="flex-1">
            <div>
              {props.data?.rightText ? (
                <Markdown className="markDownTitle prose">{props.data?.rightText}</Markdown>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
