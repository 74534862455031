import React, { useState } from 'react';
import router from 'next/router';
import { Address } from '@ariessolutionsio/providers-richproducts';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { useFormat } from 'helpers/hooks/useFormat';
import _ from 'lodash';
import { SingleAddressAddEdit } from './singleAddressAddEdit';
import Sidemenu from '../side-menu';
import Spinner from '@/components/commercetools-ui/spinner';
import { useRemoveAddress } from '@/hooks/my-account/address/use-remove-address';
import { useGetAccount } from '@/hooks/my-account/use-get-account';

const AddressesTastic: React.FC = (props: any) => {
  const { data, isLoading } = useGetAccount();

  const { mutateAsync: removeAddress } = useRemoveAddress();

  const [isAddEditAddress, setIsAddEditAddress] = useState<boolean>(false);
  const [operationType, setOperationType] = useState<string>('Add');
  const [addressToEdit, setAddressToEdit] = useState<Address>();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const contentTitle = formatAccountMessage({ id: 'address.book', defaultMessage: 'My Addresses' });

  let provincesList = props.data?.provinces?.dataSource?.value;
  if (provincesList) {
    provincesList = Object.keys(provincesList).map((key) => ({ key, value: provincesList[key] }));
  }

  const handleUpdateAddress = async (a) => {
    setAddressToEdit(a);
    setIsAddEditAddress(true);
    setOperationType('Edit');
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Sidemenu hash={'/my-account/addresses'} contentTitle={!isAddEditAddress && contentTitle}>
        <div className="mt-0 space-y-0 bg-white px-6 py-8 lg:ml-0 lg:space-y-[0px] lg:p-[50px]">
          {!isAddEditAddress && (
            <>
              <nav className="flex w-full flex-col space-y-2 pb-6 md:flex-row md:space-x-4 md:space-y-0">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOperationType('Add');
                    setIsAddEditAddress(true);
                  }}
                  palette="black"
                  className="font-semibold"
                  type="reset"
                >
                  + ADD NEW RECIPIENT / ADDRESS
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => router.push('/my-account/addresses/upload-csv')}
                  palette="black"
                  className="font-semibold"
                  type="reset"
                >
                  UPLOAD ADDRESS (.CSV)
                </Button>
              </nav>
              <div className="p-3.5 text-left text-sm text-[#000000]">Name & Address</div>
            </>
          )}

          {isAddEditAddress && (
            <SingleAddressAddEdit
              handleParentCallback={(e) => {
                setIsAddEditAddress(false);
              }}
              addressToEdit={addressToEdit}
              operationType={operationType}
              handleReturnToParent={() => setIsAddEditAddress(false)}
              provincesList={provincesList ?? []}
            />
          )}
          {!isAddEditAddress &&
            data?.addresses?.length > 0 &&
            _.sortBy(data.addresses, (address) => address.lastName.toLowerCase()).map((a, i) => (
              <div
                key={i}
                className="flex w-auto justify-between space-x-0 border-t bg-[#ffff] p-3.5 text-left text-sm  text-[#000000] hover:bg-[#F9F9F9] md:items-center md:space-x-1"
              >
                <p className="gap-1 break-all">
                  <strong>{`${a.firstName ?? ''} ${a.lastName ?? ''}`}</strong> <br className="md:hidden" />
                  {`${a?.streetNumber ?? ''} ${a?.streetName ?? ''}`} <br className="md:hidden" />{' '}
                  {`${a.additionalStreetInfo !== undefined ? a.additionalStreetInfo : ''} `}{' '}
                  <br className="md:hidden" /> {` ${a?.city ?? ''}  `} <br className="md:hidden" />{' '}
                  {`${a?.state ?? ''}  `} <br className="md:hidden" /> {`${a?.postalCode ?? ''}`}
                </p>
                <div className="flex items-center space-x-5">
                  <span className="float-right m-1 space-x-5 hover:text-[#F9F9F9]">
                    <button
                      className="group relative -m-2 flex items-center justify-center space-x-1 py-2"
                      onClick={() => handleUpdateAddress(a)}
                    >
                      {EditIcon}
                      <span className="ml-2 hidden h-full items-center whitespace-nowrap text-center align-middle text-xs font-semibold text-primary-800 md:flex">
                        Edit
                      </span>
                    </button>
                  </span>
                  <span className="float-right space-x-5 pl-1 hover:text-[#F9F9F9]">
                    <button
                      className="group relative -m-1 flex items-center justify-center space-x-1 py-2"
                      onClick={() =>
                        removeAddress({
                          addressId: a.addressId,
                        })
                      }
                    >
                      {DeleteIcon}
                      <span className="ml-2 hidden h-full items-center whitespace-nowrap text-center align-middle text-xs font-semibold text-primary-800 md:flex">
                        Delete
                      </span>
                    </button>
                  </span>
                </div>
              </div>
            ))}
        </div>
      </Sidemenu>
    </>
  );
};

export default AddressesTastic;

export const EditIcon = (
  <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0997 7.70599L8.94087 15.2833C8.67056 15.5711 8.40897 16.1378 8.35666 16.5302L8.03403 19.3554C7.92068 20.3756 8.65312 21.0731 9.66459 20.8987L12.4723 20.4192C12.8647 20.3494 13.414 20.0617 13.6843 19.7652L20.8431 12.1879C22.0813 10.8799 22.6393 9.38887 20.7123 7.56648C18.794 5.76153 17.3378 6.39806 16.0997 7.70599Z"
      stroke="#B7002A"
      strokeWidth="1.04635"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.905 8.9707C15.0878 10.1399 15.6537 11.2152 16.5138 12.028C17.3739 12.8408 18.4796 13.3449 19.6572 13.4613M7.15332 23.7504H22.8486"
      stroke="#B7002A"
      strokeWidth="1.04635"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DeleteIcon = (
  <svg width="11" height="16" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5874_5133)">
      <path
        d="M1.17052 4.97086H1.52157L2.53572 17.8549C2.59423 18.6352 3.23782 19.2354 3.99843 19.2354H11.9946C12.7552 19.2354 13.3988 18.6352 13.4573 17.8549L14.4715 4.97086H14.8225C15.4661 4.97086 15.9927 4.43069 15.9927 3.77048C15.9927 3.11027 15.4661 2.5701 14.8225 2.5701H10.6294V2.04994C10.6294 1.34971 10.0638 0.769531 9.38122 0.769531H6.61181C5.92921 0.769531 5.36363 1.34971 5.36363 2.04994V2.5701H1.17017C0.526577 2.5701 0 3.11027 0 3.77048C0 4.43069 0.526577 4.97086 1.17017 4.97086H1.17052ZM12.6772 17.7949C12.6577 18.155 12.3457 18.4351 11.9946 18.4351H3.99843C3.64738 18.4351 3.33534 18.155 3.31583 17.7949L2.30168 4.97086H13.6718L12.6772 17.7949ZM6.14375 2.06994C6.14375 1.80986 6.35828 1.58979 6.61181 1.58979H9.38122C9.63476 1.58979 9.84929 1.80986 9.84929 2.06994V2.59011H6.14375V2.06994ZM1.17052 3.37035H14.8225C15.037 3.37035 15.2126 3.55041 15.2126 3.77048C15.2126 3.99055 15.037 4.1706 14.8225 4.1706H1.89212H1.17052C0.955988 4.1706 0.780462 3.99055 0.780462 3.77048C0.780462 3.55041 0.955988 3.37035 1.17052 3.37035Z"
        fill="#B7002A"
      />
      <path
        d="M5.52207 17.2507C5.7367 17.2507 5.91231 17.0706 5.91231 16.8506V6.36976C5.91231 6.14974 5.7367 5.96973 5.52207 5.96973C5.30744 5.96973 5.13184 6.14974 5.13184 6.36976V16.8506C5.13184 17.0706 5.30744 17.2507 5.52207 17.2507Z"
        fill="#B7002A"
      />
      <path
        d="M8.0001 17.3512C8.21473 17.3512 8.39033 17.1712 8.39033 16.9512V6.47035C8.39033 6.25033 8.21473 6.07031 8.0001 6.07031C7.78547 6.07031 7.60986 6.25033 7.60986 6.47035V16.9512C7.60986 17.1712 7.78547 17.3512 8.0001 17.3512Z"
        fill="#B7002A"
      />
      <path
        d="M10.4786 17.4509C10.6932 17.4509 10.8688 17.2708 10.8688 17.0508V6.56995C10.8688 6.34994 10.6932 6.16992 10.4786 6.16992C10.264 6.16992 10.0884 6.34994 10.0884 6.56995V17.0508C10.0884 17.2708 10.264 17.4509 10.4786 17.4509Z"
        fill="#B7002A"
      />
    </g>
    <defs>
      <clipPath id="clip0_5874_5133">
        <rect width="16" height="18.4615" fill="white" transform="translate(0 0.769531)" />
      </clipPath>
    </defs>
  </svg>
);
