import { LineItem } from '@Types/cart/LineItem';
import { pushToDataLayer, ANALYTICS_EVENTS } from 'frontastic/lib/utils/analytics-data-layer';
import { useCart } from 'frontastic/provider';

export const ViewCartGtmHelperTastic = () => {
  const cart = useCart();

  if (!cart.data || !cart.data.lineItems || cart.data.lineItems.length === 0) {
    return <></>;
  }
  const { lineItems, discountCodes, orderId, taxed, shippingInfo } = cart.data;
  const estimatedSubtotal = lineItems.reduce((acc, item) => (acc += item.totalPrice.centAmount), 0) / 100.0;
  const products = lineItems.map((product: LineItem) => {
    return {
      id: product.productId,
      name: product.name,
      price: product.price.centAmount,
      quantity: product.count,
    };
  });
  window['_cc_dataLayer'] = {
    coupon: discountCodes[0]?.code,
    id: orderId,
    revenue: estimatedSubtotal,
    tax: taxed?.amount,
    products: products,
    shipping: shippingInfo[0]?.shippingInfo?.price,
  };

  /* Push cart data to analytics data layer */
  pushToDataLayer({
    event: ANALYTICS_EVENTS.VIEW_CART,
    ecommerce: {
      currency: 'USD',
      value: estimatedSubtotal,
      shipping: shippingInfo[0]?.shippingInfo?.price ? shippingInfo[0]?.shippingInfo?.price : 0.0,
      tax: taxed?.amount ? taxed?.amount : 0.0,
      discounts: discountCodes,
      items: lineItems.map((product: LineItem) => ({
        item_id: product.variant.sku ? product.variant.sku : product.productId,
        item_name: product.name,
        item_variant: product.variant?.attributes?.tin_color?.label,
        coupon: product.discounts?.[0]?.code,
        discount: (product.price.centAmount - product.discountedPrice?.centAmount) / 100.0,
        price: product.price.centAmount / 100.0,
        quantity: product.count,
      })),
    },
  });

  return <></>;
};
