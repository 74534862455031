/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable tailwindcss/no-arbitrary-value */
import { useState } from 'react';
import {
  normalizeTelNumber,
  getInputPattern,
} from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/data-entry/input/utils';
import { EMAIL_REGX } from '@ariessolutionsio/react-ecomm-ui/dist/utils/maps';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useAzure } from 'frontastic/provider/frontastic';
import formData from './dsar-form.json';
import { InputCheckBox, Input } from './formFields.helper';

const DSARFromSchema = Yup.object().shape({
  firstname: Yup.string().required('FirstName is a required field'),
  lastname: Yup.string().required('LastName is a required field'),
  middlename: Yup.string(),
  phonenumber: Yup.string()
    .required('Phone Number  is a required field')
    .matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'You must enter a valid Phone Number'),
  email: Yup.string()
    .required('Email Address is a required field')
    .matches(EMAIL_REGX, 'You must enter a valid email')
    .max(60, 'Email must be less than 60 characters'),
  country: Yup.string().required('Country is a required field'),
  state: Yup.string(),
  additionalInfo: Yup.string(),
});

export const DSARForm = () => {
  const form = useForm({
    resolver: yupResolver(DSARFromSchema),
    defaultValues: {
      firstname: '',
      lastname: '',
      middlename: '',
      phonenumber: '',
      email: '',
      country: '',
      state: '',
      additionalInfo: '',
    },
  });

  const [requestInfo, setRequestInfo] = useState({
    rightToKnow: [],
    deletionRequest: [],
    salesOrSharing: [],
    automatedDecisionMaking: [],
    limitRequest: [],
    correctionRequest: [],
    dataPortabilityRequest: [],
  });
  const [message, setMessage] = useState('');
  const { sendDSAREmail } = useAzure();

  const onSubmit = async (values) => {
    const res = await sendDSAREmail({
      email: values?.email,
      firstName: values?.firstName,
      emailSubject: 'DSAR Request',
      lastName: values?.lastName,
      middleName: values?.middleName,
      phoneNumber: values?.phoneNumber,
      country: values?.country,
      state: values?.state,
      rightToKnow: requestInfo?.rightToKnow,
      deletionRequest: requestInfo?.deletionRequest,
      salesOrSharing: requestInfo?.salesOrSharing,
      automatedDecisionMaking: requestInfo?.automatedDecisionMaking,
      limitRequest: requestInfo?.limitRequest,
      correctionRequest: requestInfo?.correctionRequest,
      dataPortabilityRequest: requestInfo?.dataPortabilityRequest,
      additionalInfo: values?.additionalInfo,
    });

    if (res?.success) {
      setMessage("Thanks for contacting us with your comments and questions. We'll respond to you very soon.");
    }
  };

  const handleChange = (data) => {
    const { name, value, checked } = data;
    const selectedKey = requestInfo?.[name];
    if (checked) {
      setRequestInfo({
        ...requestInfo,
        [name]: selectedKey?.length > 0 ? [...selectedKey, value] : [value],
      });
    }
    // Case 2  : The user unchecks the box
    else {
      setRequestInfo({
        ...requestInfo,
        [name]: selectedKey.filter((e) => e !== value),
      });
    }
  };

  return (
    <div className="my-8 px-3 lg:px-0">
      <div className="mx-0 w-[100%] lg:w-[75%]">
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
          <Input
            label="First Name :"
            id="firstname"
            error={form?.formState?.errors?.firstname?.message}
            register={form.register('firstname')}
          />
          <Input label="Middle Name or Initial :" id="middlename" register={form.register('middlename')} />

          <Input
            label="Last Name :"
            id="lastname"
            error={form?.formState?.errors?.lastname?.message}
            register={form.register('lastname')}
          />
          <Input
            label="Phone Number :"
            id="phonenumber"
            error={form?.formState?.errors?.phonenumber?.message}
            register={form.register('phonenumber')}
            restProps={{
              type: 'tel',
              onChange: (event) => {
                const { value } = event.target;
                event.target.value = normalizeTelNumber(value);
              },
              pattern: getInputPattern('tel'),
            }}
          />
          <Input
            label="Email :"
            id="email"
            error={form?.formState?.errors?.email?.message}
            register={form.register('email')}
          />
          <Input
            label="Country of Residence :"
            id="country"
            error={form?.formState?.errors?.country?.message}
            register={form.register('country')}
          />
          <Input label="If US, State of Residence :" id="state" register={form.register('state')} />

          <div className="space-y-3">
            <span>Action Requests (Click as many as apply)</span>
            {formData &&
              formData?.map((items, index) => {
                return (
                  <div className="flex flex-col space-y-1" key={index}>
                    <p className="font-bold" id="deletionRequest">
                      {items?.label}
                    </p>
                    <div className="ml-5 space-y-3">
                      {items?.fields?.map((field, i) => {
                        return (
                          <InputCheckBox
                            name={field?.name}
                            id={field?.id}
                            value={field?.value}
                            label={field?.value}
                            handleOnChange={handleChange}
                            key={i}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="flex">
            <label className="font-bold" id="AdditionalInformationField">
              Additional information/comments optional field :
            </label>
            <textarea
              rows={3}
              cols={50}
              name="comment"
              className="border-black ml-2 rounded-md border-2 px-4 py-1"
              {...form.register('additionalInfo')}
            />
          </div>
          <div>
            <p>
              Please note that the exercise of any of these rights will not result in any discriminatory treatment to
              you, including, but not limited to, denying goods or services to you; charging different prices; providing
              a different quality of goods and services; and/or suggesting that you may receive a different price or
              rate.
            </p>
          </div>
          <div>
            <p>
              To process your request, Rich’s may need to ask you additional questions to confirm the information that
              is associated with you.
            </p>
          </div>
          <div className="flex flex-col space-y-3">
            <button
              type="submit"
              className="w-[100%] rounded-md bg-primary-800 px-[10px] py-[7px] text-white lg:w-[200px]"
            >
              Submit
            </button>
            {message && <span>{message}</span>}
          </div>
        </form>
      </div>
    </div>
  );
};
