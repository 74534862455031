export const UserIcon = (
  <svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7913 21.9544V19.8635C15.7913 18.7544 15.3524 17.6907 14.571 16.9065C13.7895 16.1222 12.7297 15.6816 11.6247 15.6816H5.37467C4.26961 15.6816 3.2098 16.1222 2.4284 16.9065C1.64699 17.6907 1.20801 18.7544 1.20801 19.8635V21.9544"
      stroke="#2B2A2D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.49967 11.5004C10.8009 11.5004 12.6663 9.62809 12.6663 7.31854C12.6663 5.00898 10.8009 3.13672 8.49967 3.13672C6.19849 3.13672 4.33301 5.00898 4.33301 7.31854C4.33301 9.62809 6.19849 11.5004 8.49967 11.5004Z"
      stroke="#2B2A2D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SearchIcon = (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.58529 17.909C14.1877 17.909 17.9186 14.1645 17.9186 9.5454C17.9186 4.92629 14.1877 1.18176 9.58529 1.18176C4.98291 1.18176 1.25195 4.92629 1.25195 9.5454C1.25195 14.1645 4.98291 17.909 9.58529 17.909Z"
      stroke="#2B2A2D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M20 20L15.4688 15.4523" stroke="#2B2A2D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const ShoppingBagIcon = (
  <svg width="27" height="27" viewBox="0 4 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_644_9822)">
      <path
        d="M4.35728 8.25L1.67871 11.8214V24.3214C1.67871 24.795 1.86685 25.2492 2.20173 25.5841C2.53662 25.919 2.99082 26.1071 3.46443 26.1071H15.9644C16.438 26.1071 16.8922 25.919 17.2271 25.5841C17.562 25.2492 17.7501 24.795 17.7501 24.3214V11.8214L15.0716 8.25H4.35728Z"
        stroke="#2B2A2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.67871 11.8213H17.7501"
        stroke="#2B2A2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2854 15.3926C13.2854 16.3398 12.9092 17.2482 12.2394 17.918C11.5696 18.5877 10.6612 18.964 9.71401 18.964C8.7668 18.964 7.8584 18.5877 7.18863 17.918C6.51885 17.2482 6.14258 16.3398 6.14258 15.3926"
        stroke="#2B2A2D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_644_9822">
        <rect width="27" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ChevronDownIcon = (
  <svg width="9" height="4" viewBox="0 0 9 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.49294 3.99865C4.343 3.99891 4.19769 3.95243 4.08223 3.86729L0.231828 1.01162C0.100775 0.914678 0.0183608 0.775372 0.0027155 0.624349C-0.0129298 0.473327 0.0394754 0.322958 0.148402 0.206323C0.257329 0.089688 0.413856 0.0163408 0.583547 0.00241676C0.753238 -0.0115073 0.922194 0.0351323 1.05325 0.132076L4.49294 2.69075L7.93264 0.223457C7.99828 0.176016 8.07381 0.140587 8.15488 0.119209C8.23596 0.0978302 8.32098 0.090923 8.40507 0.0988842C8.48915 0.106845 8.57064 0.129517 8.64485 0.165598C8.71906 0.201679 8.78452 0.250457 8.83748 0.309127C8.89625 0.367852 8.94076 0.436746 8.96822 0.511492C8.99568 0.586238 9.0055 0.665226 8.99707 0.743508C8.98864 0.821789 8.96213 0.897677 8.91921 0.966417C8.87629 1.03516 8.81788 1.09527 8.74764 1.14298L4.89723 3.90156C4.77846 3.97324 4.63611 4.00743 4.49294 3.99865Z"
      fill="#2B2A2D"
    />
  </svg>
);
