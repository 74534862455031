import { generateUniqueIdNumber } from '@/frontastic/actions/account';

export const genGenIDNumber = async (entity: string, toast: any) => {
  try {
    const response = await generateUniqueIdNumber(entity);
    let customerNumber;
    if (!response.hasError) {
      const { nextId } = response ?? { nextId: undefined };
      customerNumber = nextId;
    } else {
      customerNumber = undefined;
    }
    return customerNumber;
  } catch (error) {
    toast({
      title: 'Unable to generate address number at this time, Please try again!',
      duration: 5000,
    });
    return undefined;
  }
};
