import { useState } from 'react';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import _ from 'lodash';
import Datepicker from 'react-tailwindcss-datepicker';
import { OrderDeliveryItem, useCheckoutContext } from '../../../contexts/checkout.context';

interface DateByProductProps {
  orderDeliveryItem: OrderDeliveryItem;
  unavailabledDates: any[];
}

const ProductTileByDate = ({ orderDeliveryItem, unavailabledDates }: DateByProductProps) => {
  const [{ deliveryMethods, orderDelivery }, { setOrderDelivery }] = useCheckoutContext();

  const ShippingTo = ({ shippingPlanning, index }) => {
    const [dateValue, setDateValue] = useState(null);

    const updateDate = (date: any) => {
      setDateValue(date);
      shippingPlanning.shippingDate = date.startDate;
      orderDeliveryItem.shippingPlanning[index] = { ...shippingPlanning };
      const idx = orderDelivery.findIndex((item) => item.lineItem.lineItemId === orderDeliveryItem.lineItem.lineItemId);
      orderDelivery[idx] = { ...orderDeliveryItem };
      setOrderDelivery([...orderDelivery]);
    };

    return (
      <div className="mb-8 flex w-full justify-between space-x-6" key={`SSUD-${shippingPlanning.address.id}`}>
        <div className="flex w-1/2 justify-between">
          <div>
            <Typography
              variant="body"
              className="font-bold"
              tagName="div"
            >{`${shippingPlanning.address.firstName} ${shippingPlanning.address.lastName}`}</Typography>
            <Typography
              variant="body"
              tagName="div"
            >{`${shippingPlanning.address.streetName} ${shippingPlanning.address.state} ${shippingPlanning.address.postalCode}`}</Typography>
          </div>
          <Typography variant="body" tagName="div" className="font-bold">
            {shippingPlanning.quantity}
          </Typography>
        </div>
        <div className="flex w-1/3 justify-end ">
          <div>
            <Datepicker
              value={{
                startDate: shippingPlanning.shippingDate,
                endDate: shippingPlanning.shippingDate,
              }}
              onChange={updateDate}
              displayFormat={'YYYY-MM-DD'}
              primaryColor="red"
              asSingle={true}
              readOnly
              useRange={false}
              disabledDates={unavailabledDates}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <>
        <div className=" rounded-lg border  ">
          <div className="flex space-x-3 px-6 py-8">
            {orderDeliveryItem.lineItem.variant?.images && (
              <div className="h-[80px] w-[80px] flex-none">
                <img
                  src={orderDeliveryItem.lineItem.variant?.images[0]}
                  alt={orderDeliveryItem.lineItem.name}
                  className="h-full w-full object-cover"
                />
              </div>
            )}
            <div className="flex w-full flex-col space-y-1 lg:flex-row lg:justify-between">
              <div>
                <p className="text-lg">{orderDeliveryItem.lineItem.name}</p>
                {orderDeliveryItem.lineItem.variant?.attributes?.cookie_flavor?.label && (
                  <p className="text-sm">
                    <span>Assortment: </span>
                    <span className="font-semibold">
                      {orderDeliveryItem.lineItem.variant?.attributes.cookie_flavor.label}
                    </span>
                  </p>
                )}
                {orderDeliveryItem.lineItem.variant?.attributes?.tin_color?.label && (
                  <p className="text-sm">
                    <span>Tin Color: </span>
                    <span className="font-semibold">
                      {orderDeliveryItem.lineItem.variant?.attributes.tin_color.label}
                    </span>
                  </p>
                )}
              </div>
              <div>
                <Typography variant="body" className="font-bold">
                  {`QTY: ${orderDeliveryItem.quantity}`}
                </Typography>
              </div>
            </div>
          </div>
          <div className="border-t"></div>
          <div className="px-6 py-8">
            {!_.isEmpty(orderDeliveryItem.shippingPlanning) &&
              orderDeliveryItem.shippingPlanning.map((shippingPlanning, index) => (
                <ShippingTo
                  shippingPlanning={shippingPlanning}
                  index={index}
                  key={`SSBD-${shippingPlanning.address.id}`}
                />
              ))}
            {_.isEmpty(orderDeliveryItem.shippingPlanning) && <div className="flex w-full justify-center"></div>}
          </div>
        </div>
      </>
    </div>
  );
};
export const DateByProduct = ({ orderDeliveryItem, unavailabledDates }: DateByProductProps) => {
  return (
    <div className="pt-4">
      <ProductTileByDate orderDeliveryItem={orderDeliveryItem} unavailabledDates={unavailabledDates} />
    </div>
  );
};
