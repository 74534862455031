export const trashCanIcon = (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2875_110054)">
      <path
        d="M2.26511 4.59791H2.64746L3.75202 18.219C3.81574 19.0439 4.51671 19.6784 5.34514 19.6784H14.0542C14.8826 19.6784 15.5836 19.0439 15.6473 18.219L16.7519 4.59791H17.1342C17.8352 4.59791 18.4087 4.02684 18.4087 3.32886C18.4087 2.63089 17.8352 2.05982 17.1342 2.05982H12.5673V1.5099C12.5673 0.769621 11.9513 0.15625 11.2078 0.15625H8.19151C7.44805 0.15625 6.83205 0.769621 6.83205 1.5099V2.05982H2.26473C1.56376 2.05982 0.990234 2.63089 0.990234 3.32886C0.990234 4.02684 1.56376 4.59791 2.26473 4.59791H2.26511ZM14.7976 18.1555C14.7764 18.5362 14.4365 18.8324 14.0542 18.8324H5.34514C4.96279 18.8324 4.62292 18.5362 4.60168 18.1555L3.49712 4.59791H15.881L14.7976 18.1555ZM7.68171 1.53105C7.68171 1.25609 7.91537 1.02343 8.19151 1.02343H11.2078C11.484 1.02343 11.7176 1.25609 11.7176 1.53105V2.08097H7.68171V1.53105ZM2.26511 2.90585H17.1342C17.3679 2.90585 17.559 3.0962 17.559 3.32886C17.559 3.56152 17.3679 3.75188 17.1342 3.75188H3.05105H2.26511C2.03145 3.75188 1.84028 3.56152 1.84028 3.32886C1.84028 3.0962 2.03145 2.90585 2.26511 2.90585Z"
        fill="#B7002A"
      />
      <path
        d="M7.00462 17.5805C7.23838 17.5805 7.42964 17.3902 7.42964 17.1576V6.07721C7.42964 5.84461 7.23838 5.6543 7.00462 5.6543C6.77085 5.6543 6.57959 5.84461 6.57959 6.07721V17.1576C6.57959 17.3902 6.77085 17.5805 7.00462 17.5805Z"
        fill="#B7002A"
      />
      <path
        d="M9.70383 17.686C9.9376 17.686 10.1289 17.4957 10.1289 17.2631V6.18268C10.1289 5.95008 9.9376 5.75977 9.70383 5.75977C9.47007 5.75977 9.27881 5.95008 9.27881 6.18268V17.2631C9.27881 17.4957 9.47007 17.686 9.70383 17.686Z"
        fill="#B7002A"
      />
      <path
        d="M12.4026 17.7915C12.6363 17.7915 12.8276 17.6012 12.8276 17.3686V6.28815C12.8276 6.05555 12.6363 5.86523 12.4026 5.86523C12.1688 5.86523 11.9775 6.05555 11.9775 6.28815V17.3686C11.9775 17.6012 12.1688 17.7915 12.4026 17.7915Z"
        fill="#B7002A"
      />
    </g>
    <defs>
      <clipPath id="clip0_2875_110054">
        <rect width="17.4264" height="19.5176" fill="white" transform="translate(0.990234 0.15625)" />
      </clipPath>
    </defs>
  </svg>
);
