import React from 'react';
import { useRouter } from 'next/router';
import { useAsio } from '@ariessolutionsio/providers-richproducts';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import _ from 'lodash';
import useSWR from 'swr';

interface catalogCardProps {
  name: string;
  catalogKey: string;
}

export const CatalogCard = ({ name, catalogKey }: catalogCardProps) => {
  const [{ products }] = useAsio();
  const router = useRouter();
  const { data: productsFromProductSelection } = useSWR('productsFromProductSelection', () =>
    products.getProductsFromProductSelection({ storeKey: catalogKey }),
  );

  const cleanProductList = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (key === 'images' && Array.isArray(obj[key])) {
          obj[key] = obj[key].map((image) => (image.url ? image.url : image));
        } else {
          cleanProductList(obj[key]);
        }
      }
    }
    return obj;
  };

  return (
    <div className="center mb-3.5 flex justify-between bg-white p-8 md:space-x-8 md:p-10" key={catalogKey}>
      <div className="hidden h-[150px] w-[150px] flex-none md:block">
        {!_.isEmpty(productsFromProductSelection) && (
          <img
            src={cleanProductList(productsFromProductSelection)?.[0]?.variants?.[0]?.images?.[0]}
            alt="Corporate Catalog Img"
            className="h-full w-full object-cover"
          />
        )}
      </div>
      <div className="flex-auto space-y-3">
        <div className="space-y-2">
          <Typography tagName="h3" variant="h5" className="text-black font-galanoClassic text-[24px]">
            {name}
          </Typography>
          <p className="text-sm">
            <span>Corporate ID: </span>
            <span>{catalogKey}</span>
          </p>
        </div>
        <Button
          variant="outlined"
          palette="black"
          className="w-full flex-none font-semibold md:max-w-[268px]"
          onClick={() => router.push(`/my-account/catalog/corporate-plp?storeKey=${catalogKey}`)}
        >
          Browse Private Catalog
        </Button>
      </div>
    </div>
  );
};
