import { useEffect, useState } from 'react';
import { ShippingForm } from '../schemas/shipping.schema';

const useCheckoutLSData = () => {
  const [checkoutSLData, setCheckoutData] = useState<ShippingForm | null>(null);

  // Todo: if user is logged in then store data with user specific key
  useEffect(() => {
    const storedCheckoutData = localStorage.getItem('checkoutData');
    if (storedCheckoutData) {
      setCheckoutData(JSON.parse(storedCheckoutData));
    }
  }, []);

  const updateCheckoutData = (data) => {
    setCheckoutData(data);
    localStorage.setItem('checkoutData', JSON.stringify(data));
  };

  return { checkoutSLData, updateCheckoutData };
};

export default useCheckoutLSData;
