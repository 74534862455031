import React, { useState } from 'react';
import { Input } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { CatalogCard } from './CatalogCard';
import { useAccount } from '@/frontastic/provider';

export const CatalogsInfo = ({ catalog }: any) => {
  const [corporateKey, setCorporateKey] = useState('');
  const [loading, setLoading] = useState(false);
  const { updateStoreAccount } = useAccount();

  const handleSubmit = async (corporateKey: string) => {
    if (corporateKey) {
      setLoading(true);
      try {
        const response = await updateStoreAccount(corporateKey);

        if (!response.infoError) {
          toast.success('Corporate Catalog is updated successfully');
          setLoading(false);
          window.location.reload();
        } else {
          setLoading(false);
          toast.error('Unknown Corporate Catalog ID. Please try again or contact us at 1-800-458-2447 for assistance.');
        }
      } catch (error) {
        setLoading(false);
        toast.error('Error updating Corporate Catalog');
      }
    }
  };

  return (
    <div className="md:mt-16">
      <div className="grid gap-8 lg:gap-6">
        {catalog && !loading && <CatalogCard name={catalog.name} catalogKey={catalog.key} />}
      </div>
      <div className="mb-3.5 flex flex-col justify-between space-y-6 bg-white p-8 md:mt-8">
        <p className="text-2xl">Access a Corporate Catalog</p>
        <p className="text-sm">
          <b>How to Access a Corporate Catalog:</b> Input the code provided by your sales representative. Lost code?
          Contact us at 1-800-458-2447.
        </p>
        <div className="flex flex-col space-y-8 md:flex-row md:items-end md:justify-between md:space-x-8">
          <div className="flex w-full flex-col space-y-2">
            <label className="flex-none font-light text-gray-600">
              Enter Your Corporate ID to Access Your Corporate Catalog
            </label>
            <Input
              type="text"
              name="corporateKey"
              value={corporateKey}
              onChange={(e) => setCorporateKey(e.target.value)}
            />
          </div>
          <Button
            variant="outlined"
            palette="black"
            className="flex-none font-semibold"
            onClick={() => {
              handleSubmit(corporateKey);
            }}
          >
            Access Private Catalog
          </Button>
        </div>
      </div>
    </div>
  );
};
