import { FC, useState } from 'react';

interface IncrementerProps {
  onChange: (value: number) => void;
  initialValue?: number;
  allowIncrement: boolean;
}

export const Incrementer: FC<IncrementerProps> = ({ onChange, initialValue, allowIncrement }) => {
  const [value, setValue] = useState(initialValue || 0);

  return (
    <div className="flex items-center space-x-2">
      <div
        onClick={() => {
          if (value === 0) return;
          setValue(value - 1);
          onChange(value - 1);
        }}
        className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border"
      >
        <span>-</span>
      </div>
      <div className="flex items-center justify-center">
        <div>{value}</div>
      </div>
      <div
        onClick={() => {
          if (!allowIncrement) return;
          setValue(value + 1);
          onChange(value + 1);
        }}
        className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border"
      >
        <span>+</span>
      </div>
    </div>
  );
};
