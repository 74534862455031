import { FC, useState } from 'react';
import moment from 'moment';
import Datepicker from 'react-tailwindcss-datepicker';

export const DatepickerTastic: FC = ({ data }: any) => {
  const disabledDates = data.unavailabledDates.map((date) => {
    return {
      startDate: moment(date.unavailableDate).format('YYYY-MM-DD'),
      endDate: moment(date.unavailableDate).format('YYYY-MM-DD'),
    };
  });

  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Datepicker
      value={value as any}
      disabledDates={disabledDates}
      asSingle={true}
      useRange={false}
      onChange={handleValueChange}
    />
  );
};
