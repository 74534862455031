/* eslint-disable tailwindcss/no-arbitrary-value */
/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useFormat } from 'helpers/hooks/useFormat';
import OrderDetails from '../../../../../components/commercetools-ui/account/details/sections/OrderDetails';
import OrdersHistory from '../../../../../components/commercetools-ui/account/details/sections/orders';
import Sidemenu from '../side-menu';

const AccountOrdersTastic: React.FC<unknown> = () => {
  const [detailInfo, setDetailInfo] = useState({ show: false, orderData: {} });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const contentTitle = formatAccountMessage({ id: 'my.orders', defaultMessage: 'Orders' });

  const showOrderDetail = (details: { show: boolean; orderData: unknown }) => {
    setDetailInfo(details);
  };

  return (
    <>
      {detailInfo.show ? (
        <div
          className="ml-2 mt-[50px] flex cursor-pointer text-[14px] lg:ml-[30px]"
          onClick={() => setDetailInfo({ show: false, orderData: {} })}
        >
          <ChevronLeftIcon className="h-6 w-5" />
          <div className="font-bold uppercase text-red-700">My Orders</div>
        </div>
      ) : (
        <></>
      )}
      {!detailInfo.show ? (
        <Sidemenu hash={'/my-account/orders'} contentTitle={contentTitle}>
          <OrdersHistory showOrderDetail={showOrderDetail} />
        </Sidemenu>
      ) : (
        <OrderDetails detailInfo={detailInfo} />
      )}
    </>
  );
};

export default AccountOrdersTastic;
