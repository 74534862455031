import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button } from '@ariessolutionsio/primitives-richproducts';
import { useAsio } from '@ariessolutionsio/providers-richproducts';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { ProductCard } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/product-overviews/full-product-card-with-image-and-details';
import { Product } from '@ariessolutionsio/react-ecomm-ui/dist/types/product/Product';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import useSWR from 'swr';
import { useGetAccount } from '@/hooks/my-account/use-get-account';

const AccountCorporatePlpTastic = () => {
  const parseProduct = (product: Product) => {
    const _product = JSON.parse(JSON.stringify(product));
    delete _product['categories'];
    _product.description = '';
    _product._url = '/' + _product._url;
    return _product;
  };
  const router = useRouter();

  const [{ products, customers }] = useAsio();

  const { data, isLoading } = useGetAccount();

  const { data: myStores } = useSWR('myStores', () => {
    if (isLoading) {
      return customers.myStores();
    }
    return [];
  });

  const { data: productsFromProductSelection } = useSWR('productsFromProductSelection', () =>
    products.getProductsFromProductSelection({ storeKey: router.query.storeKey as string }),
  );

  const { data: selection } = useSWR('selection', () => products.getProductSelection());

  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    if (!data && !isLoading) {
      router.push('/my-account/create');
    }

    if (myStores && myStores.length > 0 && !myStores.includes(router.query.storeKey) && data && !isLoading) {
      router.push('/my-account/catalog');
    }
  }, [myStores, data, router, isLoading]);

  const cleanProductList = useCallback((obj) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (key === 'images' && Array.isArray(obj[key])) {
          obj[key] = obj[key].map((image) => (image.url ? image.url : image));
        } else {
          cleanProductList(obj[key]);
        }
      }
    }
    return obj;
  }, []);

  useEffect(() => {
    if (productsFromProductSelection) {
      const listP = cleanProductList(productsFromProductSelection);
      setProductsList(listP);
    }
  }, [productsFromProductSelection, router, cleanProductList]);

  return (
    <div className="my-24 md:mx-12">
      <Button
        variant="link"
        className="font-semibold hover:no-underline"
        onClick={() => {
          router.push('/my-account/catalog');
        }}
      >
        <ChevronLeftIcon className="mr-1 inline-block h-5 w-5" />
        MY CATALOG
      </Button>
      <div className="mb-16 text-center">
        <Typography tagName="h4" variant="h5" className="mb-3 text-[32px] font-normal text-[#2B2A2D] lg:text-[40px]">
          Private Catalog For
        </Typography>
        <Typography tagName="h1" variant="h1" className="text-black text-[54px] lg:text-[65px]">
          {selection?.name}
        </Typography>
      </div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {productsList?.map((item, idx) => (
          <div key={idx} className="bg-white">
            {item?.variants?.[0]?.images && (
              <ProductCard
                product={parseProduct(item)}
                size="md"
                key={item.productId}
                extendedClasses={{ card: 'w-full h-full', imgContainer: 'md:aspect-w-6 md:aspect-h-5' }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountCorporatePlpTastic;
