import ContentCard from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/content-card/with-content-card';

function ContentCardTastic({ data }) {
  const { cartData } = data;
  const ctaLabelWeight = cartData[0]?.labelBold ? 'font-bold' : 'font-normal';
  const ctaLabelItalic = cartData[0]?.labelItalic ? 'italic' : 'not-italic';
  const ctaLabelFontSize = cartData[0]?.labelFontSize ? `text-[${cartData[0]?.labelFontSize}px]` : 'text-xs';

  return (
    <ContentCard
      data={{ ...cartData[0], media: cartData[0]?.media?.media }}
      extendedClasses={{
        container: 'w-[100%] max-w-[463px] rounded-[20px]',
        media: 'h-[210px] md:h-[300px] w-full min-h-full object-cover',
        contentWrapper: 'bg-white',
        title: 'md:px-8 text-2xl',
        description: 'md:px-8 py-4 text-base font-normal',
        cta: `${ctaLabelWeight} ${ctaLabelItalic} ${ctaLabelFontSize}`,
      }}
    />
  );
}

export default ContentCardTastic;
