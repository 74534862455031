import { FC, useEffect } from 'react';
import { EmptyCartBanner } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/Ecommerce/cart/empty-cart/empty-cart-banner';
import { clearAllLocalData, clearSession } from 'frontastic/lib/utils/FrontasticSessionData';
import { useCart } from 'frontastic/provider';

export const EmptyCartBannerTastic: FC<any> = (props) => {
  const { data: order } = useCart() as any;

  useEffect(() => {
    const handleOrderStatus = async () => {
      if (order?.status === 404) {
        await clearSession('frontastic-session');
        await clearAllLocalData();
      }
    };
    handleOrderStatus();
  }, [order]);

  return (
    <div className="px-8">
      {(order && order.lineItems && order.lineItems.length === 0) || (order && !order.lineItems) ? (
        <>
          <div className="mb-7 mt-2.5 font-['Akshar'] text-[24px] font-bold leading-10 md:mb-9 lg:mt-14 lg:text-[52px] ">
            Shopping Cart
          </div>
          <EmptyCartBanner
            data={{
              icon: props.data.icon.media,
              markdown: props.data.markdown,
            }}
          />
        </>
      ) : null}
    </div>
  );
};
