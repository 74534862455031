export const sortOptions = [
  {
    label: 'Relevance',
    value: 'relevance',
  },
  //   {
  //     label: 'Most Popular',
  //     value: 'variants.attributes.reviewRatingStatistics',
  //   },
  {
    label: 'Price: Low to High',
    value: 'price: Low to High',
  },
  {
    label: 'Price: High to Low',
    value: 'price: High to Low',
  },
  {
    label: 'Newest',
    value: 'variants.attributes.newestDate',
  },
  {
    label: 'Recommended',
    value: 'variants.attributes.recommendationRanking',
  },
];
