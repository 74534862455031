/* eslint-disable tailwindcss/no-arbitrary-value */
import { useEffect, useState } from 'react';
import { ProductCard } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/product-overviews/full-product-card-with-image-and-details';
import Sort from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/product-sort/simple-sort';
import { Product } from '@ariessolutionsio/react-ecomm-ui/dist/types/product/Product';
import { useProducts } from 'frontastic/provider';
import { sortOptions } from 'frontastic/tastics/products/constants';
import { getSortedItems } from '../product.helper';

function ProductCategoriesTastic({ data }) {
  const { getSortedProducts } = useProducts();
  const [products, setProducts] = useState([]);

  const {
    items,
    facets,
    query,
    category,
    previousCursor,
    nextCursor,
    total,
    totalItems,
    canonicalUrl,
    h1Content,
    categoryId,
  } = data.data.dataSource;

  useEffect(() => {
    setProducts(items);
  }, [items]);

  useEffect(() => {
    const canonicalLinks = document.getElementsByTagName('link');
    if (canonicalLinks && canonicalUrl) {
      // making it to delay because of page load with canonical before this effect
      setTimeout(() => {
        for (let i = 0; i < canonicalLinks.length; i++) {
          if (canonicalLinks[i].getAttribute('rel') === 'canonical') {
            canonicalLinks[i].setAttribute('href', canonicalUrl);
          }
        }
      }, 3000);
    }
  }, [canonicalUrl]);

  const getResultText = () => {
    if (items.length === 1) {
      return '1 result';
    }
    return `${items.length} results`;
  };

  const getSortResults = async (value: string) => {
    if (value == 'variants.attributes.reviewRatingStatistics') {
      console.log('MOST POPULAR', items);
    }
    // If value is relevance
    if (value == sortOptions[0]?.value) {
      value = `categoryOrderHints.${categoryId}`;
      const results = await getSortedItems(getSortedProducts, value, query);
      setProducts(results);
    } else {
      const results = await getSortedItems(getSortedProducts, value, query);
      setProducts(results);
    }
  };

  if (!data?.data?.dataSource || products?.length === 0) return <></>;

  const parseProduct = (product: Product) => {
    const _product = product;
    delete _product['categories'];
    _product.description = ''; //TODO: We need to add assortments for products
    return _product;
  };

  return (
    <div className="py-8">
      {h1Content && <h1 className="font-['Akshar'] text-[52px] font-bold text-[#2B2A2D]">{h1Content}</h1>}
      <div className="flex justify-end py-4 ">
        <Sort options={sortOptions} handleOnChange={getSortResults} defaultLabel="Sort By" seperator={true} />
      </div>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {products?.map((item: Product) => {
          return (
            <div key={item.productId}>
              <ProductCard
                size="md"
                product={parseProduct(item)}
                key={item.productId}
                extendedClasses={{ card: 'w-full h-full', imgContainer: 'md:aspect-w-6 md:aspect-h-5' }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProductCategoriesTastic;
