import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
import { AccountInfo } from './my-info';
import Sidemenu from './side-menu';
import { useAccount } from 'frontastic/provider';

const AccountDetailsTastic: React.FC<{}> = (props: any) => {
  const { push } = useRouter();
  const { loggedIn, loading } = useAccount();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  useEffect(() => {
    if (!loading && !loggedIn) {
      push('/');
    }
  }, [push, loggedIn, loading]);
  let provincesList = props.data?.provinces?.dataSource?.value;
  if (provincesList) {
    provincesList = Object.keys(provincesList).map((key) => ({ key, value: provincesList[key] }));
  }
  const contentTitle = formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' });
  return (
    <>
      <Sidemenu hash={'/my-account'} contentTitle={contentTitle}>
        <AccountInfo provincesList={provincesList} />
      </Sidemenu>
    </>
  );
};

export default AccountDetailsTastic;
