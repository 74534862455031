import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useCart } from 'frontastic/provider';
import { CheckoutBody } from './checkout-body';
import { CheckoutHeader } from './checkout-header';
import { CheckoutProvider } from '../contexts/checkout.context';

export const CheckOutMultiSteps: FC = (props: any) => {
  const { shipSpeedData, shippingInfo } = props.data;
  const cart = useCart();
  const [shippingMethods, setShippingMethods] = useState<any[] | null>(null);
  const { data } = cart;
  const router = useRouter();

  useEffect(() => {
    (async () => {
      const methods = data?.availableShippingMethods ?? [];
      const wrappedMethods = methods.shippingMethods?.map((method) => {
        return {
          id: method.key,
          title: method.label,
          turnaround: method.description,
          price: methods.shippingByCart[method.key],
        };
      });
      setShippingMethods(wrappedMethods);
      if (!props.data) router.push('/cart');
    })();
  }, [data]);

  if (!cart.data || !props.data || !shippingMethods) {
    return <></>;
  }

  return (
    <CheckoutProvider
      states={props.data?.provinces?.dataSource?.value}
      shippingMethods={shippingMethods}
      shipSpeedData={shipSpeedData}
      shippingInfo={shippingInfo}
      unavailabledDates={props.data?.unavailabledDates}
      beyondTodayDate={props.data?.bfp}
      undatePattern={props.data?.undatePattern}
    >
      <CheckoutHeader />
      <CheckoutBody />
    </CheckoutProvider>
  );
};
