import { FC, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { UploadAddresses } from 'components/common/upload-addresses';
import { useRecipientsBookDialog } from 'hooks/dialogs/recipients-book/recipients-book';
import _, { set } from 'lodash';
import { useCart } from 'frontastic/provider';
import { OrderDeliveryItem, useCheckoutContext } from 'frontastic/tastics/checkout/contexts/checkout.context';
import { ProductTile } from './product-tile';

interface MultiShippingAddressFormProps {
  provinces: any[];
}

export const MultiShippingAddressForm: FC<MultiShippingAddressFormProps> = ({ provinces }) => {
  const cart = useCart();

  const [{ orderDelivery }, { setOrderDelivery }] = useCheckoutContext();

  const { data: cartData } = cart;

  const updateOrderDelivery = () => {
    let item: OrderDeliveryItem;
    const list = [];

    for (let i = 0; i < cartData?.lineItems.length; i++) {
      const sp = orderDelivery.find((item) => item.lineItem.lineItemId === cartData?.lineItems[i].lineItemId);

      item = {
        lineItem: cartData?.lineItems[i],
        address: null,
        quantity: cartData?.lineItems[i].count,
        shippingPlanning: sp?.shippingPlanning || [],
      };
      list.push(item);
    }
    setOrderDelivery(list);
  };

  useEffect(() => {
    updateOrderDelivery();
  }, [cartData]);

  return (
    <>
      <div className="flex flex-col space-y-4">
        {orderDelivery.map((item) => (
          <ProductTile orderDeliveryItem={item} key={item.lineItem.lineItemId} provinces={provinces} />
        ))}
      </div>
      <UploadAddresses />
    </>
  );
};
