import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import Sidemenu from '../side-menu';

const CustomerSupportTastic: React.FC<{}> = () => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const contentTitle = formatAccountMessage({ id: 'customer_support', defaultMessage: 'Customer Support' });

  return (
    <>
      <Sidemenu hash={'/my-account/customer-support'} contentTitle={contentTitle}>
        {'WIP: Customer Support section here'}
      </Sidemenu>
    </>
  );
};

export default CustomerSupportTastic;
