/* eslint-disable prefer-rest-params */
/* eslint-disable tailwindcss/no-custom-classname */
// import ProductList from 'components/commercetools-ui/products/product-list';
import { useEffect, useState } from 'react';
import { LinkElement } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Link';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { ProductCard } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/product-overviews/full-product-card-with-image-and-details';
import Sort from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/product-sort/simple-sort';
import { Product } from '@ariessolutionsio/react-ecomm-ui/dist/types/product/Product';
import { useProducts } from 'frontastic/provider';
import { sortOptions } from 'frontastic/tastics/products/constants';
import { getSortedItems } from '../product.helper';
declare global {
  interface Window {
    pwr: any;
  }
}

const EmptyBanner = ({ patternSearch }) => {
  return (
    <div className="flex flex-col items-center py-10">
      <Typography variant="body" tagName="h3" className="text-center text-[36px] font-bold" gutterBottom="sm">
        {patternSearch === undefined || patternSearch == ''
          ? ` We’re sorry, no results found.`
          : ` We’re sorry, no results for “${patternSearch}”`}
      </Typography>
      <div className="w-3/5">
        <Typography variant="body" className="text-center" tagName="span">
          Check the spelling, try a more general term or try browsing by{' '}
          <LinkElement palette="primary" href="/search/shop-by-treat">
            treat
          </LinkElement>{' '}
          or{' '}
          <LinkElement palette="primary" href="/search/occasion">
            occassion.{' '}
          </LinkElement>
          <Typography variant="body" className="text-center" tagName="span">
            Need more help? Contact us at 1-800-458-2447
          </Typography>
        </Typography>
      </div>
    </div>
  );
};

function ProductListTastic({ data }) {
  const { getSortedProducts } = useProducts();
  const [products, setProducts] = useState([]);

  const { items, facets, query, category, previousCursor, nextCursor, total, totalItems } = data.data.dataSource;

  useEffect(() => {
    setProducts(items);
  }, [items]);

  const getResultText = () => {
    if (items.length === 1) {
      return '1 result';
    }
    return `${items.length} results`;
  };

  const getSortResults = async (value: string) => {
    if (value == 'variants.attributes.reviewRatingStatistics') {
      console.log('MOST POPULAR', items);
    }

    if (value == sortOptions[0]?.value) {
      setProducts(items);
    } else {
      const results = await getSortedItems(getSortedProducts, value, query);
      setProducts(results);
    }
  };

  if (!data?.data?.dataSource) return <></>;

  const parseProduct = (product: Product) => {
    const _product = product;
    delete _product['categories'];
    _product.description = ''; //TODO: We need to add assortments for products
    return _product;
  };

  const patternSearch = data?.data?.dataSource?.query?.query ?? '';
  if (items.length === 0) return <EmptyBanner patternSearch={patternSearch} />;

  return (
    <div className="py-8">
      <Typography variant="body" tagName="h3" className="text-center text-[36px] font-bold" gutterBottom="sm">
        {!parseProduct ? 'All products' : `Results for "${patternSearch || 'All products'}"`}
      </Typography>
      <div className="flex justify-between py-4 ">
        <Typography variant="body" tagName="p" className="font-bold">
          {getResultText()}
        </Typography>
        <div>
          <Sort options={sortOptions} handleOnChange={getSortResults} defaultLabel="Sort By" seperator={true} />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {products?.map((item: Product) => (
          <div key={item.productId} className="bg-white">
            <ProductCard
              product={parseProduct(item)}
              key={item.productId}
              extendedClasses={{ card: 'w-full h-full', imgContainer: 'md:aspect-w-6 md:aspect-h-5' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
  /* return (
    <ProductList
      products={items}
      totalProducts={total}
      facets={facets}
      category={category}
      previousCursor={previousCursor}
      nextCursor={nextCursor}
    />
  ); */
}

{
  /* <ProductCard product={parseProduct(item)} key={item.productId} />; */
}
export default ProductListTastic;
