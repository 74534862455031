import { useState, FC } from 'react';
import { Button as AsioButton } from '@ariessolutionsio/primitives-richproducts';
import { Checkbox, useToast } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { Form } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/data-entry/form';
import { AddressForm } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/forms/address';
import { addressFormSchema } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/forms/schemas/address.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAccount } from '@/frontastic/provider';
import { useAddAddress } from '@/hooks/my-account/address/use-add-address';
import { useUpdateAddress } from '@/hooks/my-account/address/use-update-address';

const schema = yup
  .object()
  .shape({
    addressFormValues: addressFormSchema, //MONKEYPATCH: @Variaban - this is the only way to get this to work. Please check this before merging.
  })
  .required();

type AddressFormSchemaType = yup.InferType<typeof schema>;

const additionalFormComp = [
  {
    label: 'Set as Default Shipping',
    key: 'isDefaultShipping',
  },
  {
    label: 'Set as Default Billing',
    key: 'isDefaultBilling',
  },
];

const defaultValues = {
  isDefaultShippingAddress: false,
  isDefaultBillingAddress: false,
};

export const SingleAddressAddEdit: FC<any> = (props) => {
  const { toast } = useToast();
  const { generateUniqueIdNumber } = useAccount();
  const { mutateAsync: updateAddress, isLoading: isUpdateAddressLoading } = useUpdateAddress();
  const { mutateAsync: addAddress, isLoading: isAddAddressLoading } = useAddAddress();
  const { provincesList, operationType = 'Add', addressToEdit = defaultValues, handleReturnToParent } = props;
  const [additionalInfo, setAdditionalInfo] = useState({
    isDefaultShipping: addressToEdit?.isDefaultShippingAddress || false,
    isDefaultBilling: addressToEdit?.isDefaultBillingAddress || false,
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      addressFormValues:
        operationType === 'Add'
          ? {}
          : {
              ...addressToEdit,
              streetAddress: addressToEdit?.streetName,
              zipCode: addressToEdit?.postalCode,
              apartment: addressToEdit?.additionalStreetInfo,
            },
    },
  });

  const genGenIDNumber = async (entity: string) => {
    try {
      const response = await generateUniqueIdNumber(entity);
      let customerNumber;
      if (!response.hasError) {
        const { nextId } = response ?? { nextId: undefined };
        customerNumber = nextId;
      } else {
        customerNumber = undefined;
      }
      return customerNumber;
    } catch (error) {
      toast({
        title: 'Unable to generate address number at this time, Please try again!',
        duration: 5000,
      });
      return undefined;
    }
  };

  const onSubmit = async (values: AddressFormSchemaType) => {
    const addressId = await genGenIDNumber('ADDRESS');
    const { addressFormValues } = values;
    const { isDefaultBilling, isDefaultShipping } = additionalInfo;

    const { streetAddress: streetName, zipCode: postalCode, apartment, ...formValues } = addressFormValues;

    switch (operationType) {
      case 'Add':
        const responseAddAddress = await addAddress({
          ...formValues,
          streetName,
          streetNumber: '',
          postalCode,
          country: 'US',
          additionalStreetInfo: apartment,
          city: addressFormValues.city,
          state: addressFormValues.state,
          firstName: addressFormValues.firstName,
          lastName: addressFormValues.lastName,
          company: addressFormValues.company,
          key: addressId.toString() ?? undefined,
          isDefaultBillingAddress: isDefaultBilling,
          isDefaultShippingAddress: isDefaultShipping,
        });

        if (!responseAddAddress.isError) {
          props.handleParentCallback();
        }
        break;

      case 'Edit':
        const payload = {
          ...formValues,
          id: addressToEdit?.id,
          addressId: addressToEdit?.id,
          streetName,
          streetNumber: '',
          postalCode,
          country: 'US',
          additionalStreetInfo: apartment,
          city: addressFormValues.city,
          state: addressFormValues.state,
          firstName: addressFormValues.firstName,
          lastName: addressFormValues.lastName,
          key: addressId.toString() ?? undefined,
          isDefaultBillingAddress: isDefaultBilling,
          isDefaultShippingAddress: isDefaultShipping,
        };

        const responseUpdateAddress = await updateAddress(payload);

        if (!responseUpdateAddress.isError) {
          props.handleParentCallback();
        }
        break;
    }
  };

  const handleCheckboxChange = (type) => {
    setAdditionalInfo({
      ...additionalInfo,
      [type]: !additionalInfo[type],
    });
  };

  const renderDefaultAddressCheckboxRow = () => {
    return (
      <>
        {additionalFormComp?.map((e) => (
          <div className="mr-5 flex items-center space-x-2" key={e?.key}>
            <Checkbox
              id={e?.key}
              name={e?.key}
              className="border-[#2B2A2D] data-[state=checked]:bg-[#2B2A2D]"
              aria-describedby="Set as default shipping address"
              checked={additionalInfo[e.key]}
              onClick={() => handleCheckboxChange(e?.key)}
            />
            <label htmlFor="is-default-shipping-address" className="text-gray-600">
              {e?.label}
            </label>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="bg-white p-4">
      <Form
        onSubmit={form.handleSubmit(onSubmit)}
        actions={
          <nav className="flex w-full space-x-4">
            <Button
              variant="outlined"
              onClick={handleReturnToParent}
              palette="black"
              className="font-semibold"
              type="reset"
            >
              Cancel
            </Button>
            <AsioButton
              loading={isAddAddressLoading || isUpdateAddressLoading}
              type="submit"
              className="bg-gray-900 text-white"
            >
              {isAddAddressLoading || isUpdateAddressLoading ? 'Saving...' : 'Save'}
            </AsioButton>
          </nav>
        }
      >
        <div className="flex flex-col space-y-4 bg-white">
          <h1 className="line-height-[43.2px] mb-4 text-[36px] font-bold">Add New Recipient / Address</h1>
          <AddressForm form={form} schemaKey="addressFormValues" states={provincesList} title="" />
          <div className="relative flex items-start">{renderDefaultAddressCheckboxRow()}</div>
        </div>
      </Form>
    </div>
  );
};
