import React, { useCallback, useEffect, useState } from 'react';
import { Form, useToast } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentMethodResult } from '@stripe/stripe-js';
import { LineItem } from '@Types/cart/LineItem';
import { CartLineItem } from '@Types/customCart/Types';
import { addresses } from 'helpers/mocks/mockData';
import { FormProvider, useForm } from 'react-hook-form';
import { removeDiscountCode, updateItem } from 'frontastic/actions/cart';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';
import { useAccount, useAzure, useCart, useOrder } from 'frontastic/provider';
import { CheckoutPaymentForm } from 'frontastic/tastics/checkout/components/payment/payment-form/payment-form';
import { CheckoutProvider, useCheckoutContext } from 'frontastic/tastics/checkout/contexts/checkout.context';
import { paymentSchema } from 'frontastic/tastics/checkout/schemas/payment.schema';
import { OrderReq } from '@/frontastic/actions/order';
type ASIOCartDemoTasticProps = {
  data: {
    data: CartLineItem[];
  };
};

// this is unused component kept some logs for debugging purpose.
const ASIOCartDemoTasticDemo: React.FC<any> = (props) => {
  const lineItem = props.data.lineItem;
  const [quantity, setQuantity] = useState<number>(0);
  const [customerNo, setCustomerNo] = useState<string>('');
  const [orderValue, setOrderValue] = useState<string>('');
  const {
    addItem,
    removeItem,
    getShippingMethods,
    setCartShippingMethod,
    setMultiShippingMethod,
    addCustomerInformation,
    addCartAddress,
    redeemDiscountCode,
    submitOrder,
    data: cart,
  } = useCart();

  const [{ currentStep, checkoutData, paymentMethodResult }] = useCheckoutContext();
  const { getOrderById, getOrderByNumber, getOrdersByUserId, checkOrderForOneTimeDiscountCode } = useOrder();
  const {
    sendAccountConfirmationEmail,
    sendContactUsEmail,
    sendDSAREmail,
    sendCorpGiftSubmissionEmail,
    sendAccountVerificationEmail,
  } = useAzure();
  const {
    login,
    register,
    requestPasswordReset,
    requestConfirmationEmail,
    generateUniqueIdNumber,
    updateStoreAccount,
  } = useAccount();

  const { toast } = useToast();

  const { uploadBlobAsset } = useAzure();

  const getOrdersByUserIdTest = async () => {
    const payload: OrderReq = {
      limit: 1,
    };
    const order = await getOrdersByUserId(payload);
    console.log('order', order);
  };

  const addMoreItem = (item: LineItem) => {
    const { variant, lineItemId } = item;
    const payload = {
      variant: {
        sku: variant.sku,
        id: lineItemId,
      },
      quantity: 1,
    };
    addItem(payload.variant, lineItem.quantity);
  };

  const removeLineItem = (item: LineItem) => {
    const { lineItemId } = item;
    removeItem(lineItemId);
  };

  const updateStoreAccountTest = () => {
    updateStoreAccount('merrill_lynch');
  };

  updateStoreAccount;

  const addCartShipping = () => {
    setCartShippingMethod({
      addressKey: '001',
      shippingMethod: 'standard',
      shipByDate: '2021-09-30',
      giftMessage: 'test gift message.',
    });
  };

  const generateUniqueIdNumberTest = async () => {
    const response = await generateUniqueIdNumber('CUSTOMER');

    if (!response.hasError) {
      const { nextId } = response ?? { nextId: 'Something went wrong' };
      setCustomerNo(nextId);
    } else {
      setCustomerNo('Something went wrong');
    }
  };

  //   useEffect(() => {
  //     getTokenDetails();
  //   }, []);
  const getTokenDetails = async () => {
    const res = await fetchApiHub('/action/account/getTokenDetails', { method: 'POST' }, {});
  };

  const generateAddressIDTest = async () => {
    const response = await generateUniqueIdNumber('ADDRESS');

    if (!response.hasError) {
      const { nextId } = response ?? { nextId: 'Something went wrong' };
      setCustomerNo(nextId);
    } else {
      setCustomerNo('Something went wrong');
    }
  };

  const addLineShipping = () => {
    setMultiShippingMethod([
      {
        addressKey: '001',
        shippingMethod: 'standard',
        shipByDate: '2021-09-30',
        quantity: 1,
        itemId: 'dc5c3f23-8dd8-4952-afba-aad8ca2804bc',
        giftMessage: 'my gift message for 001',
      },
      {
        addressKey: '002',
        shippingMethod: 'priority',
        shipByDate: '2021-09-30',
        quantity: 1,
        itemId: 'dc5c3f23-8dd8-4952-afba-aad8ca2804bc',
        giftMessage: 'my gift message for 002',
      },
    ]);
  };

  const loginUser = async () => {
    try {
      const response = await login('siddappa.mirji@ariessolutions.io', 'hello1234');
      if (!response.accountId) {
        toast({
          title: 'Login Error',
          description: response as unknown as string,
        });
      }
    } catch (err) {
      toast({
        title: 'Register Error',
        description: err,
      });
    }
  };

  const addCustomerInfo = () => {
    addCustomerInformation({
      emailAddress: 'smirjitest989091@gmail.com',
      phoneNumber: '1234567890',
    });
  };

  const addCustomerCartAddress = () => {
    addCartAddress({
      firstName: 'Sidd',
      lastName: 'Mirji',
      street: 'Hala',
      city: 'Wdts',
      state: 'SN',
      zipCode: '12345',
      company: 'Aecgen',
      subPremise: 'Test Sub Premise',
    });
  };

  const addBillingAddress = () => {
    addCartAddress({
      firstName: 'Sidd',
      lastName: 'Mirji',
      street: 'Hala',
      city: 'Wdts',
      state: 'SN',
      zipCode: '12345',
      company: 'Aecgen',
      subPremise: 'Test Sub Premise',
      addressType: 'BILLING',
    });
  };
  //   useEffect(() => {
  //     getShippingMethods().then((shippingData) => {
  //       console.log('shipping methods', shippingData);
  //     });
  //   }, []);

  const uploadFile = () => {
    uploadBlobAsset({
      fileName: 'testsid.text',
      base64Text: 'test text content',
      containerName: 'addtocart',
    });
  };

  const redeemDiscount = () => {
    redeemDiscountCode('BOGO');
  };

  const sendEmail = async () => {
    const res = await sendAccountConfirmationEmail({
      userEmail: 'siddappa.mirji@ariessolutions.io',
      userName: 'Siddappa Mirji',
      emailSubject: 'Account Confirmation',
      resetPasswordLink: 'https://www.christiecookies.com',
      viewInBrowserLink: 'https://www.christiecookies.com',
    });
  };

  const sendEmailContactUsEmailTest = async () => {
    const res = await sendContactUsEmail({
      email: 'siddappa.mirji@ariessolutions.io',
      name: 'Siddappa Mirji',
      emailSubject: 'Contact Us Form',
      note: 'test notes',
    });
  };

  const sendAccountVerificationEmailTest = async () => {
    const res = await sendAccountVerificationEmail();
  };

  const sendDSAREmailTest = async () => {
    const res = await sendDSAREmail({
      email: 'siddappa.mirji@ariessolutions.io',
      firstName: 'Siddappa',
      emailSubject: 'DSAR Request',
      lastName: 'Mirji',
      middleName: 'Shivarayappa',
      phoneNumber: '1234567890',
      country: 'India',
      state: 'Karnataka',
      rightToKnow: ['test', 'rightToKnow', 'test2 this is sample line'],
      deletionRequest: ['deletionRequest', 'test1', 'test2 this is sample line'],
      salesOrSharing: ['salesOrSharing', 'test1', 'test2 this is sample line'],
      automatedDecisionMaking: ['automatedDecisionMaking', 'test1', 'test2 this is sample line'],
      limitRequest: ['limitRequest', 'test1', 'test2 this is sample line'],
      correctionRequest: ['correctionRequest', 'test1', 'test2 this is sample line'],
      dataPortabilityRequest: ['dataPortabilityRequest', 'test1', 'test2 this is sample line'],
      additionalInfo: 'test additional info',
    });
  };

  const sendCorpGiftSubmissionEmailTest = async () => {
    const res = await sendCorpGiftSubmissionEmail({
      email: 'siddappa.mirji@ariessolutions.io',
      name: 'Siddappa Mirji',
      emailSubject: 'Corporate Gifting Submission',
      note: 'test notes for corp gifting submission',
      state: 'Karnataka',
    });
  };

  const sendConfirmationEmail = async () => {
    await requestConfirmationEmail('nickklein7588@gmail.com', 'testpass');
  };

  const sendPassResetEmail = async () => {
    const res = await requestPasswordReset('siddappa.mirji@ariessolutions.io');
  };
  const createAccount = async () => {
    try {
      const response = await register({
        email: 'siddappa.mirji@ariessolutions.io',
        password: 'hello1234',
        firstName: 'Sidd12',
        lastName: 'MJR',
        addresses: addresses,
      });
      if (!response.accountId) {
        console.log('error', response);
      } else {
        console.log('success', response);
      }
    } catch (err) {
      toast({
        title: 'Register Error',
        description: err,
      });
    }
  };

  const placeOrder = () => {
    //need to use new payment method id
    submitOrder('pm_1NKzkYDkeIp1WpDtuga5wPzE');
  };

  const getOrderByIdOrNumber = () => {
    //getOrderById(orderValue);
    getOrderByNumber(orderValue);
  };

  const removeDiscount = () => {
    removeDiscountCode({
      code: 'BOGO',
    });
  };

  const updateLineItem = (item: LineItem, quantity: number) => {
    const { lineItemId } = item;
    updateItem(lineItemId, quantity);
  };

  //   useEffect(() => {
  //     // add an item to cart when loads
  //     addItem(payload.variant, lineItem.quantity);
  //   }, []);
  const checkOrderForOneTimeDiscountCodeTest = async () => {
    const orderPayload = {
      email: 'chris.rogers@ariessolutions.io',
      phoneNumber: '1234567890',
      discountCodeId: '8b55471e-7983-4303-950d-c83016dc5a1f',
    };
    try {
      const orderResult = await checkOrderForOneTimeDiscountCode(orderPayload);
      if (orderResult.length > 0) {
        console.log('orderResults', orderResult);
      } else {
        console.log('No Orders found');
      }
    } catch (error) {
      console.log('No Orders found', error);
    }
  };

  return (
    <CheckoutProvider
      states={[]}
      shippingMethods={''}
      shipSpeedData={[]}
      shippingInfo={''}
      unavailabledDates={[]}
      beyondTodayDate={0}
      undatePattern={[]}
    >
      <div className="flex flex-col items-center justify-center">
        <h2 className="p-20 text-3xl">Tests custom cart data from Use Custom Cart frontastic Context</h2>

        {cart?.lineItems?.map((item) => (
          <div
            key={item.lineItemId}
            className="m-5 flex w-full justify-between gap-5 border-b border-gray-300 bg-white px-4 py-2"
          >
            <div className="mr-4 flex-1">
              <div className="font-medium text-gray-800">{item.name}</div>
              <div className="text-sm text-gray-500">{item.totalPrice.centAmount}</div>
            </div>
            <button className="rounded-md px-3 py-1 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              Qty {item.count}
            </button>
            <div style={{ border: '2px solid black', padding: '2px' }}>
              <input
                type="number"
                defaultValue={item.count}
                style={{ width: '70px' }}
                onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
              />
              <button
                style={{
                  padding: '10px 20px',
                  marginLeft: '10px',
                  background: 'rgb(59 130 246 / var(--tw-bg-opacity))',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => updateLineItem(item, quantity)}
                className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Update Line Item Qty
              </button>
            </div>
            <button
              onClick={() => addMoreItem(item)}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Add
            </button>
            <button
              onClick={() => removeLineItem(item)}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Remove Item
            </button>
            <button
              onClick={addLineShipping}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Add Line shipping
            </button>
          </div>
        ))}

        <div className="m-5 flex w-full justify-between gap-5 border-b border-gray-300 bg-white px-4 py-2">
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">{customerNo !== '' ? customerNo : 'Generate Customer No'}</div>
            <button
              onClick={generateUniqueIdNumberTest}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Generate
            </button>
          </div>

          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">{customerNo !== '' ? customerNo : 'Generate Address No'}</div>
            <button
              onClick={generateAddressIDTest}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Generate
            </button>
          </div>

          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Login CoFe</div>
            <button
              onClick={loginUser}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Login
            </button>
          </div>

          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">getTokenDetails</div>
            <button
              onClick={getTokenDetails}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              getTokenDetails
            </button>
          </div>

          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Send Account Verification Email</div>
            <button
              onClick={sendAccountVerificationEmailTest}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Verification Email
            </button>
          </div>
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Send Confirmation Email</div>
            <button
              onClick={sendConfirmationEmail}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Confirmation Email
            </button>
          </div>
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Send Contact US Email</div>
            <button
              onClick={sendEmailContactUsEmailTest}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Contact US Email
            </button>
          </div>
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Send Corp Gift Email</div>
            <button
              onClick={sendCorpGiftSubmissionEmailTest}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Corp Gift Email
            </button>
          </div>
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Send DSAR Email</div>
            <button
              onClick={sendDSAREmailTest}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              DSAR Email
            </button>
          </div>

          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800"> Update StoreAccount</div>
            <button
              onClick={updateStoreAccountTest}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Update StoreAccount
            </button>
          </div>
        </div>

        <div className="m-5 flex w-full justify-between gap-5 border-b border-gray-300 bg-white px-4 py-2">
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Upload File to azure</div>
            <button
              onClick={uploadFile}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Upload
            </button>
          </div>
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Redeem Code</div>
            <button
              onClick={redeemDiscount}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Redeem Code
            </button>
          </div>

          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Remove Code</div>
            <button
              onClick={removeDiscount}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Remove Code
            </button>
          </div>
        </div>
        <div className="m-5 flex w-full justify-between gap-5 border-b border-gray-300 bg-white px-4 py-2">
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800"> Set Cart Customer Info</div>
            <button
              onClick={addCustomerInfo}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Set Cart Customer Info
            </button>
          </div>

          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800"> Set Cart Ship Address</div>
            <button
              onClick={addCustomerCartAddress}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Set Cart Ship Address
            </button>
          </div>
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Set Shipping Method</div>
            <button
              onClick={addCartShipping}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Set Shipping Method
            </button>
          </div>
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Set Cart Billing Address</div>
            <button
              onClick={addBillingAddress}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Set Cart Billing Address
            </button>
          </div>
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Submit Order</div>
            <button
              onClick={placeOrder}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Submit Order
            </button>
          </div>

          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Get Order By User</div>
            <button
              onClick={getOrdersByUserIdTest}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Get Order
            </button>
          </div>

          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Send Email</div>
            <button
              onClick={sendEmail}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              send Account conf Email
            </button>
          </div>

          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Send Email</div>
            <button
              onClick={sendPassResetEmail}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              send password reset Email
            </button>
          </div>

          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Create Account</div>
            <button
              onClick={createAccount}
              className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              create Account
            </button>
          </div>
        </div>
        <div className="m-5 flex w-full justify-between gap-5 border-b border-gray-300 bg-white px-4 py-2">
          <div className="mr-4 flex-1">
            <div className="font-medium text-gray-800">Get Order By Id or Number</div>
            <div>
              <input
                type="text"
                placeholder="Order ID or Number"
                value={orderValue}
                onChange={(e) => setOrderValue(e.target.value)}
                className="rounded-md px-3 py-1 text-sm"
                style={{ width: '400px', padding: '10px 20px' }}
              />
              <button
                style={{
                  padding: '10px 20px',
                  marginLeft: '10px',
                  background: 'rgb(59 130 246 / var(--tw-bg-opacity))',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={getOrderByIdOrNumber}
                className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Get Order
              </button>
              <button
                style={{
                  padding: '10px 20px',
                  marginLeft: '10px',
                  background: 'rgb(59 130 246 / var(--tw-bg-opacity))',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
                onClick={checkOrderForOneTimeDiscountCodeTest}
                className="rounded-md bg-blue-500 px-3 py-1 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Order Email, Phone and Code
              </button>
            </div>
          </div>
        </div>
      </div>
      <CheckoutPaymentForm />
    </CheckoutProvider>
  );
};

const ASIOCartDemoTastic: React.FC<ASIOCartDemoTasticProps> = (props) => {
  const lineItem = props.data.data[0];
  const form = useForm({
    resolver: yupResolver(paymentSchema),
    defaultValues: {
      billing: {
        firstName: '',
        lastName: '',
        city: '',
        state: '',
        zipCode: '',
        company: '',
      },
      confirmationToken: 'test',
    },
  });

  //   const elements = useElements();
  //   const stripe = useStripe();
  //   const { toast } = useToast();
  //   const getPaymentMethod = useCallback(async (): Promise<PaymentMethodResult> => {
  //     if (!stripe || !elements) {
  //       return;
  //     }

  //     const result = await stripe.createPaymentMethod({
  //       type: 'card',
  //       card: elements.getElement(CardNumberElement),
  //     });

  //     if (result.paymentMethod) {
  //       form.setValue('paymentDetails.cardNumberValue', result.paymentMethod.card.last4);
  //       form.setValue(
  //         'paymentDetails.cardExpiryValue',
  //         `${result.paymentMethod.card.exp_month}/${result.paymentMethod.card.exp_year}`,
  //       );

  //       return result;
  //     }
  //   }, [elements, form, stripe]);

  //   const onSubmit = useCallback(
  //     async (values: FieldValues) => {
  //       const paymentMethod = await getPaymentMethod();

  //       if (paymentMethod?.error) {
  //         toast({
  //           title: 'Payment Error',
  //           description:
  //             'There was an issue with your method of payment. Please review and try again, use a different payment method, or contact us at orders@christiecookies.com | 1-800-458-2447',
  //         });
  //       } else {
  //         // setPaymentMethodResults(paymentMethod);
  //         // setCheckoutData((prevData) => ({
  //         //   ...prevData,
  //         //   payment: values,
  //         // }));
  //         //nextStep();
  //       }
  //     },
  //     [getPaymentMethod, toast],
  //   );

  //   const setPaymentMethodResults = (paymentMethod) => {
  //     setPaymentMethodResult(paymentMethod);
  //   };

  return (
    <CheckoutProvider
      states={[]}
      shippingMethods={''}
      shipSpeedData={[]}
      shippingInfo={''}
      unavailabledDates={[]}
      beyondTodayDate={0}
      undatePattern={[]}
    >
      <FormProvider {...form}>
        {/* <Form
          className="mt-8"
          title="Payment Details"
          onSubmit={form.handleSubmit(onSubmit)}
          actions={
            <nav className="flex w-full justify-end space-x-4 py-8">
              <Button type="submit">Submit</Button>
            </nav>
          }
        > */}
        <ASIOCartDemoTasticDemo
          data={{
            lineItem,
          }}
          // setPaymentMethodRes={setPaymentMethodResults}
        />
        {/* </Form> */}
      </FormProvider>
    </CheckoutProvider>
  );
};

export default ASIOCartDemoTastic;
