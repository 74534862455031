import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { ShipSpeed } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/Ecommerce/checkout/ship-speed-description';
import { Address } from '@ariessolutionsio/providers-richproducts';
import type { State } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/forms/address';
import { addressFormSchema } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/forms/schemas/address.schema';
import { LineItem } from '@ariessolutionsio/react-ecomm-ui/dist/types/cart/LineItem';
import { PaymentMethodResult, loadStripe } from '@stripe/stripe-js';
import { CheckoutStep, useCheckoutSteps } from '../hooks/use-checkout-steps';
import { GiftMessageForm } from '../schemas/giftMessage.schema';
import { PaymentForm } from '../schemas/payment.schema';
import { ShippingForm } from '../schemas/shipping.schema';

export type DeliveryMethod = {
  id: string;
  title: string;
  turnaround: string;
  price: string;
};

export type OrderShippingPlanning = {
  address: Address;
  quantity: number;
  shippingMethod?: string;
  shippingDate?: string;
  oneMessagePerRecipients?: boolean;
  giftMessageLine1?: string;
  giftMessageLine2?: string;
  giftMessageLine3?: string;
  giftMessageLine4?: string;
  giftMessageLine5?: string;
};

export type OrderDeliveryItem = {
  lineItem: LineItem;
  quantity: number;
  address: Address | null;
  shippingPlanning: OrderShippingPlanning[];
};

type CheckoutformData = {
  shipping?: ShippingForm;
  giftMessage?: GiftMessageForm;
  payment?: PaymentForm;
  addressKey?: string;
};

type CheckoutContextState = {
  checkoutData: CheckoutformData;
  steps: CheckoutStep[];
  currentStep: number;
  deliveryMethods: DeliveryMethod[];
  paymentMethodResult: PaymentMethodResult;
  carouselRef: MutableRefObject<any>;
  provinces: State[];
  sameAddress: boolean;
  shipSpeedData: ShipSpeed[];
  shippingInfo: string;
  unavailabledDates: any[];
  beyondTodayDate: number;
  undatePattern: any[];
  shippingMode?: 'Single Address' | 'Multiple Addresses';
  orderDelivery?: OrderDeliveryItem[];
  allowShippingSpeedByProduct?: boolean;
  allowShippingDateByProduct?: boolean;
  shippingDateSpeed?: string;
};

type Handlers = {
  nextStep: () => void;
  prevStep: () => void;
  //TODO: Remove after refactor payment alternatives
  setPaymentMethodResult: Dispatch<SetStateAction<PaymentMethodResult>>;
  setProvinces: Dispatch<SetStateAction<unknown[]>>;
  setCheckoutData: Dispatch<SetStateAction<CheckoutformData>>;
  setSameAddress: Dispatch<SetStateAction<boolean>>;
  setShippingMode: Dispatch<SetStateAction<'Single Address' | 'Multiple Addresses'>>;
  setOrderDelivery: Dispatch<SetStateAction<OrderDeliveryItem[]>>;
  setAllowShippingSpeedByProduct: Dispatch<SetStateAction<boolean>>;
  setAllowShippingDateByProduct: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
};

const CheckoutContext = createContext<[CheckoutContextState, Handlers, Dispatch<SetStateAction<number>>] | undefined>(
  undefined,
);

export const CheckoutProvider = ({
  children,
  states: s,
  shippingMethods,
  shipSpeedData,
  shippingInfo,
  unavailabledDates,
  beyondTodayDate,
  undatePattern,
}) => {
  const [sameAddress, setSameAddress] = useState<boolean>(true);
  const [shippingMode, setShippingMode] = useState<'Single Address' | 'Multiple Addresses'>('Single Address');
  const [checkoutData, setCheckoutData] = useState<CheckoutformData>();
  const [shippingDateSpeed, setShippingDateSpeed] = useState<string>('standard');
  const carouselRef = useRef(null);
  const [paymentMethodResult, setPaymentMethodResult] = useState<PaymentMethodResult>();
  const [provinces, setProvinces] = useState(Object.keys(s).map((key) => ({ key, value: s[key] })));
  const [orderDelivery, setOrderDelivery] = useState<OrderDeliveryItem[]>([]);

  const [allowShippingSpeedByProduct, setAllowShippingSpeedByProduct] = useState<boolean>(false);
  const [allowShippingDateByProduct, setAllowShippingDateByProduct] = useState<boolean>(false);

  const { steps, currentStep, setCurrentStep, nextStep, prevStep } = useCheckoutSteps(carouselRef);

  const handlers = useMemo(
    () => ({
      nextStep,
      prevStep,
      setPaymentMethodResult,
      setProvinces,
      setCheckoutData,
      setSameAddress,
      setShippingMode,
      setOrderDelivery,
      setCurrentStep,
      setAllowShippingSpeedByProduct,
      setAllowShippingDateByProduct,
      setShippingDateSpeed,
    }),
    [nextStep, prevStep],
  );

  return (
    <CheckoutContext.Provider
      value={[
        {
          steps,
          currentStep,
          deliveryMethods: shippingMethods,
          paymentMethodResult, //TODO: Remove after refactor payment alternatives
          carouselRef,
          provinces,
          checkoutData,
          sameAddress,
          shipSpeedData,
          shippingInfo,
          unavailabledDates,
          beyondTodayDate,
          undatePattern,
          shippingMode,
          orderDelivery,
          allowShippingSpeedByProduct,
          allowShippingDateByProduct,
          shippingDateSpeed,
        },
        handlers,
        setCurrentStep,
      ]}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);

  if (context === undefined) {
    throw new Error(`useCheckoutContext must be used within a CheckoutProvider`);
  }

  return context;
};
