import * as z from 'zod';

export const createAddressSchema = z.object({
  country: z.string().optional(),
  streetName: z
    .string({ required_error: 'Street name is a required field' })
    .nonempty({
      message: 'Street name is a required field',
    })
    .max(60, { message: 'Max 60 characters' })
    .nonempty(),
  streetNumber: z.string().optional(),
  building: z.string().optional(),
  apartment: z.string().optional(),
  pOBox: z.string().optional(),
  city: z
    .string({ required_error: 'City is a required field' })
    .max(30, { message: 'Max 30 characters' })
    .regex(/^[aA-zZ\s]+$/, {
      message: 'City is a required field',
    })
    .nonempty({
      message: 'City is a required field',
    }),
  postalCode: z
    .string({ required_error: 'Zip code is a required field' })
    .regex(/^(?:\d{5}(?:[-\s]\d{4})?|\d{9})$/, {
      message: 'Zip code is a required field',
    })
    .min(5, { message: 'Invalid zip code' })
    .max(10, { message: 'Invalid zip code' })
    .nonempty({
      message: 'Zip code is a required field',
    }),
  region: z.string().optional(),
  state: z
    .string({ required_error: 'State is required' })
    .max(30, { message: 'Max 30 characters' })
    .regex(/^[aA-zZ\s]+$/, {
      message: 'Only alphabets are allowed for this field ',
    }),
  additionalStreetInfo: z.string().max(60, { message: 'Max 60 characters' }).optional(),
  additionalAddressInfo: z.string().optional(),
  // ID for the contact used in an external system.
  externalId: z.string().optional(),
});
