import * as z from 'zod';
import { createAddressSchema } from './create-address.schema';

export const signUpInfoSchema = z.object({
  email: z
    .string()
    .nonempty({
      message: 'Email is a required field',
    })
    .email('Should be a valid email'),
  dateOfBirth: z
    .object({
      startDate: z.string(),
    })
    .optional(),
  password: z
    .object({
      newPassword: z
        .string()
        .nonempty({
          message: 'Password must be a minimum of 8 characters long and contain a symbol',
        })
        .min(8, 'Password must be a minimum of 8 characters long and contain a symbol')
        .regex(
          /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/,
          'Password must be a minimum of 8 characters long and contain a symbol',
        ),
      confirmPassword: z
        .string()
        .nonempty({
          message: 'Re-enter your password',
        })
        .regex(/^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/, 'Re-enter your password'),
    })
    .refine((data) => data.newPassword === data.confirmPassword, {
      message: 'Passwords do not match',
      path: ['confirmPassword'],
    }),
  firstName: z
    .string({
      required_error: 'First name is a required field',
    })
    .nonempty({
      message: 'First name is a required field',
    })
    .max(30, {
      message: 'First name must be less than 30 characters',
    }),
  lastName: z
    .string({
      required_error: 'Last name is a required field',
    })
    .nonempty({
      message: 'Last name is a required field',
    })
    .max(30, {
      message: 'Last name must be less than 30 characters',
    }),
  middleName: z.string().optional(),
  address: createAddressSchema,
  phoneNumber: z
    .string()
    .nonempty({
      message: 'Phone number is a required field',
    })
    .regex(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/, 'Phone number must be a number')
    .min(12, 'Phone number must be 10 digits')
    .max(12, 'Phone number must be 10 digits'),
});
