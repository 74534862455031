import React from 'react';
import router, { useRouter } from 'next/router';
import { Input } from '@ariessolutionsio/primitives-richproducts';
import { Configurator, useAsio } from '@ariessolutionsio/providers-richproducts';
import { Form } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { setCookie } from 'cookies-next';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import * as yup from 'yup';
import { fetchApiHub } from 'frontastic';
import { clearAllLocalData, clearSession } from 'frontastic/lib/utils/FrontasticSessionData';
import { useAccount, useCart } from 'frontastic/provider';
import Sidemenu from '../details/side-menu';

const changePasswordSchema = yup.object({
  currentPassword: yup.string().required('Current Password is required'),
  newPassword: yup
    .string()
    .required('New Password is required')
    .min(8, 'Password must be a minimum of 8 characters long and contain a symbol')
    .matches(/^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/, 'Password must contain a symbol'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

export const ChangePassword = () => {
  const { clearCartAndSession } = useCart();
  const { login, loading } = useAccount();
  const { push } = useRouter();
  const [{ customers }] = useAsio();
  const form = useForm({
    resolver: yupResolver(changePasswordSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  const { data: meData, mutate } = useSWR('me', () => !loading && customers.me(), {
    revalidateIfStale: true,
  });

  const onSubmit = async (values) => {
    try {
      const { version } = meData;

      const response = await mutate(
        customers.changePassword({
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          customerVersion: version,
        }),
      );

      if (!response) {
        form.setError('currentPassword', { message: 'Invalid Password' });
      } else {
        const { email } = meData;

        const response = await login(email, values.newPassword);

        fetchApiHub('/action/account/getTokenDetails', { method: 'POST' }, {}).then((res) => {
          if (res.token) {
            setCookie('asio_session', JSON.stringify(res));

            Configurator.get().setSession({
              refreshToken: res.refreshToken,
              accessToken: res.token,
            });
          }
        });

        if (response) {
          push('/my-account');
        } else {
          await clearSession('frontastic-session');
          await clearAllLocalData();
          await clearCartAndSession();

          setTimeout(async () => {
            await push('/');
            window.location.reload();
          });
        }
      }
    } catch (error) {
      form.setError('currentPassword', { message: 'Invalid Password' });
    }
  };

  return (
    <Sidemenu backButton={{ title: 'My Account', href: '/my-account' }}>
      <Form
        onSubmit={form.handleSubmit(onSubmit)}
        className="mt-0 space-y-11 bg-white p-[30px] lg:ml-6 lg:space-y-[54px] lg:p-[60px]"
        actions={
          <nav className="flex w-full space-x-4">
            <Button
              variant="outlined"
              onClick={() => router.push('/my-account')}
              palette="black"
              className="font-semibold"
              type="reset"
            >
              Cancel
            </Button>
            <Button palette="black" type="submit" className="bg-gray-900 text-white">
              Save
            </Button>
          </nav>
        }
      >
        <div>
          <h1 className="mb-[30px] font-['Akshar'] text-4xl font-bold lg:mb-10">Change Your Password</h1>
          <Form.Section>
            <div className="max-w-[650px] space-y-5">
              <Form.Control
                name="currentPassword"
                label="Current Password"
                error={form?.formState?.errors?.currentPassword?.message}
              >
                <Input.Password
                  {...form.register('currentPassword')}
                  autoFocus={form?.formState?.errors?.currentPassword?.message?.length > 0}
                />
              </Form.Control>
              <Form.Control
                name="newPassword"
                label="New Password"
                error={form?.formState?.errors?.newPassword?.message}
              >
                <Input.Password
                  {...form.register('newPassword')}
                  autoFocus={form?.formState?.errors?.newPassword?.message?.length > 0}
                />
              </Form.Control>
              <Form.Control
                name="confirmPassword"
                label="Confirm Password"
                error={form?.formState?.errors?.confirmPassword?.message}
              >
                <Input.Password
                  {...form.register('confirmPassword')}
                  autoFocus={form?.formState?.errors?.confirmPassword?.message?.length > 0}
                />
              </Form.Control>
            </div>
          </Form.Section>
        </div>
      </Form>
    </Sidemenu>
  );
};
