// @ts-nocheck
import { FC, useState } from 'react';
import { Checkbox, Form, Input } from '@ariessolutionsio/react-ecomm-ui/dist';
import { GiftMessageMultiShipping } from './multi-shipping';
import { GiftSingleMessage } from './single-shipping';
import { useCheckoutContext } from '../../contexts/checkout.context';

export const GiftMessage: FC = () => {
  const [{ checkoutData, shippingMode }, { setCheckoutData }] = useCheckoutContext();
  //const [show, setShow] = useState(checkoutData.shipping.giftMessageCheckbox.show);

  const checkboxToggle = () => {
    setCheckoutData({
      ...checkoutData,
      shipping: {
        ...checkoutData.shipping,
        giftMessageCheckbox: {
          show: !checkoutData.shipping.giftMessageCheckbox.show,
        },
      },
    });
  };

  return (
    <div className="py-8">
      <div className="font-['Akshar'] text-[24px] font-bold md:text-[36px]">Gift Messages</div>
      <div className="mt-4 flex items-center justify-between rounded-md py-[30px] pr-[18px] align-middle">
        <div className="flex items-center justify-between space-x-[12px]">
          <Checkbox checked={checkoutData.shipping.giftMessageCheckbox.show} onClick={checkboxToggle} />
          <div className="flex flex-col">
            <label htmlFor="same-billing-address" className="text-black">
              I would like to add gift messaging
            </label>
          </div>
        </div>
      </div>

      {shippingMode === 'Multiple Addresses' && (
        <GiftMessageMultiShipping show={checkoutData.shipping.giftMessageCheckbox.show} />
      )}
      {shippingMode === 'Single Address' && <GiftSingleMessage show={checkoutData.shipping.giftMessageCheckbox.show} />}
    </div>
  );
};
