import Analytics from '@adyen/adyen-web/dist/types/core/Analytics';
import { LineItem } from '@Types/cart/LineItem';
// import { pushToDataLayer, ANALYTICS_EVENTS, DataLayerObject } from 'frontastic/lib/utils/analytics-data-layer';
import { useCart } from 'frontastic/provider';
import { OrderSummary } from 'frontastic/tastics/order/summary';
import { useCheckoutContext } from '../contexts/checkout.context';

export const CheckoutBody = () => {
  const [{ steps, currentStep }] = useCheckoutContext();
  const cart = useCart();

  if (!cart.data || !cart.data.lineItems || cart.data.lineItems.length === 0) {
    return <></>;
  }

  cart.data.discountCodes;

  /*
  const { lineItems, discountCodes, orderId, taxed, shippingInfo } = cart.data;
  const estimatedSubtotal = lineItems.reduce((acc, item) => (acc += item.totalPrice.centAmount), 0);
  const products = lineItems.map((product: LineItem) => {
    return {
      id: product.productId,
      name: product.name,
      price: product.price,
      quantity: product.count,
    };
  });

  window['_cc_dataLayer'] = {
    coupon: discountCodes[0]?.code,
    id: orderId,
    revenue: estimatedSubtotal,
    tax: taxed?.amount,
    products: products,
    shipping: shippingInfo[0]?.shippingInfo?.price,
  };
  let dataLayerObject: DataLayerObject = {
    event: ANALYTICS_EVENTS.BEGIN_CHECKOUT,
  };

  switch (steps[currentStep].id) {

    case 'shipping':
      dataLayerObject = {
        event: ANALYTICS_EVENTS.BEGIN_CHECKOUT,
        ecommerce: {
          currency: 'USD',
          value: estimatedSubtotal,
          shipping: shippingInfo[0]?.shippingInfo?.price,
          tax: taxed?.amount,
          discounts: discountCodes,
          items: lineItems.map((product: LineItem) => ({
            item_id: product.variant.sku ? product.variant.sku : product.productId,
            item_name: product.name,
            item_variant: product.variant?.attributes?.tin_color?.label,
            coupon: product.discounts?.[0]?.code,
            discount: (product.price.centAmount - product.discountedPrice?.centAmount) / 100.0,
            price: product.price.centAmount / 100.0,
            quantity: product.count,
          })),
          fullCart: cart.data,
        },
      };
      break;

    case 'gift':
      dataLayerObject = {
        event: ANALYTICS_EVENTS.ADD_SHIPPING_INFO,
        ecommerce: {
          shipping_tier: cart.data?.shippingInfo[0]?.shippingKey,
          shipping: shippingInfo,
          fullCart: cart.data,
        },
      };
      break;
    case 'payment':
      break;
    case 'review':

      dataLayerObject = {
        event: ANALYTICS_EVENTS.ADD_PAYMENT_INFO,
        ecommerce: {
          payment_type: cart.data?.payments[0]?.paymentType,
          fullCart: cart.data,
        },
      };
      break;
  }
  pushToDataLayer(dataLayerObject); */

  return (
    <main className="grid grid-cols-1 lg:flex lg:grid-cols-2">
      <div className="bg-white px-4 lg:w-4/6 lg:px-16">
        <div className="w-full">{steps[currentStep].component}</div>
      </div>
      <div className="flex p-4 lg:w-3/6">
        <div className="w-full lg:px-8">
          <div className="pt-6 text-[24px] font-bold">Order Summary</div>
          {/* <CartSummaryMultiProduct
            data={{
              useCart: cart,
            }}
          /> */}
          <OrderSummary cart={cart} />
        </div>
      </div>
    </main>
  );
};
