/* eslint-disable tailwindcss/no-arbitrary-value */
// import { SingleImageWithDescription } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/product-overviews/single-image-with-description';
import { FC, useEffect, useState } from 'react';
import { Carousel } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/organisms/Carousel';
import { PDPDescription } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/product-overviews/pdp/description';
import { PDPPrice } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/product-overviews/pdp/price';
import { PDPTitle } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/product-overviews/pdp/title';
import { SlideOver } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/shopping-carts/slide-over';
import { LineItem } from '@ariessolutionsio/react-ecomm-ui/dist/types/cart/LineItem';
import { Money } from '@ariessolutionsio/react-ecomm-ui/dist/types/product/Money';
import { Variant } from '@ariessolutionsio/react-ecomm-ui/dist/types/product/Variant';
import { ImageCarousel } from '@ariessolutionsio/ui';
import _ from 'lodash';
import { pushToDataLayer, ANALYTICS_EVENTS, DataLayerObject } from 'frontastic/lib/utils/analytics-data-layer';
import { useCart } from 'frontastic/provider';
import { CartFooter } from 'frontastic/tastics/header/header/cart-footer';
import { CartHeader } from 'frontastic/tastics/header/header/cart-header';
import { ProductTiles } from 'frontastic/tastics/header/header/product-tiles';
import { PDPAddToCart } from './add-to-cart';
import { ProductReviewSummary } from './reviews';
import { useGetAccount } from '@/hooks/my-account/use-get-account';

/* The `reduceByCookieFlavor` function takes an array of objects representing product variants and
groups them by their `cookie_flavor` attribute. It returns an object where each key is a unique
`cookie_flavor` value and the corresponding value is an array of indexes of the original array where
that `cookie_flavor` value appears. */
function reduceByCookieFlavor(groupedArray) {
  return groupedArray.reduce((result, { cookie_flavor, indexes }) => {
    result[cookie_flavor] = indexes.reduce((arr, index) => {
      arr.push(cookie_flavor);
      return arr;
    }, []);
    return result;
  }, {});
}

/* The `reduceByTinColor` function takes an array of objects representing product variants and groups
them by their `tin_color` attribute. It returns an object where each key is a unique `tin_color`
value and the corresponding value is an array of indexes of the original array where that
`tin_color` value appears. */
function reduceByTinColor(groupedArray) {
  return groupedArray.reduce((result, { tin_color, indexes }) => {
    result[tin_color] = indexes.reduce((arr, index) => {
      arr.push(tin_color);
      return arr;
    }, []);
    return result;
  }, {});
}

const varianstButton = {
  selected:
    'bg-gray-800 text-center text-white rounded-full px-6 py-2 my-2  cursor-pointer ring-primary-800 whitespace-nowrap',
  unSelected:
    'bg-transparent text-center text-gray-800 rounded-full px-6 py-2 my-2  cursor-pointer ring-1 ring-gray-800 whitespace-nowrap',
  disabled:
    'bg-gray-200 text-gray-600 rounded-full px-6 py-2 my-2  cursor-not-allowed border-dotted border-2 border-gray-600 whitespace-nowrap',
};

const ProductDetailsTastic: FC = (props: any) => {
  const { addItem, updateItem, removeItem, data: order } = useCart();
  const { data: account } = useGetAccount();
  const [variantSelected, setVariantSelected] = useState(0);
  const [isOpenSideOver, setIsOpenSideOver] = useState<boolean>(false);

  useEffect(() => {
    // Pushing Analytics Tracking to DataLayer
    const productViewData: DataLayerObject = {
      event: ANALYTICS_EVENTS.VIEW_ITEM,
      ecommerce: {
        items: [
          {
            item_name: product?.name,
            item_id: product?.variants[0]?.sku,
            price: singleVariant?.price?.centAmount / 100.0,
            item_brand: 'christie cookie',
            item_category: product?.categories[0]?.name ? product?.categories[0]?.name : '',
            item_category2: product?.categories[1]?.name ? product?.categories[1]?.name : '',
            item_category3: product?.categories[2]?.name ? product?.categories[2]?.name : '',
            item_category4: product?.categories[3]?.name ? product?.categories[3]?.name : '',
            item_category5: product?.categories[4]?.name ? product?.categories[4]?.name : '',
            item_category6: product?.categories[5]?.name ? product?.categories[5]?.name : '',
            item_category7: product?.categories[6]?.name ? product?.categories[6]?.name : '',
            item_variant: singleVariant?.attributes?.tin_color?.label
              ? singleVariant?.attributes?.tin_color?.label
              : '',
          },
        ],
      },
    };
    pushToDataLayer(productViewData);
  }, []);

  if (!props.data.data.dataSource) return <></>;

  const variantsSrc = props.data.data.dataSource.product.variants.map((variant) => variant);

  /* Creating a new array of `Variant` objects by mapping over the `variantsSrc` array and transforming
  each object in the array to a new `Variant` object with additional properties. The new `Variant`
  objects have the same properties as the original objects in `variantsSrc`, but with an added
  `label` property that is set to the `label` property of the `tin_color` attribute if it exists, or
  to the string "Und" if it does not. The resulting array is assigned to the `variants` constant. */
  const variants: Variant[] = variantsSrc.map((variant) => {
    return {
      ...variant,
      label: variant.attributes.tin_color ? variant.attributes.tin_color.label : 'Und',
    };
  });

  const product = props.data.data.dataSource.product;

  const singleVariant = variants[variantSelected];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const canonicalLinks = document.getElementsByTagName('link');
    const { canonicalUrl } = singleVariant?.attributes;
    if (canonicalLinks && canonicalUrl) {
      // making it to delay because of page load with canonical before this effect
      setTimeout(() => {
        for (let i = 0; i < canonicalLinks.length; i++) {
          if (canonicalLinks[i].getAttribute('rel') === 'canonical') {
            canonicalLinks[i].setAttribute('href', canonicalUrl);
          }
        }
      }, 3000);
    }
  }, [singleVariant]);

  /* The onAddToCart function sets the openSlideOver state to true, which opens the cart slide over
  it also pushes the add to cart event to the data layer. */
  function onAddToCart(singleVariant, selectedQty) {
    setIsOpenSideOver(true);
    pushToDataLayer({
      event: ANALYTICS_EVENTS.ADD_TO_CART,
      ecommerce: {
        currency: 'USD',
        items: [
          {
            item_id: singleVariant?.sku,
            item_name: product?.name,
            item_category: product?.categories[0]?.name ? product?.categories[0]?.name : '',
            item_category2: product?.categories[1]?.name ? product?.categories[1]?.name : '',
            item_category3: product?.categories[2]?.name ? product?.categories[2]?.name : '',
            item_category4: product?.categories[3]?.name ? product?.categories[3]?.name : '',
            item_category5: product?.categories[4]?.name ? product?.categories[4]?.name : '',
            item_category6: product?.categories[5]?.name ? product?.categories[5]?.name : '',
            item_category7: product?.categories[6]?.name ? product?.categories[6]?.name : '',
            item_variant: singleVariant?.attributes?.tin_color?.label,
            price: singleVariant?.price?.centAmount / 100.0,
            quantity: selectedQty,
          },
        ],
      },
    });
  }

  /* The `groupArrayElements` function takes an array of product variants and groups them by their
`cookie_flavor` and `tin_color` attributes. It returns an array of objects where each object
represents a unique combination of `cookie_flavor` and `tin_color` values, and contains an array of
indexes of the original array where that combination appears. This function is used to group the
product variants by their attributes so that they can be displayed as options for the user to select
from. */
  function groupArrayElements(arr) {
    const groups = {};

    arr.forEach((element, index) => {
      const { cookie_flavor, tin_color } = element.attributes;
      const cookie_flavor_key = cookie_flavor ? cookie_flavor.key : 'Und';
      const tin_color_key = tin_color ? tin_color.key : 'Und';

      //if (!cookie_flavor || !tin_color) return;

      if (!groups[cookie_flavor_key]) {
        groups[cookie_flavor_key] = {};
      }

      if (!groups[cookie_flavor_key][tin_color_key]) {
        groups[cookie_flavor_key][tin_color_key] = [];
      }

      groups[cookie_flavor_key][tin_color_key].push(index);
    });

    const groupedArray = [];

    if (singleVariant.attributes.cookie_flavor) {
      for (const cookieFlavorKey in groups) {
        for (const tinColorKey in groups[cookieFlavorKey]) {
          const indexes = groups[cookieFlavorKey][tinColorKey];
          const cookieFlavorLabel = arr[indexes[0]].attributes.cookie_flavor.label;

          const tinColorLabel = arr[indexes[0]].attributes.tin_color ? arr[indexes[0]].attributes.tin_color.label : '';

          groupedArray.push({
            cookie_flavor: cookieFlavorLabel,
            tin_color: tinColorLabel,
            indexes,
          });
        }
      }

      return groupedArray;
    }

    if (!singleVariant.attributes.cookie_flavor && singleVariant.attributes.tin_color) {
      for (const key in groups['Und']) {
        const indexes = groups['Und'][key];
        const tinColorLabel = arr[indexes[0]].attributes.tin_color.label;

        groupedArray.push({
          cookie_flavor: 'Und',
          tin_color: tinColorLabel,
          indexes,
        });
      }
      return groupedArray;
    }
  }

  const cart = {
    data: order,
    addItem: addItem,
    removeItem: removeItem,
    updateItem: updateItem,
  };

  const TinColorFilter = () => {
    if (!singleVariant.attributes.tin_color) return <> </>;

    const keys = Object.keys(reduceByTinColor(groupArrayElements(product.variants)));

    const variantsAvailable = variants.filter((variant) =>
      !variant.attributes.cookie_flavor
        ? true
        : variant.attributes.cookie_flavor.key === singleVariant.attributes.cookie_flavor.key,
    );
    const availableFlavors = Object.keys(reduceByTinColor(groupArrayElements(variantsAvailable)));

    const tinColorSelected = singleVariant.attributes.tin_color.label;

    const filterByColor = (color) => {
      const filtered = product.variants.filter((variant) => {
        return variant.attributes.tin_color.label === color;
      });

      const toBeSelected = singleVariant.attributes.cookie_flavor
        ? filtered.find((e) => e.attributes.cookie_flavor.label === singleVariant.attributes.cookie_flavor.label)
        : filtered[0];

      setVariantSelected(variants.findIndex((v) => v.id === toBeSelected.id));
    };
    return (
      <>
        <div className="mt-6 font-bold">Choose your Tin Color</div>
        <div className="w-[350px] md:w-[700px]">
          <Carousel
            items={keys.map((key) => (
              <div className="relative flex justify-center" key={key}>
                <div
                  onClick={() => _.includes(availableFlavors, key) && filterByColor(key)}
                  className={`mx-1 ${
                    tinColorSelected === key
                      ? varianstButton.selected
                      : _.includes(availableFlavors, key)
                      ? varianstButton.unSelected
                      : varianstButton.disabled
                  }`}
                >
                  {key}
                </div>
              </div>
            ))}
          />
        </div>
      </>
    );
  };

  const CookieFlavor = () => {
    if (!singleVariant.attributes.cookie_flavor) return <></>;
    const keys = Object.keys(reduceByCookieFlavor(groupArrayElements(product.variants)));

    const cookieFlavorSelected = singleVariant.attributes.cookie_flavor.label;

    const filterByFlavor = (flavor) => {
      const filtered = product.variants.filter((variant) => {
        return variant.attributes.cookie_flavor.label === flavor;
      });

      const toBeSelected = filtered
        .filter((e) => e && e.attributes.tin_color) //FIX: Only products with tin_color
        .find((e) => e.attributes.tin_color.label === singleVariant.attributes.tin_color.label);

      const idToSearch = toBeSelected ? toBeSelected.id : filtered[0].id;

      setVariantSelected(variants.findIndex((v) => v.id === idToSearch));
    };
    return (
      <>
        <div className="font-bold">Choose your Cookie Flavor</div>
        <div className="w-[350px]   md:w-full">
          <Carousel
            items={keys.map((key) => (
              <div
                key={key}
                onClick={() => filterByFlavor(key)}
                className={`mx-1 ${cookieFlavorSelected === key ? varianstButton.selected : varianstButton.unSelected}`}
              >
                {key}
              </div>
            ))}
            extendedClasses={{ carousel: 'md:flex-wrap pl-px' }}
          />
        </div>
      </>
    );
  };

  return (
    <div className=" ">
      <div className="mx-auto lg:max-w-[1380px]">
        {/* <ProductDetail {...dataStructure} /> */}

        <div data-testid="product-overview" className="bg-transparent">
          <div className="mx-auto px-8 py-16 sm:py-24 lg:px-0 lg:pt-14">
            <div className="mb-3 space-y-3 lg:hidden">
              <PDPTitle title={product.name} extendedClasses="text-[32px] md:text-[52px]" />
            </div>
            <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
              <ImageCarousel
                data={singleVariant.images.map((image, index) => ({
                  id: index.toString(),
                  url: image,
                  alt: singleVariant?.imagesAltText?.[index] || product?.name,
                }))}
                classNameThumbs="border-2 border-primary-900 rounded-lg"
              />
              <div className="mt-3 sm:mt-16 sm:px-0 md:mt-10 lg:mt-0">
                <div className="hidden lg:block">
                  <PDPTitle title={product.name} extendedClasses="text-[32px] md:text-[52px]" />
                </div>
                <div className="mt-3">
                  <PDPPrice
                    price={singleVariant.price}
                    extendedClasses="pb-0 text-primary-800 text-[24px] lg:text-[36px] font-bold md:text-[36px]"
                  />
                </div>
                <ProductReviewSummary
                  product={product}
                  powerReviewsPageId={singleVariant?.attributes?.PowerReviews_PageID}
                />
                <div className="mt-3 md:mt-6">
                  <PDPDescription description={product.description} />
                </div>
                <div className="mt-10">
                  <CookieFlavor />
                  <TinColorFilter />
                </div>
                <div className="mt-10 lg:flex">
                  <div className="mr-4">
                    <div className="mb-2 font-bold">Quantity</div>
                    <div>
                      <PDPAddToCart
                        useCart={cart as any}
                        singleVariant={singleVariant}
                        onAddToCart={onAddToCart}
                        store={account && account.stores[0]}
                        isOnStock={singleVariant?.isOnStock}
                        extended={{
                          addToBagIcon: <BagIcon color={singleVariant?.isOnStock ? 'white' : 'gray'} />,
                          addToBagIconPosition: 'right',
                        }}
                      />
                    </div>
                    <SlideOver
                      open={isOpenSideOver}
                      setOpen={setIsOpenSideOver}
                      cartHeader={<CartHeader cart={cart as any} />}
                      productTiles={<ProductTiles items={cart.data?.lineItems as LineItem[]} cart={cart as any} />}
                      cartFooter={<CartFooter sum={cart.data?.sum as Money} cart={cart as any} discountCodes={[]} />}
                    />
                  </div>
                </div>
                <div className="mt-8 space-y-1 text-xs">
                  <p>
                    Nutritional Facts, Ingredients and Allergens{' '}
                    <a href="/flavors-nutrition" className="text-primary-800">
                      Learn More
                    </a>
                  </p>
                  <p>
                    Need assistance with your order? Our cookie experts{' '}
                    <a href="/contact-us" className="text-primary-800">
                      can help
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsTastic;

const BagIcon = ({ color = 'white' }) => {
  return (
    <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.17857 1.62329L1.5 5.19472V17.6947C1.5 18.1683 1.68814 18.6225 2.02302 18.9574C2.35791 19.2923 2.81211 19.4804 3.28571 19.4804H15.7857C16.2593 19.4804 16.7135 19.2923 17.0484 18.9574C17.3833 18.6225 17.5714 18.1683 17.5714 17.6947V5.19472L14.8929 1.62329H4.17857Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.5 5.19458H17.5714" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.1067 8.76587C13.1067 9.71307 12.7304 10.6215 12.0607 11.2913C11.3909 11.961 10.4825 12.3373 9.5353 12.3373C8.58809 12.3373 7.67969 11.961 7.00991 11.2913C6.34014 10.6215 5.96387 9.71307 5.96387 8.76587"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
