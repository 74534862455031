import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { OrderConfirmation } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/Ecommerce/checkout/order-confirmation';
import { CheckoutRegisterForm } from 'components/commercetools-ui/checkout/order-confirmation/CheckoutFasterForm';
import { getOrderByNumber } from 'frontastic/actions/order';
import { pushToDataLayer, ANALYTICS_EVENTS, DataLayerObject } from 'frontastic/lib/utils/analytics-data-layer';
import { useAccount } from 'frontastic/provider';

export const ThankYou = (props) => {
  const { shipSpeedData, bypassVerification } = props.data;

  const { loggedIn } = useAccount();

  const router = useRouter();
  const { order: orderNumber } = router.query;
  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (router.asPath.includes('thank-you') && router.query?.order && order) {
      window['cc_data_layer'].orderData = order;
    }
  }, [router.asPath, order]);

  useEffect(() => {
    getOrderByNumber(orderNumber as string)
      .then((result) => {
        setOrder(result);
        const orderLineItems = result?.lineItems?.map((lineItem) => {
          return {
            page_id: lineItem?.variant?.attributes?.PowerReviews_PageID,
            page_id_variant: lineItem?.variant?.sku,
            product_name: lineItem?.name,
            quantity: lineItem?.count,
            unit_price: lineItem?.price?.centAmount / 100,
          };
        });
        window['cc_data_layer'] = {
          order: {
            userEmail: result?.email,
            userFirstName: result?.billingAddress?.firstName,
            userLastName: result?.billingAddress?.lastName,
            orderId: result?.orderId,
            orderItems: orderLineItems,
          },
          ecommerce: {
            currency: result?.sum?.currencyCode,
            value: result?.sum?.centAmount / 100.0,
            transaction_id: result.orderId,
            customer_id: result.email,
            customer_email: result.email,
            purchase: {
              items: result.lineItems.map((product) => ({
                item_id: product.variant.sku ? product.variant.sku : product.productId,
                item_name: product.name,
                item_variant: product.variant?.attributes?.tin_color?.label,
                coupon: product.discounts?.[0]?.code,
                discount: (product.price.centAmount - product.discountedPrice?.centAmount) / 100.0,
                price: product.price.centAmount / 100.0,
                quantity: product.count,
              })),
            },
            fullOrder: result,
          },
        };
      })
      .catch((error) => {
        console.log(error);
      });
  }, [orderNumber]);

  if (!order) {
    return <></>;
  }

  // Clean up white space in gift messages
  if (order && order.shippingInfo) {
    for (let i = 0; i < order.shippingInfo.length; i++) {
      order.shippingInfo[i].shippingInfo.giftMessage = order.shippingInfo[i].shippingInfo.giftMessage
        ? order.shippingInfo[i].shippingInfo.giftMessage.trim()
        : '';
      const discountedShippingPrice = order.shippingInfo[i].shippingInfo.discountedPrice?.value;
      if (discountedShippingPrice?.centAmount) {
        order.shippingInfo[i].shippingInfo.price = discountedShippingPrice;
      }
    }
  }
  // This data layer push for purchase event is commented to fix duplicate entries in GTM
  // https://dev.azure.com/DemandCreationProgram/E-Com/_workitems/edit/27995
  /* const dataLayerObject: DataLayerObject = {
    event: ANALYTICS_EVENTS.PURCHASE,
    ecommerce: {
      currency: order?.sum?.currencyCode,
      value: order?.sum?.centAmount / 100.0,
      transaction_id: order.orderId,
      customer_id: order.email,
      customer_email: order.email,
      purchase: {
        items: order.lineItems.map((product) => ({
          item_id: product.variant.sku ? product.variant.sku : product.productId,
          item_name: product.name,
          item_variant: product.variant?.attributes?.tin_color?.label,
          coupon: product.discounts?.[0]?.code,
          discount: (product.price.centAmount - product.discountedPrice?.centAmount) / 100.0,
          price: product.price.centAmount / 100.0,
          quantity: product.count,
        })),
      },
      fullOrder: order,
    },
  };
  console.log('purchase event fired');
  pushToDataLayer(dataLayerObject); */

  return (
    <div className="mx-6 mt-8 lg:mx-28 lg:mt-20">
      <OrderConfirmation
        orderId={order.orderId}
        email={order.email}
        paymentType={{
          cardNumber: order.payments[0]?.paymentType,
        }}
        billingAddress={{
          addressId: order.billingAddress?.addressId,
          firstName: order.billingAddress?.firstName,
          lastName: order.billingAddress?.lastName,
          streetName: order.billingAddress?.streetName,
          city: order.billingAddress?.city,
          state: order.billingAddress?.state,
          country: order.billingAddress?.country,
          postalCode: order.billingAddress?.postalCode,
          additionalAddressInfo: order.billingAddress?.additionalAddressInfo,
          apartment: order.billingAddress?.additionalAddressInfo ?? '',
          companyName: order.billingAddress?.companyName ?? '',
        }}
        shippingAddress={order.shippingAddress}
        shippingInfo={order.shippingInfo}
        lineItems={order.lineItems}
        itemSubtotal={order.itemSubtotal}
        discountedCodes={{
          code: order.discountCodes[0]?.code,
          name: order.discountCodes[0]?.name,
          discountedAmount: order.discountCodes[0]?.discountedAmount,
        }}
        sum={order.sum}
        shipSpeedData={shipSpeedData}
        taxed={order.taxed}
        taxedPrice={order.taxedPrice}
      />
      {!loggedIn && <CheckoutRegisterForm order={order} bypassVerification={bypassVerification} />}
    </div>
  );
};
