// @ts-nocheck
import { FC, useMemo } from 'react';
import { Review } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/Ecommerce/checkout/review';
import { EditIcon } from '@ariessolutionsio/cc-custom-components/dist/utils/icons';
import { useCart } from 'frontastic/provider';
import { ReviewAndSummaryMultiShipping } from './multi-shipping';
import { useCheckoutContext } from '../../contexts/checkout.context';

export const ReviewAndSummary: FC = () => {
  const [{ checkoutData, shippingMode }, { nextStep, prevStep }, setCurrentStep] = useCheckoutContext();
  const cart = useCart();

  const giftMessage: GiftMessage = useMemo(() => {
    return cart?.data?.lineItems?.length >= 0
      ? cart?.data?.lineItems[0]?.custom?.giftMessages?.map((item) => {
          const addressId = item.split('|')[0];
          const shippingAddressInfo = cart.data.shippingAddress;
          const address = shippingAddressInfo.find((item) => item.addressId === addressId);

          let messageLines = [];
          let name = '';

          if (item.includes('From:')) {
            messageLines = ['No Gift Messages'];
          } else {
            messageLines = [
              checkoutData.shipping.giftMessages.giftMessage1 || '',
              checkoutData.shipping.giftMessages.giftMessage2 || '',
              checkoutData.shipping.giftMessages.giftMessage3 || '',
              checkoutData.shipping.giftMessages.giftMessage4 || '',
              checkoutData.shipping.giftMessages.giftMessage5 || '',
            ];
            name = `${address?.firstName} ${address?.lastName}`;
          }

          return {
            messageLines,
            name,
          };
        })
      : [];
  }, [cart.data.lineItems, cart.data.shippingAddress]);

  const shippingSpeed = checkoutData.shipping.shipSpeed;
  const totalShippingPrice = cart.data?.shippingInfo?.reduce(
    (accumulator, item) => accumulator + (item?.shippingInfo?.price?.centAmount ?? 0),
    0,
  );

  return (
    <div>
      <div className="py-8 font-['Akshar'] text-2xl font-bold md:text-4xl">Review & Submit</div>
      {shippingMode === 'Multiple Addresses' && <ReviewAndSummaryMultiShipping />}
      <div>
        {shippingMode === 'Single Address' && (
          <Review
            shippingAddress={[
              {
                ...checkoutData.shipping.shipping,
                streetName: checkoutData.shipping.shipping.streetAddress,
                companyName: checkoutData?.shipping?.shipping?.company,
                postalCode: String(checkoutData.shipping.shipping.zipCode),
              },
            ]}
            shippingMethod={{
              method: shippingSpeed.title,
              price: {
                fractionDigits: 2,
                centAmount: totalShippingPrice ?? 0,
                currencyCode: 'USD',
              },
            }}
            shipDate={{
              date: checkoutData.shipping?.shippingDate?.startDate?.split('T')[0],
            }}
            giftMessages={giftMessage}
            contactInfo={{
              email: checkoutData.shipping.contactInfo.email,
              phoneNumber: Number(checkoutData.shipping.contactInfo.phoneNumber.replaceAll('-', '')),
            }}
            paymentMethod={{
              cardNumber: checkoutData.payment.paymentPayload.payment_method_preview.card.last4, //TODO: Remove after complete alt checkoutData.payment.paymentDetails.cardNumberValue,
            }}
            billingAddress={{
              name: `${checkoutData.payment.billing.firstName} ${checkoutData.payment.billing.lastName}`,
              street: `${checkoutData.payment.billing.streetAddress}`,
              state: checkoutData.payment.billing.state,
              country: '',
              company: checkoutData?.payment?.billing?.company,
              apartment: checkoutData?.payment?.billing?.apartment,
              city: checkoutData.payment.billing.city,
              postalCode: String(checkoutData.payment.billing.zipCode),
            }}
            editIcon={EditIcon}
            setCurrentStep={setCurrentStep}
          />
        )}
      </div>
    </div>
  );
};
