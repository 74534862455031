import { FC } from 'react';
import { WithSocialMedia } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/hero-sections/with-social-media';

export const CarouselWithSocialMedia: FC<any> = (props) => {
  const socialMediaData = props;
  const newSocialMediaData = socialMediaData.data.socialMediaImages.map((item: any) => {
    return { ...item, media: { ...item.media.media } };
  });
  socialMediaData.data.socialMediaImages = newSocialMediaData;
  const socialMediaCta = socialMediaData.data.callToAction[0];
  const ctaLabelWeight = socialMediaCta.labelBold ? 'font-bold' : 'font-normal';
  const ctaLabelItalic = socialMediaCta.labelItalic ? 'italic' : 'not-italic';
  const ctaLabelFontSize = socialMediaCta.labelFontSize ? `text-[${socialMediaCta.labelFontSize}px]` : 'text-xs';

  return (
    <div>
      <WithSocialMedia
        data={{
          ...socialMediaData.data,
          callToAction: socialMediaCta,
        }}
        extendedClasses={{
          cta: `${ctaLabelWeight} ${ctaLabelItalic} ${ctaLabelFontSize}`,
        }}
      />
    </div>
  );
};
