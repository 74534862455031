export const CategoryLandingPageBannerDynamic = ({ pageFolder }) => {
  const imageBannerMobile = pageFolder.dataSourceConfigurations[0].preloadedValue.imageBannerMobile ?? null;
  const imageBannerDesktop = pageFolder.dataSourceConfigurations[0].preloadedValue.imageBannerDesktop ?? null;

  if (imageBannerMobile || imageBannerDesktop) {
    return (
      <div className="relative h-[200px] w-full lg:h-[300px]">
        <div className="absolute inset-0 h-full w-full">
          <picture>
            <source srcSet={imageBannerMobile} media="(max-width: 768px)" />
            <img
              srcSet={imageBannerDesktop || imageBannerMobile}
              className="h-full w-full object-cover object-center"
              alt=""
            />
          </picture>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
