import { SimpleHeroWithMedia as WithContrastSupportAndMedia } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/marketing/hero-sections/simple-with-media';
function FlippedHeroWithMediaTastic({ data }) {
  const formatData = {
    ...data,
    callToAction: data?.callToAction?.[0]?.name ? data?.callToAction : null,
  };
  const fontColor = data?.darkMode ? 'text-white' : 'text-[#2B2A2D]';
  const ctaLabelWeight = data?.labelBold ? 'font-bold' : 'font-normal';
  const ctaLabelItalic = data?.labelItalic ? 'italic' : 'not-italic';
  const ctaLabelFontSize = data?.labelFontSize ? `text-[${data?.labelFontSize}px]` : 'text-xs';

  return (
    <WithContrastSupportAndMedia
      data={formatData}
      extendedClasses={{
        title: `font-bold my-6 mx-8 text-center md:text-left ${fontColor}`,
        description: `mx-8 text-center md:text-left ${fontColor}`,
        eyebrow: `mx-8 mt-4 text-center md:text-left ${fontColor}`,
        cta: 'md:ml-8 my-10 text-center md:text-left',
        ctaLabelStyle: `${ctaLabelWeight} ${ctaLabelItalic} ${ctaLabelFontSize}`,
      }}
    />
  );
}

export default FlippedHeroWithMediaTastic;
