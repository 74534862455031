import { Fragment, useState } from 'react';
import { Address } from '@ariessolutionsio/providers-richproducts';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { DefaultAddressesCard } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/my-account/default-addresses-card';
import { EmailNewsLetter } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/my-account/email-news-letter';
import { PersonalInfo } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/my-account/my-info';
import { PasswordSection } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/my-account/password-section';
import { cn } from '@ariessolutionsio/react-ecomm-ui/dist/utils/classNames';
import { Dialog, Transition } from '@headlessui/react';
import Spinner from '@/components/commercetools-ui/spinner';
import { useGetAccount } from '@/hooks/my-account/use-get-account';
import { useFormat } from 'helpers/hooks/useFormat';
import { useDarkMode } from 'frontastic';
import { SingleAddressAddEdit } from '../addresses/singleAddressAddEdit';

export const AccountInfo = (props: any) => {
  const { data, isLoading } = useGetAccount();
  const { formatMessage } = useFormat({ name: 'account' });
  const { mode, theme } = useDarkMode();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(undefined);

  const defaultBillingAddressDetails = {
    address: data?.addresses.find((a) => a.isDefaultBillingAddress),
    label: 'My Billing Address',
    editButtonLabel: 'EDIT ADDRESS',
    editAddressOnClick: (address: Address) => {
      setSelectedAddress(address);
      setIsDialogOpen(true);
    },
  };

  const defaultShippingAddressDetails = {
    address: data?.addresses.find((a) => a.isDefaultShippingAddress),
    label: 'My Shipping Address',
    editButtonLabel: 'EDIT ADDRESS',
    editAddressOnClick: (address: Address) => {
      setSelectedAddress(address);
      setIsDialogOpen(true);
    },
  };
  const myInfoEditDetails = {
    editHref: '/my-account/edit-info',
    editString: 'Edit',
    isEditable: true,
  };

  const passwordSectionEditDetails = {
    editHref: '/my-account/change-password',
    editString: 'Change your password',
    isEditable: true,
  };

  const emailNewsLetterEditDeatils = {
    editHref: '/my-account/edit-email-subscription',
    editString: 'Edit',
    isEditable: true,
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      {/* Title and subtitle */}
      <div className="mb-8 grid gap-5 lg:mb-14 lg:gap-6">
        {
          <Typography variant="h1" tagName="h1" className={cn(['text-black hidden lg:block'])}>
            {`${formatMessage({ id: 'hello', defaultMessage: 'Hi, ' })}${data?.firstName ?? ''}`}
          </Typography>
        }
        {
          <Typography className="hidden text-current lg:block lg:text-base">
            {formatMessage({ id: 'account.desc', defaultMessage: 'Manage your account and subscriptions.' })}
          </Typography>
        }
      </div>

      {/* Sections */}
      <div className="grid gap-8 lg:gap-6">
        {
          <>
            {data?.firstName && (
              <PersonalInfo
                account={{ ...data, birthday: data?.dateOfBirth, phoneNumber: data.metadata.phoneNumber }}
                editDetails={myInfoEditDetails}
              />
            )}
            <DefaultAddressesCard
              title="Default Billing & Shipping Address"
              defaultBillingAddressDetails={defaultBillingAddressDetails}
              defaultShippingAddressDetails={defaultShippingAddressDetails}
            />
            <PasswordSection editDetails={passwordSectionEditDetails} />
            <EmailNewsLetter
              editDetails={emailNewsLetterEditDeatils}
              isEmailSubscribed={data?.metadata?.isSubscribedToNewsletter}
            />
          </>
        }
      </div>
      <Transition.Root show={isDialogOpen} as={Fragment}>
        <Dialog className="relative z-10" onClose={() => setIsDialogOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
          </Transition.Child>

          <div className={`${mode} ${theme} fixed inset-0 overflow-y-auto`}>
            <div className="flex min-h-full w-full items-center justify-center p-4 sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div>
                  <SingleAddressAddEdit
                    handleParentCallback={() => {
                      setIsDialogOpen(false);
                    }}
                    addressToEdit={selectedAddress}
                    operationType={'Edit'}
                    handleReturnToParent={() => setIsDialogOpen(false)}
                    provincesList={props.provincesList ?? []}
                  />
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
