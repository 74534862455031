/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable tailwindcss/no-custom-classname */
import { useCallback, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  Dialog,
  Form,
  Separator,
  Text,
  useDialogContext,
  Button,
  Input as AsioInput,
} from '@ariessolutionsio/primitives-richproducts';
import { Input } from '@ariessolutionsio/react-ecomm-ui/dist';
import { cn } from '@ariessolutionsio/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useSWRConfig } from 'swr';
import * as Yup from 'yup';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import useScreenOrientation from '@/hooks/useOrientation';

const signInSchema = Yup.object().shape({
  username: Yup.string().email('Please enter a valid email address').required('Please enter a valid email address'),
  password: Yup.string().required('Please enter a password'),
});

type SignInSchemaType = Yup.InferType<typeof signInSchema>;

export type SignInDialogProps = {
  title: string;
  onSignIn?: (values: SignInSchemaType) => Promise<any>;
  requestConfirmationEmail?: (values: SignInSchemaType) => Promise<any>;
};

export const SignInDialog = ({ title, ...props }: SignInDialogProps) => {
  const queryClient = useQueryClient();
  const { mutate } = useSWRConfig();
  const [, { close }] = useDialogContext();
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const form = useForm({
    resolver: yupResolver(signInSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const { isMobile } = useDeviceDetect();
  const orientation = useScreenOrientation();

  const onSubmitCoFe = async (values: SignInSchemaType) => {
    setError('');
    setLoading(true);
    try {
      const response = await props.onSignIn(values);

      if (response === 'This account is not confirmed. Click here to resend confirmation email.') {
        setError(response);
        queryClient.removeQueries({ queryKey: ['get:account'] });
      } else if (response === 'Failed to login account with the given credentials') {
        setError('Invalid email or password');
        queryClient.removeQueries({ queryKey: ['get:account'] });
      } else if (response) {
        close();
        router.push('/');
      } else {
        setError(response);
        queryClient.removeQueries({ queryKey: ['get:account'] });
      }
    } catch (err) {
      setError('Invalid email or password');
    } finally {
      setLoading(false);
    }
  };

  const onSubmitForVerify = async (values: SignInSchemaType) => {
    setError('');
    try {
      const response = await props.requestConfirmationEmail(values);
      if (response) {
        mutate('me');
        close();
      } else {
        setError(response);
      }
    } catch (err) {
      setError('Invalid email or password');
    } finally {
      setLoading(false);
    }
  };

  const sendVerificationEmail = async () => {
    await form.trigger();
    const payload = {
      username: form.getValues('username'),
      password: form.getValues('password'),
    };
    if (form.formState.isValid) {
      onSubmitForVerify(payload);
    }
  };

  const renderLoginErrorMsg = (input: string) => {
    if (typeof input === 'boolean') return '';
    if (!input?.includes('Click here')) return <Text className="p-0 pl-1 text-primary-800 ">{input}</Text>;
    const updatedErrorString = input.split('Click here');
    return (
      <Text className="p-0 pl-1 text-primary-800">
        {updatedErrorString[0]}
        <a href="#" onClick={sendVerificationEmail} className="underline hover:cursor-pointer">
          Click here
        </a>
        {updatedErrorString[1]}
      </Text>
    );
  };

  const memoizedErrorMsg = useMemo(() => renderLoginErrorMsg(error), [error]);

  return (
    <div className="mt-10 block">
      <Dialog {...props}>
        <Dialog.Content
          className={cn(
            orientation === 'landscape-primary' && isMobile
              ? 'flex h-[300px] flex-initial flex-col overflow-scroll '
              : '',
            'border-black bg-white',
          )}
        >
          <Dialog.Header>
            <Dialog.Title>{title}</Dialog.Title>
          </Dialog.Header>
          <Form onSubmit={form.handleSubmit(onSubmitCoFe)}>
            <Form.Section>
              <Form.Control name="username" label="Email Address" error={form?.formState?.errors?.username?.message}>
                <Input {...form.register('username')} />
              </Form.Control>
              <Form.Control name="password" label="Password" error={form?.formState?.errors?.password?.message}>
                <AsioInput.Password {...form.register('password')} />
              </Form.Control>
              <Form.Control name="actions">
                <div className="flex flex-row justify-between space-x-5 rounded-md">
                  <Button type="submit" className="w-full bg-primary-800 text-white" loading={loading}>
                    {loading ? 'SIGNING IN...' : 'SIGN IN'}
                  </Button>
                  <Button
                    variant="link"
                    className="w-full text-primary-800"
                    onClick={() => {
                      close();
                      router.push('/forgot-password');
                    }}
                  >
                    Forgot Password?
                  </Button>
                </div>
              </Form.Control>
              {error !== '' && memoizedErrorMsg}
            </Form.Section>
          </Form>
          <Separator className="h-[1px] bg-gray-300" />
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-center">
              <Text className="text-black">No Account? No problem, </Text>
              <Button
                variant="link"
                className="p-0 pl-1 text-primary-800 hover:cursor-pointer hover:underline"
                onClick={() => {
                  close();
                  router.push('/my-account/create');
                }}
              >
                create one here!
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </div>
  );
};
