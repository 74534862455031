import { FC } from 'react';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { UploadAddresses } from 'components/common/upload-addresses';
import Sidemenu from '../side-menu';

export const AddressUploadCSV: FC = () => {
  return (
    <div>
      <Sidemenu hash={'/my-account/addresses'} contentTitle="">
        <div className="flex justify-center">
          <div className="flex flex-col items-center space-y-3 rounded-lg bg-white py-16 lg:w-[783px]">
            <div className="flex flex-col items-center justify-center space-y-3 lg:w-[650px]">
              <Typography variant="h3">Upload Addresses</Typography>
              <Typography variant="body">Upload a CSV to add to your address book.</Typography>
              <UploadAddresses showDropzone={true} />
            </div>
          </div>
        </div>
      </Sidemenu>
    </div>
  );
};
