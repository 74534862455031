import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { pushToDataLayer, ANALYTICS_EVENTS, DataLayerObject } from '@/frontastic/lib/utils/analytics-data-layer';

export const send_event_shipping_info = (cart: Cart) => {
  const { lineItems, discountCodes, taxed, shippingInfo } = cart;
  const estimatedSubtotal = lineItems.reduce((acc, item) => (acc += item.totalPrice.centAmount), 0);

  let dataLayerObject: DataLayerObject = {
    event: ANALYTICS_EVENTS.BEGIN_CHECKOUT,
  };

  dataLayerObject = {
    event: ANALYTICS_EVENTS.BEGIN_CHECKOUT,
    ecommerce: {
      currency: 'USD',
      value: estimatedSubtotal,
      shipping: shippingInfo[0]?.shippingInfo?.price,
      tax: taxed?.amount,
      discounts: discountCodes,
      items: lineItems.map((product: LineItem) => ({
        item_id: product.variant.sku ? product.variant.sku : product.productId,
        item_name: product.name,
        item_variant: product.variant?.attributes?.tin_color?.label,
        coupon: product.discounts?.[0]?.code,
        discount: (product.price.centAmount - product.discountedPrice?.centAmount) / 100.0,
        price: product.price.centAmount / 100.0,
        quantity: product.count,
      })),
      fullCart: cart,
    },
  };

  pushToDataLayer(dataLayerObject);
};

export const send_event_begin_checkout = (cart: Cart) => {
  const { lineItems, discountCodes, taxed, shippingInfo } = cart;
  const estimatedSubtotal = lineItems.reduce((acc, item) => (acc += item.totalPrice.centAmount), 0) / 100.0;

  let dataLayerObject: DataLayerObject = {
    event: ANALYTICS_EVENTS.BEGIN_CHECKOUT,
  };

  dataLayerObject = {
    event: ANALYTICS_EVENTS.BEGIN_CHECKOUT,
    ecommerce: {
      currency: 'USD',
      value: estimatedSubtotal,
      shipping: shippingInfo[0]?.shippingInfo?.price ? shippingInfo[0]?.shippingInfo?.price : 0.0,
      tax: taxed?.amount ? taxed?.amount : 0.0,
      discounts: discountCodes,
      items: lineItems.map((product: LineItem) => ({
        item_id: product.variant.sku ? product.variant.sku : product.productId,
        item_name: product.name,
        item_variant: product.variant?.attributes?.tin_color?.label,
        coupon: product.discounts?.[0]?.code,
        discount: (product.price.centAmount - product.discountedPrice?.centAmount) / 100.0,
        price: product.price.centAmount / 100.0,
        quantity: product.count,
      })),
    },
  };

  pushToDataLayer(dataLayerObject);
};
