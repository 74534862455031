import { SimpleAccordion } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/SimpleAccordion';

export const Accordion = (props) => {
  const { data } = props;
  return (
    <div className="p-10">
      <SimpleAccordion
        items={data?.items}
        extendedClasses={{
          title: 'text-[24px] leading-[31.2px] hover:black text-gray-900 font-galanoClassic',
          description: 'font-normal text-[24px] leading-[31.2px] text-[#000000] font-galanoClassic',
        }}
      />
    </div>
  );
};
