import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useToast } from '@ariessolutionsio/primitives-richproducts';
import { useAsio } from '@ariessolutionsio/providers-richproducts';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { cn } from '@ariessolutionsio/react-ecomm-ui/dist/utils/classNames';
import CSVFileValidator from 'csv-file-validator';
import { useDropzone } from 'react-dropzone';
import useSWR from 'swr';
import { generateUniqueIdNumber } from 'frontastic/actions/account';
import CustomLoader from 'frontastic/provider/loading/CustomLoader';

interface UploadAddressesProps {
  showDropzone?: boolean;
}

const validateNoSpecialChars = (value) => {
  if (/[^a-zA-Z\s\-.'"]/g.test(value)) {
    return false;
  }
  return true;
};

function validateMaxChars(value, charLimit) {
  return value.length < charLimit;
}

function isValidZipCode(zipCode: string): boolean {
  const zipCodePattern = /^(?:\d{5}(?:[-\s]\d{4})?|\d{9})$/;
  return zipCodePattern.test(zipCode);
}

function validateState(state) {
  // Check if the state value contains only letters and has a length of 2
  return /^[A-Z]{2}$/.test(state);
}

function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

export function usePrevious<T>(value: T): any {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current as T;
}

export const UploadAddresses: FC<UploadAddressesProps> = ({ showDropzone }) => {
  const { toast } = useToast();
  const [error, setError] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);
  const [{ addresses, customers }] = useAsio();
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [firstPartUploaded, setFirstPartUploaded] = useState(false);
  const [secondPart, setSecondPart] = useState([]);

  const { data } = useSWR('me', () => customers.me(), {
    revalidateIfStale: firstPartUploaded,
    refreshInterval: 1000,
  });

  const previousValue = usePrevious(data?.version);

  const parseAddress = useCallback(async (address) => {
    const addressId = await genGenIDNumber('ADDRESS');
    const customData = {
      autoGenID: addressId?.toString() ?? undefined,
    };
    return {
      firstName: address.firstName,
      lastName: address.lastName,
      streetName: address.streetName,
      streetNumber: '',
      company: address.company,
      city: address.city,
      state: address.state,
      postalCode: address.zip,
      country: 'US',
      key: customData.autoGenID,
    };
  }, []);

  useEffect(() => {
    if (firstPartUploaded && previousValue !== data?.version) {
      setTimeout(async () => {
        if (secondPart) {
          const processedAddresses = await Promise.all(secondPart.map(parseAddress));
          await addresses.addAddresses({
            customerVersion: data.version,
            addresses: processedAddresses as any,
          });
        }
        setFirstPartUploaded(false);
        setLoadingFiles(false);

        toast({
          title: 'Accepted file.',
          description: 'The CSV file was validated and accepted.',
          className: 'bg-green-200 border-green-700',
        });
      }, 3000);
    }
  }, [firstPartUploaded, previousValue, data?.version]);

  const genGenIDNumber = async (entity: string) => {
    try {
      const response = await generateUniqueIdNumber(entity);
      let customerNumber;
      if (!response.hasError) {
        const { nextId } = response ?? { nextId: undefined };
        customerNumber = nextId;
      } else {
        customerNumber = undefined;
      }
      return customerNumber;
    } catch (error) {
      toast({
        title: 'Unable to generate address number at this time, Please try again!',
        duration: 5000,
      });
      return undefined;
    }
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      setError('There is not file selected');
      return;
    }

    const file = acceptedFiles[0];
    try {
      setLoadingFiles(true);
      const csvData = await CSVFileValidator(file, {
        headers: [
          {
            name: 'First Name',
            inputName: 'firstName',
            required: true,

            validate: (value) => validateNoSpecialChars(value) && validateMaxChars(value, 30),
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: 'Last Name',
            inputName: 'lastName',
            required: true,
            validate: (value) => validateNoSpecialChars(value) && validateMaxChars(value, 30),
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: 'Company',
            inputName: 'company',
            optional: true,
            validate: (value) => validateNoSpecialChars(value) && validateMaxChars(value, 30),
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: 'Street Name',
            inputName: 'streetName',
            required: true,
          },
          {
            name: 'City',
            inputName: 'city',
            required: true,
            validate: (value) => validateMaxChars(value, 30),
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} must have a maximum of 30 characters in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: 'State',
            inputName: 'state',
            required: true,
            validate: (value) => validateState(value),
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} must have exactly two characters in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: 'Zip',
            inputName: 'zip',
            required: true,
            validate: (value) => isValidZipCode(value as string),
            validateError: function (headerName, rowNumber, columnNumber) {
              return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
        ],
      });

      if (csvData.inValidData.length > 0) {
        toast({
          title: 'Error uploading file.',
          description: 'Unable to import addresses. Please try again, or contact us at 1-800-458-2447 for help.',
          className: 'bg-[#FFF0F4] border-red-600',
        });
        setHasError(true);
      } else {
        if (data && data?.version) {
          const parts = chunkArray(csvData.data, 499);

          const processedAddresses = await Promise.all(parts[0].map(parseAddress));
          setLoadingFiles(true);

          await addresses.addAddresses({
            customerVersion: data.version,
            addresses: processedAddresses as any,
          });

          setFirstPartUploaded(true);
          setSecondPart(parts[1]);
        } else {
          toast({
            title: 'Error in your file.',
            description: 'The CSV file was validated but there was an error saving the addresses.',
            className: 'bg-[#FFF0F4] border-red-600',
          });
          setHasError(true);
        }
      }
    } catch (error) {
      toast({
        title: 'Error in your file.',
        description: 'The CSV file was validated but there was an error saving the addresses.',
        className: 'bg-[#FFF0F4] border-red-600',
      });
      setHasError(true);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    disabled: loadingFiles,
  });

  const dropzoneDisabledClassName = loadingFiles ? 'opacity-40' : 'opacity-100';

  return (
    <div>
      <div className=" mt-8 flex flex-col rounded-lg bg-[#F3EBE0] px-6 py-16 lg:flex-row">
        <div className="mr-2 flex flex-col space-y-6 lg:w-5/12">
          <div className="flex flex-col space-y-2">
            <div className="text-[20px] font-semibold">Bulk Import Addresses to My Address Book</div>
            <div className="text-[12px]">Upload a .csv file and save addresses to your Address Book. </div>
          </div>

          {!showDropzone && (
            <div
              {...getRootProps()}
              className={`dropzone ${
                isDragActive ? 'active' : ''
              } flex   justify-center  rounded-lg border-2 border-black-900 px-4 py-2 hover:bg-[#F3EBE0]`}
            >
              <input {...getInputProps()} />
              <div className="text-[12px] font-semibold">UPLOAD ADDRESSES (.CSV)</div>
              {error && <p>{error}</p>}
            </div>
          )}
        </div>

        <div className="ml-2 lg:w-7/12">
          <Typography variant="body" className="font-semibold text-[#000000]">
            Import instructions
          </Typography>

          <div className="text-[12px]">
            Download our .csv template or create a spreadsheet with the exact column names below. For single orders with
            more than 499 recipients, let us help! Please call us at 1-800-458-2447.
          </div>

          <a target="_blank" href="/assets/address.example.csv">
            <div className="mt-4 flex space-x-2">
              <Icon />
              <div className="font-semibold text-primary-800">Address_Template.csv</div>
            </div>
          </a>

          <div className="flex space-x-1 bg-white px-1 py-2 text-[10px] font-bold">
            <div className="border-r">First Name</div>
            <div className="border-r">Last Name</div>
            <div className="border-r">Company</div>
            <div className="border-r">Street Name</div>
            <div className="border-r">City</div>
            <div className="border-r">State</div>
            <div>Zip</div>
          </div>
        </div>
      </div>

      {showDropzone && (
        <div
          className={cn([
            'mx-8 mt-12 rounded-lg border border-dashed border-primary-800  lg:mx-0 lg:mt-6',
            dropzoneDisabledClassName,
          ])}
          {...getRootProps()}
        >
          <div className="flex flex-col items-center justify-center py-16">
            <CloudIcon />
            <input {...getInputProps()} />
            <div className="hidden space-x-2 lg:flex">
              <span className="text-[24px] font-bold text-primary-800">Upload{` `} </span>
              <span className="text-[24px]">or Drag File Here</span>
            </div>

            <div className="flex  space-x-2 lg:hidden">
              <span className="text-[24px] font-bold text-primary-800">Click here{` `} </span>
              <span className="text-[24px]">to upload a file</span>
            </div>
          </div>
        </div>
      )}
      {loadingFiles && !hasError ? (
        <div className="font-[Galano Classic] flex items-center gap-2 pl-1 pt-1 text-lg font-bold">
          <p className="pt-2">Importing addresses...</p>
          <CustomLoader />
        </div>
      ) : null}
    </div>
  );
};

const Icon = () => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 10L8 16M8 16L11 14M8 16L5 14M15.0002 7H12.2002C11.0801 7 10.5196 6.99997 10.0918 6.78198C9.71547 6.59024 9.40973 6.28429 9.21799 5.90797C9 5.48014 9 4.9201 9 3.8V1M15 15.8V7.3255C15 6.83632 14.9996 6.59169 14.9443 6.36151C14.8953 6.15744 14.8147 5.96238 14.705 5.78343C14.5814 5.5816 14.4089 5.40864 14.063 5.06274L10.9375 1.93726C10.5916 1.59135 10.4186 1.41842 10.2168 1.29474C10.0379 1.18508 9.84282 1.10425 9.63875 1.05526C9.40858 1 9.1639 1 8.67471 1H4.2002C3.08009 1 2.51962 1 2.0918 1.21799C1.71547 1.40973 1.40973 1.71572 1.21799 2.09204C1 2.51986 1 3.07991 1 4.20001V15.8C1 16.9201 1 17.4801 1.21799 17.908C1.40973 18.2843 1.71547 18.5902 2.0918 18.782C2.51962 19 3.08009 19 4.2002 19H11.8002C12.9203 19 13.48 19 13.9078 18.782C14.2841 18.5902 14.5905 18.2843 14.7822 17.908C15.0002 17.4801 15 16.9201 15 15.8Z"
        stroke="#3D4352"
        stroke-width="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CloudIcon = () => {
  return (
    <svg width="76" height="75" viewBox="0 0 76 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.0703 50L38.0703 31.25M38.0703 31.25L28.6953 37.5M38.0703 31.25L47.4453 37.5M72.4453 46.875C72.4453 39.9714 66.8489 34.375 59.9453 34.375C59.8714 34.375 59.7992 34.3757 59.7256 34.3769C58.2101 23.7751 49.0912 15.625 38.0703 15.625C29.3308 15.625 21.7897 20.7501 18.2858 28.1588C10.1393 28.692 3.69531 35.4686 3.69531 43.75C3.69531 52.3794 10.6909 59.375 19.3203 59.375H59.9453C66.8489 59.375 72.4453 53.7786 72.4453 46.875Z"
        stroke="#2B2A2D"
        stroke-width="2.32143"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
