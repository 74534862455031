// @ts-nocheck
import { contactInfoFormSchema } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/forms/schemas/contact-info.schema';
import * as Yup from 'yup';

export const addressFormSchema = Yup.object({
  firstName: Yup.string()
    .required('First name is a required field')
    .matches(/^[a-zA-Z\s\-]+$/, 'Only alphabets  are allowed for this field')
    .max(30, 'Max 30 characters'),
  lastName: Yup.string()
    .required('Last name is a required field')
    .matches(/^[a-zA-Z\s\-]+$/, 'Only alphabets are allowed for this field')
    .max(30, 'Max 30 characters'),
  company: Yup.string().optional().max(30, 'Max 30 characters'),
  streetAddress: Yup.string()
    .required('Street address is a required field')
    .max(60, 'Max 60 characters')
    .matches(
      /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i,
      'Invalid Address: FedEx Does Not Deliver to P.O. Boxes',
    ),
  apartment: Yup.string().optional().max(60, 'Max 60 characters'),
  city: Yup.string().required('City is a required field').max(30, 'Max 30 characters'),
  state: Yup.string()
    .required('State is a required field')
    .matches(/^[A-Z]{2}$/, 'Must have exactly two characters in this field')
    .max(60, 'Max 30 characters'),
  zipCode: Yup.string()
    .required('Zip Code is a required field')
    .matches(/^(?:\d{5}(?:[-\s]\d{4})?|\d{9})$/, 'Invalid zip code')
    .min(5, 'Invalid zip code')
    .max(10, 'Invalid zip code'),
});

export const shippingSchema = Yup.object().shape({
  contactInfo: contactInfoFormSchema,
  shipping: addressFormSchema,
  shipping: addressFormSchema.concat(
    Yup.object({
      addressKey: Yup.string(),
    }),
  ),
  giftMessages: Yup.object({
    giftMessage1: Yup.string(),
    giftMessage2: Yup.string(),
    giftMessage3: Yup.string(),
    giftMessage4: Yup.string(),
    giftMessage5: Yup.string(),
  }),
  giftMessageCheckbox: Yup.object({
    show: Yup.boolean(),
  }),
  shipSpeed: Yup.object({
    id: Yup.string(),
    price: Yup.number(),
    title: Yup.string(),
    turnaround: Yup.string(),
  }).required('Ship Speed is a required field'),
  shippingDate: Yup.object({
    startDate: Yup.string(),
  }).required('Shipping Date is a required field'),
  shippingDateSpeed: Yup.string(),
});

export type ShippingForm = Yup.InferType<typeof shippingSchema>;
