import { SimpleHeroWithMedia } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/marketing/hero-sections/simple-with-media';

const HeroWithTwoPanelsWithBackgroundMediaOnePanel = (props) => {
  const fontColor = props?.data?.darkMode ? 'text-white' : 'text-[#2B2A2D]';
  const ctaLabelWeight = props.data.labelBold ? 'font-bold' : 'font-normal';
  const ctaLabelItalic = props.data.labelItalic ? 'italic' : 'not-italic';
  const ctaLabelFontSize = props.data.labelFontSize ? `text-[${props.data.labelFontSize}px]` : 'text-xs';

  return (
    <div className="marketing-components-container">
      <SimpleHeroWithMedia
        {...props}
        extendedClasses={{
          title: `font-bold my-4 text-center md:text-left md:text-[52px] leading-[52px] ${fontColor}`,
          description: `text-center md:text-left mb-8 font-normal leading-[25.6px] ${fontColor}`,
          eyebrow: `mt-4 text-center md:text-left md:text-[40px] lg:text-[40px] leading-[52px] ${fontColor}`,
          cta: 'text-[12px] xs:text-center md:text-left lg:text-left',
          mediaHeight: 'h-[450px] md:px[600px]',
          contentBox: 'px-4 py-4 md:px-16',
          ctaLabelStyle: `${ctaLabelWeight} ${ctaLabelItalic} ${ctaLabelFontSize}`,
        }}
      />
    </div>
  );
};

export { HeroWithTwoPanelsWithBackgroundMediaOnePanel as SimpleHeroWithMedia };
