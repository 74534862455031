// Doc Examples
import { AccountVerifiedTastic } from './account/account-verified/account-verified';
import { ChangePassword } from './account/change-password';
import AccountCorporatePlpTastic from './account/corporate-plp';
import { CreateAccount } from './account/create';
import AccountDetails from './account/details';
import AddressesTastic from './account/details/addresses';
import { AddressUploadCSV } from './account/details/addresses/upload-csv';
import CatalogsTastic from './account/details/catalogs';
import CustomerSupportTastic from './account/details/customer-support';
import AccountOrdersTastic from './account/details/orders';
import PaymentMethodsTastic from './account/details/payment-methods';
import EditEmailSubscriptionTastic from './account/edit-email-subscription';
import EditParsonalInfoTastic from './account/edit-personal-info';
import ForgotPasswordTastic from './account/forgot-password';
import AccountLogin from './account/login';
import AccountOrdersHistory from './account/orders';
import AccountRegister from './account/register';
import ResetPassword from './account/reset-password';
import { SuccessVerifyEmailPendingTastic } from './account/success/verify-email-pending';
import AccountTest from './account/test';
import Cart from './cart';
import { EmptyCartBannerTastic } from './cart/empty-cart-banner';
import CartOrderSummaryTastic from './cart/order-summary';
import { ProductTilesTastic } from './cart/product-tiles';
import { ViewCartGtmHelperTastic } from './cart/view-cart-gtm-helper';
import { CategoryLandingPageBanner } from './category-landing/category-landing-banner';
import Checkout from './checkout';
import { CheckOutMultiSteps } from './checkout/components/multi-steps';
import { ThankYou } from './checkout/components/order-confirmation';
import { BlankPanel } from './content/blank-panel';
import Blog from './content/blog';
import CategoryTeasers from './content/category-teasers';
import Markdown from './content/markdown';
import Spacer from './content/spacer';
import Tile from './content/tile';
import { DatepickerTastic } from './datepicker';
import HelloWorld from './doc-examples/hello-world';
import SimpleButton from './doc-examples/simple-button';
import StarWarsCharacterFilter from './doc-examples/star-wars/character-filter';
import StarWarsCharacterSearch from './doc-examples/star-wars/character-search';
import StarWarsOpeningCrawl from './doc-examples/star-wars/movies';
import ASIOCartDemoTastic from './examples/cartdemo';
//import ContentfulBlog from './doc-examples/contentful/blog';
import { Footer } from './footer';
import { HeaderMegaMenuProductImagesAndFilter } from './header';
import { HeaderCustomTin } from './header/custom-tin';
import { GlobalMessagingBanner } from './header/header/global-messaging-banner';
import Hero from './hero';
import { HeroDoubleWithMedia } from './hero/double-with-media';
import { CarouselWithProductRecommendations } from './hero/with-product-recommendations';
import { HeroWithSinglePanel } from './hero/with-single-panel';
import { CarouselWithSocialMedia } from './hero/with-social-media';
import { WithThreePanels7525 } from './hero/with-three-panel-7525';
import { SimpleHeroWithMedia } from './hero/with-two-panels-with-background-media-one-panel';
import { HeroWithVideoBackground } from './hero/with-video-background';
import ImageContainer from './image-container/image-container';
import { IncentiveTitleWithPanels } from './incentives';
import { IncentiveWith5050Split } from './incentives/with-5050-split';
import { MarkDownTastic } from './markdown';
import NewsCardTastic from './news-card';
import Newsletter from './newsletter';
import NewsLetterSubscriptionTastic from './newsletter-subscription';
import NotFound from './not-found';
import WriteReviewTastic from './power-review/write-a-review';
import CategoryCarouselTastic from './products/categories-carousel/categories-carousel';
import { CustomTin } from './products/custom-tin';
import ProductDetails from './products/details';
import ProductCategories from './products/product-categories';
import ProductList from './products/product-list';
import { RecommendedProducts } from './products/recommeded-products';
import SimilarProducts from './products/similar-products';
import ProductSlider from './products/slider';
import Showcase from './showcase';
import { Accordion } from './static-pages/accordion/Accordion';
import { ContactUSFormTastics } from './static-pages/contact-form/ContactForm';
import ContentCardTastic from './static-pages/content-card/cantent-card';
import { CorporateFormTastic } from './static-pages/corporate-form/CorporateFormTastic';
import DsarFormTastic from './static-pages/dsar-form/dsar-form';
import FlippedHeroWithMediaTastic from './static-pages/flipped-hero-with-media/flipped-hero-with-media';
import HeadlineBlockTastic from './static-pages/headline-block/headline-block';
import Wishlist from './wishlist';
import { CategoryLandingPageBannerDynamic } from './category-landing/category-landing-banner-dynamic';
import { CategoryLandingPageBannerText } from './category-landing/category-landing-banner-text';
// Import tastic components from aries

export const tastics = {
  // Doc Examples
  'example/simple-button': SimpleButton,
  'example/hello-world': HelloWorld,
  'example/star-wars/movie': StarWarsOpeningCrawl,
  'example/star-wars/character-search': StarWarsCharacterSearch,
  'example/star-wars/character-filter': StarWarsCharacterFilter,
  'example/cartdemo': ASIOCartDemoTastic,
  //'contentful/blog': ContentfulBlog,

  // Aries components
  'aries/cc-components/marketing/hero/with-three-panels-7525': WithThreePanels7525,
  'aries/marketing/hero/with-two-panels-with-background-media-one-panel': SimpleHeroWithMedia,
  'asio/marketing/hero/with-video-background': HeroWithVideoBackground,
  'asio/martketing/header/mega-menu-product-images-and-filter': HeaderMegaMenuProductImagesAndFilter,
  'asio/marketing/header/global-messaging-banner': GlobalMessagingBanner,
  'asio/marketing/content/blank-panel': BlankPanel,
  'asio/custom-components/custom-tin': CustomTin,
  'asio/marketing/hero/double-with-media': HeroDoubleWithMedia,
  'asio/marketing/footer': Footer,
  'asio/ecommerce/products/recommended-products': RecommendedProducts,
  'asio/marketing/hero/product-recommendations': CarouselWithProductRecommendations,
  'aries/cc-components/marketing/category/landing-banner': CategoryLandingPageBanner,
  'aries/cc-components/marketing/category/landing-banner-dynamic': CategoryLandingPageBannerDynamic,
  'aries/cc-components/marketing/category/landing-banner-text': CategoryLandingPageBannerText,
  'asio/marketing/hero/with-social-media': CarouselWithSocialMedia,
  'asio/marketing/hero/with-single-panel': HeroWithSinglePanel,
  'asio/ecommerce/pdp': ProductDetails,
  'asio/ecommerce/product-list': ProductList,
  'asio/ecommerce/products-by-category': ProductCategories,
  'asio/ecommerce/category-carousel': CategoryCarouselTastic,
  // static pages
  'asio/ecommerce/static-pages/headline-block': HeadlineBlockTastic,
  'asio/ecommerce/static-pages/accordion': Accordion,
  'asio/ecommerce/static-pages/flipped-hero-with-media': FlippedHeroWithMediaTastic,
  'asio/ecommerce/static-pages/dsar-form': DsarFormTastic,
  'asio/ecommerce/static-pages/content-card': ContentCardTastic,
  'asio/marketing/incentives/title-with-panels': IncentiveTitleWithPanels,
  'asio/marketing/incentives/with-5050-split': IncentiveWith5050Split,
  'asio/ecommerce/date-picker': DatepickerTastic,
  'asio/ecommerce/checkout/multi-steps': CheckOutMultiSteps,
  'asio/marketing/newsletter-subscription': NewsLetterSubscriptionTastic,
  'asio/ecommerce/static-pages/contact-form': ContactUSFormTastics,
  'asio/ecommerce/static-pages/corporate-form': CorporateFormTastic,
  'commercetools/ui/checkout': Checkout,
  'asio/ecommerce/checkout/order-confirmation': ThankYou,
  'asio/ecommerce/cart/product-tiles': ProductTilesTastic,
  'asio/ecommerce/cart/order-summary': CartOrderSummaryTastic,
  'asio/ecommerce/cart/empty-cart': EmptyCartBannerTastic,
  'asio/ecommerce/cart/view-cart-gtm-helper': ViewCartGtmHelperTastic,
  'asio/marketing/image-container': ImageContainer,
  'asio/ecommerce/power-review/write-a-review': WriteReviewTastic,
  'asio/ecommerce/account/addresses': AddressesTastic,
  'asio/ecommerce/account/orders': AccountOrdersTastic,
  'asio/ecommerce/account/corporate-plp': AccountCorporatePlpTastic,
  'asio/ecommerce/account/catalogs': CatalogsTastic,
  'asio/ecommerce/account/payment-methods': PaymentMethodsTastic,
  'asio/ecommerce/account/customer-support': CustomerSupportTastic,
  'asio/ecommerce/account/edit-personal-info': EditParsonalInfoTastic,
  'asio/ecommerce/account/create': CreateAccount,
  'asio/ecommmerce/account/success-email-verification-pending': SuccessVerifyEmailPendingTastic,
  'asio/ecommerce/account/account-verified': AccountVerifiedTastic,
  'asio/ecommerce/account/edit-email-subscription': EditEmailSubscriptionTastic,
  'asio/ecommerce/account/forgot-password': ForgotPasswordTastic,
  'asio/ecommerce/account/addresses/upload-csv': AddressUploadCSV,
  'asio/primitives/markdown': MarkDownTastic,
  'commercetools/ui/cart': Cart,
  'commercetools/ui/footer': Footer,
  // 'commercetools/ui/header': Header,
  'commercetools/ui/content/blog': Blog,
  'commercetools/ui/content/tile': Tile,
  'commercetools/ui/content/spacer': Spacer,
  'commercetools/ui/content/showcase': Showcase,
  'commercetools/ui/content/markdown': Markdown,
  'commercetools/ui/content/newsletter': Newsletter,
  'commercetools/ui/products/details': ProductDetails,
  // 'commercetools/ui/products/product-list': ProductList,
  'commercetools/ui/products/slider': ProductSlider,
  'commercetools/ui/products/similar-products': SimilarProducts,
  'commercetools/ui/wishlist': Wishlist,
  'commercetools/ui/account/test': AccountTest,
  'commercetools/ui/account/details': AccountDetails,
  'commercetools/ui/account/login': AccountLogin,
  'commercetools/ui/account/register': AccountRegister,
  'commercetools/ui/account/orders': AccountOrdersHistory,
  'commercetools/ui/account/reset-password': ResetPassword,
  'commercetools/ui/account/change-password': ChangePassword,
  'commercetools/ui/content/category-teasers': CategoryTeasers,
  'commercetools/ui/content/news-card': NewsCardTastic,
  'commercetools/ui/hero': Hero,

  default: NotFound,
};
