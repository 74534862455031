import { Address } from '@ariessolutionsio/providers-richproducts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateAddress } from '@/frontastic/actions/account';

export function useUpdateAddress() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['update:address'],
    mutationFn: (payload: Address) => updateAddress(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get:account'],
      });
    },
  });
}
