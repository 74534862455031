/* eslint-disable tailwindcss/no-custom-classname */
import { FC, useEffect, useState } from 'react';
import { useIncrementDecrement } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/IncrementDecrementSelector';
import { DisplayPrice } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/templates/DisplayPrice';
import { LineItem } from '@ariessolutionsio/react-ecomm-ui/dist/types/cart/LineItem';
import { UseCart } from '@ariessolutionsio/react-ecomm-ui/dist/types/cart/UseCart';
import { trashCanIcon } from './trash-icon';

export interface ProductTilesProps {
  items: LineItem[];
  cart: UseCart;
}

export const ProductTiles: FC<ProductTilesProps> = (props) => {
  const { items, cart } = props;

  const ProductTiles = ({ item }: any) => {
    const { IncrementDecrement, selectedQty } = useIncrementDecrement({
      defaultValue: item.count,
    });
    const [, setCurrentValue] = useState(selectedQty);
    useEffect(() => {
      setCurrentValue((prevState) => {
        if (prevState !== selectedQty) {
          if (selectedQty === 0) {
            cart.updateItem(item.lineItemId, 1);
          } else {
            cart.updateItem(item.lineItemId, selectedQty);
          }
        }
        return selectedQty;
      });
    }, [selectedQty]);

    return (
      <>
        <div className="mb-3.5 flex justify-between space-x-3">
          {item.variant?.images && (
            <div className="h-[50px] w-[50px] flex-none">
              <img
                src={item.variant?.images[0]}
                alt={item.variant?.imagesAltText?.[0] || item.name}
                className="h-full w-full object-cover"
              />
            </div>
          )}
          <div className="flex-auto space-y-1">
            <p className="text-lg">{item.name}</p>
            {item.variant?.attributes?.cookie_flavor?.label && (
              <p className="text-sm">
                <span>Assortment: </span>
                {item.variant?.attributes.cookie_flavor.label}
              </p>
            )}
            {item.variant?.attributes?.tin_color?.label && (
              <p className="text-sm">
                <span>Tin Color: </span>
                {item.variant?.attributes.tin_color.label}
              </p>
            )}
          </div>
          <a href="#" className="remove-item-button" onClick={() => cart.removeItem(item.lineItemId as string)}>
            <div>{trashCanIcon}</div>
          </a>
        </div>
        <div className="mb-5 flex items-center justify-between">
          <div>
            <IncrementDecrement />
            <DisplayPrice
              price={item.price}
              component={({ displayPrice }) => (
                <div className="mt-1 text-center xs:hidden lg:mt-2 lg:block lg:text-sm">
                  {displayPrice} <span className="text-sm"> / each</span>
                </div>
              )}
            />
          </div>
          <DisplayPrice
            price={item.totalPrice}
            component={(displayer) => (
              <div className="space-x-3 text-xl">
                <span>Total</span>
                <span>{displayer.displayPrice}</span>
              </div>
            )}
          ></DisplayPrice>
        </div>
      </>
    );
  };

  return (
    <div className="py-4">
      {items && (
        <>
          {items.map((item, index) => (
            <ProductTiles key={`${index}`} item={item as LineItem} />
          ))}
        </>
      )}
    </div>
  );
};
