import { Button, Form, Markdown, Select, Textarea } from '@ariessolutionsio/primitives-richproducts';
import { Input } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Panel } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Panel';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { EMAIL_REGX } from '@ariessolutionsio/react-ecomm-ui/dist/utils/maps';
import { Checkbox } from '@ariessolutionsio/ui';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAzure, useKlaviyo } from 'frontastic/provider/frontastic';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

const contactFormSchema = Yup.object().shape({
  username: Yup.string().required('Name is required Field'),
  email: Yup.string()
    .required('Email Address is a required field')
    .matches(EMAIL_REGX, 'You must enter a valid email')
    .max(60, 'Email must be less than 60 characters'),
  note: Yup.string(),
});

const corporateFormSchema = Yup.object().shape({
  username: Yup.string().required('Name is required Field'),
  email: Yup.string()
    .required('Email Address is a required field')
    .matches(EMAIL_REGX, 'You must enter a valid email')
    .max(60, 'Email must be less than 60 characters'),
  phoneNumber: Yup.string()
    .required('Phone Number is a required field')
    .matches(/^\d{10}$/, 'You must enter a valid 10-digit Phone Number'),
  state: Yup.string()
    .required('State is a required field')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
    .max(60, 'Max 30 characters'),
  note: Yup.string(),
  subscribeToMarketingEmails: Yup.boolean(),
});

export type SignInDialogProps = {
  title: string;
};
export const ContactUsForm = ({ data, isCorporateForm = false }: any) => {
  const provincesList = isCorporateForm ? data?.provinces?.dataSource?.value : {};
  const states: any = Object.entries(provincesList ?? []).map(([key, value]) => ({ key, value }));
  const { sendCorpGiftSubmissionEmail, sendContactUsEmail } = useAzure();
  const { subscribeToNewsletter } = useKlaviyo();

  const corporateForm = useForm({
    resolver: yupResolver(corporateFormSchema),
    defaultValues: {
      username: '',
      email: '',
      phoneNumber: '',
      state: '',
      note: '',
      subscribeToMarketingEmails: true,
    },
  });
  const contactForm = useForm({
    resolver: yupResolver(contactFormSchema),
    defaultValues: {
      username: '',
      email: '',
      note: '',
    },
  });
  const [form, setForm] = useState<any>(contactForm);
  const [message, setMessage] = useState('');
  useEffect(() => {
    const currentForm = isCorporateForm ? corporateForm : contactForm;
    setForm(currentForm);
  }, [contactForm, corporateForm, isCorporateForm]);

  const onSubmit = useCallback(
    async (values) => {
      let response;
      if (isCorporateForm) {
        response = await sendCorpGiftSubmissionEmail({
          email: values?.email,
          name: values?.username,
          emailSubject: 'Corporate Gifting Submission',
          phoneNumber: values?.phoneNumber,
          note: values?.note,
          state: values?.state,
        });
        if (values?.subscribeToMarketingEmails) {
          const COUNTRY_CODE = '+1';
          await subscribeToNewsletter({
            email: values?.email,
            phoneNumber: `${COUNTRY_CODE}${values?.phoneNumber}`,
            isCorporateForm: true,
          });
        }
      } else {
        response = await sendContactUsEmail({
          email: values?.email,
          name: values?.username,
          emailSubject: 'Contact Us Form',
          note: values?.note,
        });
      }
      if (response?.success) {
        setMessage("Thanks for contacting us with your comments and questions. We'll respond to you very soon.");
        toast.success('Thanks for contacting us. We will respond to you very soon.');
        form.reset();
      } else {
        toast.error('Unable to submit contact form. Please try again later.');
      }
    },
    [form, isCorporateForm],
  );

  const panelProps = { background: data?.media?.media };
  return (
    <div className={`mx-[auto] mt-8 w-[100%] px-5 ${isCorporateForm ? 'lg:w-[100%]' : 'lg:w-[50%]'}`}>
      <Panel {...panelProps}>
        <div className="relative py-8">
          {' '}
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)} className="space-y-7">
              <div className="py-0 text-center">
                {data?.title && (
                  <Typography tagName="h2" variant="h2" className="text-black">
                    {data?.title}
                  </Typography>
                )}
                {data?.description && (
                  <Markdown className="mx-10  mt-4 text-center font-normal text-[#2B2A2D]">
                    {data?.description}
                  </Markdown>
                )}
              </div>
              <Form.Section>
                <div className="mx-[auto] w-[100%] max-w-[492px] space-y-5">
                  <Form.Control name="username" label="Name" error={form?.formState?.errors?.username?.message}>
                    <Input className="min-h-[45px]" {...form.register('username')} />
                  </Form.Control>
                  <Form.Control name="email" label="Email" error={form?.formState?.errors?.email?.message}>
                    <Input className="min-h-[45px]" {...form.register('email')} />
                  </Form.Control>
                  {isCorporateForm && (
                    <Form.Control
                      name="phoneNumber"
                      label="Phone Number"
                      error={form?.formState?.errors?.phoneNumber?.message}
                    >
                      <Input className="min-h-[45px]" {...form.register('phoneNumber')} />
                    </Form.Control>
                  )}
                  {isCorporateForm ? (
                    <Controller
                      control={corporateForm.control}
                      name="state"
                      {...corporateForm.register('state')}
                      render={({ field: { onChange, value }, formState: { errors } }) => {
                        return (
                          <Form.Control label="State" name="state" error={errors ? errors?.state?.message : ''}>
                            <Select value={value} onValueChange={onChange}>
                              <Select.Trigger
                                className="min-h-[45px] bg-white"
                                aria-label="state"
                                invalid={errors?.state?.message ? true : false}
                              >
                                <Select.Value placeholder="Select state" />
                              </Select.Trigger>
                              <Select.Content className="max-h-[250px] overflow-auto">
                                <Select.Group>
                                  {states.map((state: any, index: number) => (
                                    <Select.Item key={`${state?.key}-${index}`} value={state?.value}>
                                      <span>{state?.value}</span>
                                    </Select.Item>
                                  ))}
                                </Select.Group>
                              </Select.Content>
                            </Select>
                          </Form.Control>
                        );
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <Form.Control name="Note" label="Note (optional)">
                    <Textarea className="bg-white" {...form.register('note')} cols={60} rows={5} />
                  </Form.Control>
                  <Form.Control name="Subscribe">
                    <div className="mr-5 flex items-center space-x-2">
                      <Checkbox
                        aria-describedby="Subscribe to our newsletter"
                        {...form.register('subscribeToMarketingEmails')}
                        name="subscribeToMarketingEmails"
                        defaultChecked={corporateForm.getValues('subscribeToMarketingEmails')}
                        onClick={() =>
                          form.setValue('subscribeToMarketingEmails', !form.getValues('subscribeToMarketingEmails'))
                        }
                      />
                      <label className="text-black">Subscribe to our newsletter</label>
                    </div>
                  </Form.Control>
                  <Form.Control name="actions">
                    <div className="item-center mt-6 flex justify-center space-x-5">
                      <Button type="submit" className="w-[100%] rounded-lg bg-primary-800 text-white lg:w-[114px]">
                        Submit
                      </Button>
                    </div>
                  </Form.Control>
                  {message && <span>{message}</span>}
                </div>
              </Form.Section>
            </Form>
          </FormProvider>
        </div>
      </Panel>
    </div>
  );
};
