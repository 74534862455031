import { FC, useState } from 'react';
import { Form, Select } from '@ariessolutionsio/primitives-richproducts';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import {
  OrderDeliveryItem,
  OrderShippingPlanning,
  useCheckoutContext,
} from 'frontastic/tastics/checkout/contexts/checkout.context';

interface SpeedByProductProps {
  orderDeliveryItem: OrderDeliveryItem;
  onChange?: () => void;
}

interface AddressItemProps {
  shippingPlanning: OrderShippingPlanning;
  index: number;
}
export const SpeedByProduct: FC<SpeedByProductProps> = ({ orderDeliveryItem, onChange }) => {
  const [{ deliveryMethods, orderDelivery }, { setOrderDelivery }] = useCheckoutContext();

  const AddressItem: FC<AddressItemProps> = ({ shippingPlanning, index }) => {
    const [value, setValue] = useState(shippingPlanning.shippingMethod || deliveryMethods[0].id);

    const updateShippingMethod = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setValue(e.target.value);
      shippingPlanning.shippingMethod = e.target.value;
      orderDeliveryItem.shippingPlanning[index] = { ...shippingPlanning };
      const idx = orderDelivery.findIndex((item) => item.lineItem.lineItemId === orderDeliveryItem.lineItem.lineItemId);
      orderDelivery[idx] = { ...orderDeliveryItem };
      setOrderDelivery([...orderDelivery]);
      if (onChange) onChange();
    };

    return (
      <div className="mb-8 flex w-full justify-between space-x-6" key={`SS-${shippingPlanning.address.id}`}>
        <div className="flex w-1/2 justify-between">
          <div>
            <Typography
              variant="body"
              className="font-bold"
              tagName="div"
            >{`${shippingPlanning.address.firstName} ${shippingPlanning.address.lastName}`}</Typography>
            <Typography
              variant="body"
              tagName="div"
            >{`${shippingPlanning.address.streetName} ${shippingPlanning.address.state} ${shippingPlanning.address.postalCode}`}</Typography>
          </div>
          <Typography variant="body" tagName="div" className="font-bold">
            {shippingPlanning.quantity}
          </Typography>
        </div>
        <div className="flex w-1/3 justify-end ">
          <Form.Control name="shipSpeed" label="Ship Speed">
            <Select autoComplete="on" value={value} onChange={updateShippingMethod}>
              <Select.Trigger
                aria-label="shipSpeed"
                className="w-full appearance-none rounded border border-gray-500 px-3 py-2 leading-tight text-gray-700 focus:outline-none"
              >
                <Select.Value />
              </Select.Trigger>
              <Select.Content className="z-50 max-h-64 overflow-y-auto ">
                <Select.Group>
                  {deliveryMethods.map((ss, index) => (
                    <Select.Item key={`${ss.id}-${index}`} value={ss.id}>
                      <div>{ss.title}</div>
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select>
          </Form.Control>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className=" mb-4 rounded-lg border  ">
        <div className="flex space-x-3 px-6 py-8">
          {orderDeliveryItem.lineItem.variant?.images && (
            <div className="h-[80px] w-[80px] flex-none">
              <img
                src={orderDeliveryItem.lineItem.variant?.images[0]}
                alt={orderDeliveryItem.lineItem.name}
                className="h-full w-full object-cover"
              />
            </div>
          )}
          <div className="flex w-full flex-col space-y-1 lg:flex-row lg:justify-between">
            <div>
              <p className="text-lg">{orderDeliveryItem.lineItem.name}</p>
              {orderDeliveryItem.lineItem.variant?.attributes?.cookie_flavor?.label && (
                <p className="text-sm">
                  <span>Assortment: </span>
                  <span className="font-semibold">
                    {orderDeliveryItem.lineItem.variant?.attributes.cookie_flavor.label}
                  </span>
                </p>
              )}
              {orderDeliveryItem.lineItem.variant?.attributes?.tin_color?.label && (
                <p className="text-sm">
                  <span>Tin Color: </span>
                  <span className="font-semibold">
                    {orderDeliveryItem.lineItem.variant?.attributes.tin_color.label}
                  </span>
                </p>
              )}
            </div>
            <div>
              <Typography variant="body" className="font-bold">
                {`QTY: ${orderDeliveryItem.quantity}`}
              </Typography>
            </div>
          </div>
        </div>
        <div className="border-t"></div>
        <div className="px-6 py-8">
          {orderDeliveryItem.shippingPlanning.map((shippingPlanning, index) => (
            <AddressItem
              shippingPlanning={shippingPlanning}
              index={index}
              key={`SS-${shippingPlanning.address.id}`}
            ></AddressItem>
          ))}
        </div>
      </div>
    </>
  );
};
