import { FC } from 'react';
import { EditIcon } from '@ariessolutionsio/cc-custom-components/dist/utils/icons';
import { LinkElement } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Link';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import moment from 'moment';
import { useCart } from 'frontastic/provider';
import { OrderDeliveryItem, useCheckoutContext } from '../../contexts/checkout.context';
interface ProductTileProps {
  orderDeliveryItem: OrderDeliveryItem;
}

interface HeaderSectionProps {
  title: string;
  onClick: () => void;
}

interface EditComponentProps {
  onClick: () => void;
}
const CLASS_HEADER = 'mb-4 font-["Akshar"] text-xl font-bold';
export const ReviewAndSummaryMultiShipping: FC = () => {
  const [{ orderDelivery, checkoutData, shipSpeedData }, { setCurrentStep }] = useCheckoutContext();
  const cart = useCart();

  const Divider: FC = () => {
    return <div className="w-full border-b border-gray-300 py-6" />;
  };
  const HeaderSection: FC<HeaderSectionProps> = ({ title, onClick }) => {
    return (
      <div className="flex items-center justify-between">
        <div className={CLASS_HEADER}>{title}</div>
        <LinkElement palette="none" className="flex items-center justify-center">
          <div onClick={onClick} className="flex items-center">
            <p className="relative -top-0.5 mr-2">{EditIcon}</p>
            <p className="text-primary-800">Edit</p>
          </div>
        </LinkElement>
      </div>
    );
  };

  const EditComponent: FC<EditComponentProps> = ({ onClick }) => {
    return (
      <LinkElement palette="none" className="flex justify-center">
        <div onClick={onClick} className="flex ">
          <p className="relative -top-0.5 mr-2">{EditIcon}</p>
          <p className="text-primary-800">Edit</p>
        </div>
      </LinkElement>
    );
  };
  const ProductTile: FC<ProductTileProps> = ({ orderDeliveryItem }) => {
    return (
      <>
        <div className=" mb-4 rounded-lg border  ">
          <div className="flex space-x-3 px-6 py-8">
            {orderDeliveryItem.lineItem.variant?.images && (
              <div className="h-[80px] w-[80px] flex-none">
                <img
                  src={orderDeliveryItem.lineItem.variant?.images[0]}
                  alt={orderDeliveryItem.lineItem?.name ?? ''}
                  className="h-full w-full object-cover"
                />
              </div>
            )}
            <div className="flex w-full flex-col space-y-1 lg:flex-row lg:justify-between">
              <div>
                <p className="text-lg">{orderDeliveryItem.lineItem?.name ?? ''}</p>
                {orderDeliveryItem.lineItem.variant?.attributes?.cookie_flavor?.label && (
                  <p className="text-sm">
                    <span>Assortment: </span>
                    <span className="font-semibold">
                      {orderDeliveryItem.lineItem.variant?.attributes.cookie_flavor.label}
                    </span>
                  </p>
                )}
                {orderDeliveryItem.lineItem.variant?.attributes?.tin_color?.label && (
                  <p className="text-sm">
                    <span>Tin Color: </span>
                    <span className="font-semibold">
                      {orderDeliveryItem.lineItem.variant?.attributes.tin_color.label}
                    </span>
                  </p>
                )}
              </div>
              <div>
                <Typography variant="body" className="font-bold">
                  {`QTY: ${orderDeliveryItem.quantity}`}
                </Typography>
              </div>
            </div>
          </div>
          <div className="border-t"></div>
          <div className="px-6 py-8">
            <div className="flex space-x-8">
              <div className="w-full">
                {orderDeliveryItem?.shippingPlanning?.map((shippingPlanning, index) => (
                  <div key={shippingPlanning.address.id} className="mb-8 flex flex-col lg:flex-row lg:space-x-8 ">
                    <Typography variant="body" tagName="div" className="w-32 pb-2 lg:pb-0">
                      Shipping To
                    </Typography>
                    <div className=" flex w-full justify-between ">
                      <div className="flex flex-col space-y-3">
                        <div>
                          <p className="font-bold">{`${shippingPlanning.address?.firstName} ${
                            shippingPlanning.address?.lastName ?? ''
                          }`}</p>
                          <p>{`${shippingPlanning.address?.company ?? ''} `}</p>
                          <p>{`${shippingPlanning.address?.streetNumber ?? ''} ${
                            shippingPlanning.address?.streetName
                          }`}</p>
                          <p>{`${shippingPlanning.address?.additionalStreetInfo ?? ''} `}</p>
                          <p>{`${shippingPlanning.address?.city}, ${shippingPlanning.address?.state} ${
                            shippingPlanning.address?.postalCode ?? shippingPlanning.address?.postalCode
                          }`}</p>
                        </div>
                        <div>
                          <Typography variant="body" tagName="div" className="font-bold">
                            Ship Method
                          </Typography>
                          <Typography variant="body" tagName="div">
                            <p>
                              {shipSpeedData.find((sd) => sd.methodName === shippingPlanning?.shippingMethod)?.title}
                            </p>
                            <p>
                              {
                                shipSpeedData.find((sd) => sd.methodName === shippingPlanning?.shippingMethod)
                                  ?.description
                              }
                            </p>
                          </Typography>
                        </div>

                        <div>
                          <Typography variant="body" tagName="div" className="font-bold">
                            Shipping Date
                          </Typography>
                          <Typography variant="body" tagName="div">
                            {moment(shippingPlanning.shippingDate, 'YYYY-MM-DD').format('MMM Do, YYYY')}
                          </Typography>
                        </div>
                      </div>
                      <div className="flex space-x-6">
                        <EditComponent onClick={() => setCurrentStep(0)} />
                        <Typography variant="body" tagName="div">
                          {shippingPlanning.quantity}
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const ContactInfo: FC = () => {
    return (
      <div className="mt-8">
        <HeaderSection title="Contact Information" onClick={() => setCurrentStep(0)}></HeaderSection>
        <Typography variant="body" tagName="div">
          {(checkoutData as any)?.shipping?.contactInfo?.email}
        </Typography>
        <Typography variant="body" tagName="div">
          {(checkoutData as any)?.shipping?.contactInfo?.phoneNumber}
        </Typography>
      </div>
    );
  };

  const PaymentDetails: FC = () => {
    return (
      <div className="mb-16 mt-8">
        <HeaderSection title="Payment Details" onClick={() => setCurrentStep(2)}></HeaderSection>
        <div className="flex space-x-6">
          <div>
            <Typography variant="body" tagName="div" className="font-bold">
              Payment Method
            </Typography>
            <Typography variant="body" tagName="div">
              {`**** **** **** ${(checkoutData as any)?.payment?.paymentDetails?.cardNumberValue}`}
            </Typography>
          </div>
          <div>
            <Typography variant="body" tagName="div" className="font-bold">
              Billing Address
            </Typography>
            <Typography variant="body" tagName="div">
              {(checkoutData as any)?.payment?.billing?.firstName} {(checkoutData as any)?.payment?.billing?.lastName}
            </Typography>
            <Typography variant="body" tagName="div">
              {(checkoutData as any)?.payment?.billing?.company}
            </Typography>
            <Typography variant="body" tagName="div">
              {(checkoutData as any)?.payment?.billing?.streetAddress}
            </Typography>
            <Typography variant="body" tagName="div">
              {(checkoutData as any)?.payment?.billing?.apartment}
            </Typography>
            <Typography variant="body" tagName="div">
              {(checkoutData as any)?.payment?.billing?.city}, {(checkoutData as any)?.payment?.billing?.state}{' '}
              {(checkoutData as any)?.payment?.billing?.zipCode}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const GiftMessages: FC = () => {
    return (
      <div className="mt-8">
        <HeaderSection title={`Gift Message`} onClick={() => setCurrentStep(1)}></HeaderSection>
        {orderDelivery.map((odItem, index) => (
          <>
            <p className='font-["Akshar"] text-xl font-bold'>Gift Message #{index + 1}</p>

            {odItem.shippingPlanning.map((sp) => (
              <div className="flex flex-col lg:flex-row lg:justify-between" key={`SP${Math.random()}`}>
                <Typography variant="body">{`${sp.address.firstName} ${sp.address.lastName}`}</Typography>
                <div className="my-4 text-[#374151] lg:mt-0">
                  <p className="break-all">{sp.giftMessageLine1}</p>
                  <p className="break-all">{sp.giftMessageLine2}</p>
                  <p className="break-all">{sp.giftMessageLine3}</p>
                  <p className="break-all">{sp.giftMessageLine4}</p>
                  <p className="break-all">{sp.giftMessageLine5}</p>
                </div>
              </div>
            ))}
          </>
        ))}
      </div>
    );
  };

  if (!cart?.data || !orderDelivery) {
    return <></>;
  }

  return (
    <div>
      <HeaderSection title="Shipping Details" onClick={() => setCurrentStep(0)}></HeaderSection>
      {orderDelivery.map((od) => (
        <ProductTile orderDeliveryItem={od} key={`${od.lineItem.lineItemId}-od`} />
      ))}
      <Divider />
      <GiftMessages />
      <Divider />
      <ContactInfo />
      <Divider />
      <PaymentDetails />
    </div>
  );
};
