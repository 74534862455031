/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { Dialog, Separator, Text, useDialogContext } from '@ariessolutionsio/primitives-richproducts';
import { Address } from '@ariessolutionsio/providers-richproducts';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { cn } from '@ariessolutionsio/ui';
import { PlusIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useGetAccount } from '@/hooks/my-account/use-get-account';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import useScreenOrientation from '@/hooks/useOrientation';
import { RecipientTableMobile } from 'components/common/tables/recipients-book/table-mobile';
import { OrderDeliveryItem } from 'frontastic/tastics/checkout/contexts/checkout.context';
import { useCreateAddressDialog, useUpdateAddressDialog } from '../../../../hooks/dialogs/customer/customer';
import { getColumns } from '../../tables/recipients-book/columns';
import { RecipientsBookTable } from '../../tables/recipients-book/recipients-book.table';

export type RecipientsBookDialogProps = {
  title: string;
  states: any[];
  orderDeliveryItem?: OrderDeliveryItem;
  onSingleSelect: (address: any) => void;
  onSelectedItemInMultiSelect?: (address: any, quantity: number) => void;
  onChangeSelectable?: (address: any, checked: boolean) => void;
  orderDelivery?: OrderDeliveryItem[];
  setOrderDelivery?: (orderDelivery: OrderDeliveryItem[]) => void;
};

export const RecipientsBookDialog = ({
  title,
  states,
  onSingleSelect,
  orderDeliveryItem,
  onSelectedItemInMultiSelect,
  onChangeSelectable,
  ...props
}: RecipientsBookDialogProps) => {
  const { data } = useGetAccount();
  const [, { close }] = useDialogContext();
  const { isMobile } = useDeviceDetect();
  const orientation = useScreenOrientation();
  const newAddressDialog = useCreateAddressDialog({ states: states });
  const updateAddressDialog = useUpdateAddressDialog({ states: states });
  const [addressesDataBook, setAddressesDataBook] = useState<Address[]>([]);
  const [, setFake] = useState(0);

  const parseAddressList = (addressList: Address[]) => {
    if (!addressList) return [];
    if (!onSelectedItemInMultiSelect) return _.sortBy(addressList, (obj: any) => obj.lastName.toLowerCase());

    return _.sortBy(
      addressList.map((address) => {
        const element = orderDeliveryItem.shippingPlanning.find((sp) => sp.address.id === address.id);
        return {
          ...address,
          isSelectable: element ? true : false,
          quantitySelected: element ? element.quantity : 0,
        };
      }),
      (obj: any) => obj.lastName.toLowerCase(),
    );
  };

  useEffect(() => {
    if (data && data.addresses && data.addresses.length > 0) {
      // @ts-expect-error
      if (onSelectedItemInMultiSelect) setAddressesDataBook(parseAddressList(data.addresses));
      // @ts-expect-error
      else setAddressesDataBook(data.addresses);
    }
  }, [data]);

  const onChangeSelectableByPass = (address: Address, checked: boolean) => {
    const quantitySelected = orderDeliveryItem.shippingPlanning.map((sp) => sp.quantity).reduce((a, b) => a + b, 0);

    if (checked && quantitySelected === orderDeliveryItem.quantity) return;

    // @ts-expect-error
    if (onSelectedItemInMultiSelect) setAddressesDataBook(parseAddressList(data.addresses));
    // @ts-expect-error
    else setAddressesDataBook(data.addresses);
    onChangeSelectable(address, checked);
  };

  const onChangeSelectedMultiSelectByPass = (address: Address, quantity: number) => {
    setFake(new Date().getTime());
    onSelectedItemInMultiSelect(address, quantity);
  };

  const allowIncrement = orderDeliveryItem
    ? orderDeliveryItem.shippingPlanning.map((sp) => sp.quantity).reduce((a, b) => a + b, 0) <
      orderDeliveryItem.quantity
    : false;

  const columns = getColumns(
    updateAddressDialog,
    onSingleSelect,
    !orderDeliveryItem ? null : onChangeSelectedMultiSelectByPass,
    onChangeSelectableByPass,
    allowIncrement,
    close,
  );

  const selectAll = (value: boolean) => {
    for (let i = 0; i < orderDeliveryItem.lineItem.count; i++) {
      if (i < addressesDataBook.length) onChangeSelectableByPass(addressesDataBook[i], value);
    }
  };

  return (
    <Dialog {...props}>
      <Dialog.Content
        className={cn(
          orientation === 'landscape-primary' && isMobile
            ? 'top-0 flex h-full flex-initial flex-col overflow-scroll'
            : 'max-h-fit min-w-fit',
        )}
      >
        <Dialog.Header className="mt-4 flex flex-row justify-center">
          <Dialog.Title className="text-black text-3xl font-bold">{title}</Dialog.Title>
        </Dialog.Header>
        <Separator />
        <div className="flex flex-col">
          <div className="my-2 flex flex-col justify-between space-y-4 lg:flex-row lg:items-center lg:space-y-0">
            <Text className="text-black text-lg font-semibold">My Address Book</Text>
            <div className="flex flex-col lg:flex-row">
              <div className="flex items-center justify-between space-x-5 lg:justify-end">
                <Button
                  palette="black"
                  variant="outlined"
                  className="mb-2 font-semibold"
                  onClick={() => newAddressDialog.open()}
                >
                  <PlusIcon className="mr-1 h-4 w-4" />
                  <div className="hidden lg:block">ADD NEW RECIPIENT</div>
                  <div className="block lg:hidden">ADD NEW</div>
                </Button>
                {orderDeliveryItem && (
                  <div className="flex space-x-2">
                    <div>Select all</div>
                    <input type="checkbox" onChange={(e) => selectAll(e.target.checked)} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="lg:hidden">
            <RecipientTableMobile
              addresses={parseAddressList(addressesDataBook)}
              onSingleSelect={!orderDeliveryItem ? onSingleSelect : null}
              onClose={close}
              allowIncrement={allowIncrement}
              editCallback={updateAddressDialog}
              onChangeSelectable={onChangeSelectableByPass}
              onSelectedItemInMultiSelect={onChangeSelectedMultiSelectByPass}
            />
          </div>

          <div className="hidden lg:block">
            <RecipientsBookTable columns={columns as any} data={parseAddressList(addressesDataBook)} />
          </div>

          <div className="my-2 flex flex-row items-center justify-end space-x-4 pt-4">
            <Button palette="primary" variant="text" onClick={close}>
              Close
            </Button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
};
