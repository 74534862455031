// Enum for possible event types
export enum ANALYTICS_EVENTS {
  /** Adds payment info */
  ADD_PAYMENT_INFO = 'add_payment_info',

  /** Adds shipping info */
  ADD_SHIPPING_INFO = 'add_shipping_info',

  /** Adds an item to the cart */
  ADD_TO_CART = 'add_to_cart',

  /** Adds an item to the wishlist */
  ADD_TO_WISHLIST = 'add_to_wishlist',

  /** Begins the checkout process (after cart page) */
  BEGIN_CHECKOUT = 'begin_checkout',

  /** Logs in */
  LOGIN = 'login',

  /** Submits a purchase */
  PURCHASE = 'purchase',

  /** Refunds an order */
  REFUND = 'refund',

  /** Removes an item from the cart */
  REMOVE_FROM_CART = 'remove_from_cart',

  /** Conducts a keyword search */
  SEARCH = 'search',

  /** Selects an item from a list */
  SELECT_ITEM = 'select_item',

  /** Selects a promotion from a list */
  SELECT_PROMOTION = 'select_promotion',

  /** Social Share */
  SHARE = 'share',

  /** Creates a new account */
  SIGN_UP = 'sign_up',

  /** Views the cart page */
  VIEW_CART = 'view_cart',

  /** Views an item's PDP page */
  VIEW_ITEM = 'view_item',

  /** Views a list of items (PLP page) */
  VIEW_ITEM_LIST = 'view_item_list',

  /** Views a promotion */
  VIEW_PROMOTION = 'view_promotion',
}

// Type for the data structure pushed to dataLayer
export type DataLayerObject = {
  event: ANALYTICS_EVENTS;
  ecommerce?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  pageType?: string;
  customerGroup?: string;
};

declare global {
  interface Window {
    dataLayer: DataLayerObject[];
  }
}

const dataLayerHistoryMaxSize = 3;

// Array to store the history of pushed data objects
const dataLayerHistory: DataLayerObject[] = [];

// Function to check if dataObject is in the history
const isInDataLayerHistory = (dataObject: DataLayerObject): boolean => {
  return dataLayerHistory.some((historyItem) => JSON.stringify(historyItem) === JSON.stringify(dataObject));
};

// Function to push data to the dataLayer
export const pushToDataLayer = (dataObject: DataLayerObject): void => {
  if (typeof window === 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  // Only push if the new object is not in the history
  if (!isInDataLayerHistory(dataObject)) {
    window.dataLayer.push(dataObject);
    dataLayerHistory.push(dataObject);
    // Limit the size of history to possible duplicates instead of website navigation
    if (dataLayerHistory.length > dataLayerHistoryMaxSize) {
      dataLayerHistory.shift();
    }
  }
};
