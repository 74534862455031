import React from 'react';
import { useAsio } from '@ariessolutionsio/providers-richproducts';
import _ from 'lodash';
import useSWR from 'swr';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import { CatalogsInfo } from '../../catalogs';
import Sidemenu from '../side-menu';

export const CatalogsTastic = () => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const contentTitle = formatAccountMessage({ id: 'account.catalog', defaultMessage: 'Corporate Catalogs' });
  const [{ products }] = useAsio();
  const { data: selection } = useSWR('selection', () => products.getProductSelection());

  return (
    <>
      <Sidemenu hash={'/my-account/catalog'} contentTitle={contentTitle}>
        <CatalogsInfo catalog={selection} />
      </Sidemenu>
    </>
  );
};

export default CatalogsTastic;
