import { FC, useMemo, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useToast } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { DisplayPrice } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/templates/DisplayPrice';
import { AddRemovePromo } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/shopping-carts/add-remove-promo';
import { cn } from '@ariessolutionsio/react-ecomm-ui/dist/utils/classNames';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { clearSession, clearAllLocalData } from 'frontastic/lib/utils/FrontasticSessionData';
import { useAccount, useCart } from 'frontastic/provider';
import { UseCart } from 'frontastic/provider/frontastic/UseCart';
import CustomLoader from 'frontastic/provider/loading/CustomLoader';
import { useLoading } from 'frontastic/provider/loading/LoadingProvider';
import { useCheckoutContext } from '../checkout/contexts/checkout.context';
import { INSUFFICIENT_STOCK, INSUFFICIENT_STOCK_MESSAGE, ORDER_ERROR_TITLE } from '@/helpers/constants/cart';

export const Accordion: FC<{
  items: any[];
}> = ({ items }) => {
  return (
    <>
      {Object.keys(items).map((key) => (
        <Disclosure key={key}>
          {({ open }) => (
            <>
              <h3>
                <Disclosure.Button className="group relative flex w-full items-center  text-left">
                  <span className={cn('text-primary-800', 'text-[12px]')}>{items[key].name}</span>
                  <span className="ml-1 flex items-center">
                    {open ? (
                      <ChevronUpIcon
                        className="block h-4 w-4 text-primary-800 group-hover:text-primary-800"
                        aria-hidden="true"
                      />
                    ) : (
                      <ChevronDownIcon
                        className="block h-4 w-4 text-primary-800 group-hover:text-primary-800"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </Disclosure.Button>
              </h3>
              <Disclosure.Panel>
                <div>{items[key].component}</div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </>
  );
};

interface OrderSummaryProps {
  cart: UseCart | any;
  showTotalPrice?: boolean;
}

const paymentErrorToast = {
  title: 'Payment Error',
  description:
    'There was an issue with your method of payment. Please review and try again, use a different payment method, or contact us at orders@christiecookies.com | 1-800-458-2447',
};

export const OrderSummary: FC<OrderSummaryProps> = (props) => {
  const { cart, showTotalPrice = true } = props;
  const { data } = cart;
  const { toast } = useToast();
  const { globalLoading } = useLoading();
  const [{ currentStep, checkoutData, shippingMode }] = useCheckoutContext();
  //const paymentId = paymentMethodResult?.paymentMethod?.id;
  const router = useRouter();
  const { loggedIn } = useAccount();
  const [placingOrder, setPlacingOrder] = useState(false);

  const items: any[] = data?.lineItems;
  const imageLoader = ({ src, width, quality }) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  const getSubTotal = () => {
    return items?.reduce((a, b) => a + b.variant.price.centAmount * b.count, 0);
  };

  const totalShippingPrice = useMemo(() => {
    let sum = 0;
    if (data.shippingInfo) {
      for (const item of data.shippingInfo) {
        sum += item?.shippingInfo?.price?.centAmount ?? 0;
      }
    }
    return sum;
  }, [data]);

  const totalShippingDiscountedPrice = useMemo(() => {
    let sum = 0;
    if (data.shippingInfo) {
      for (const item of data.shippingInfo) {
        sum += item?.shippingInfo?.discountedPrice?.value?.centAmount ?? 0;
      }
    }
    return sum;
  }, [data]);

  const totalShippingPriceForPromo = useMemo(() => {
    let sum = 0;
    if (data.shippingInfo) {
      for (const item of data.shippingInfo) {
        sum += item?.shippingCustomFields?.fields?.customShippingDiscount?.centAmount ?? 0;
      }
    }
    return sum;
  }, [data]);

  const onPlaceOrder = async () => {
    if (!checkoutData.payment && !checkoutData.payment.confirmationToken) {
      toast(paymentErrorToast);
      return;
    }
    setPlacingOrder(true);
    let result;
    try {
      result = await cart.submitOrder(checkoutData.payment.confirmationToken);
    } catch (e) {
      toast(paymentErrorToast);
      setPlacingOrder(false);
      return;
    }
    if (result.orderId && result.orderId.length > 0) {
      await cart.clearCartAndSession();
      await clearAllLocalData();
      if (!loggedIn) {
        await clearSession('frontastic-session');
      }
      setTimeout(() => {
        router.push(`/thank-you?order=${result.orderId}`);
      });
    } else if (result.infoError) {
      setPlacingOrder(false);
      if (result?.message === INSUFFICIENT_STOCK) {
        toast({
          title: ORDER_ERROR_TITLE,
          description: INSUFFICIENT_STOCK_MESSAGE,
        });
        return;
      }

      toast({
        title: ORDER_ERROR_TITLE,
        description: result.message ?? result.details,
      });
    } else {
      setPlacingOrder(false);
      toast({
        title: 'Unable to create order. Please try again.',
      });
    }
  };

  if (!items) return <></>;

  const totalTaxes = data?.taxedPrice?.totalTax.centAmount;

  return (
    <div className={showTotalPrice ? 'py-6' : 'pt-6'}>
      {items.map((product) => (
        <div key={product.lineItemId} className="flex items-start justify-between py-3">
          <div className="flex break-words">
            <div className="min-w-fit">
              <Image
                alt={product.variant?.imagesAltText?.[0] || product?.name}
                loader={imageLoader}
                src={product.variant?.images[0]}
                width={44}
                height={44}
                className="object-cover"
              />
            </div>
            <div className="mr-auto space-y-1 px-3">
              <div className="text-sm">{product.name}</div>
              <div className="space-x-1 text-xs">
                <span>Quantity:</span>
                <span>{product.count}</span>
              </div>
              {product.variant?.attributes?.cookie_flavor || product.variant?.attributes?.tin_color ? (
                <Accordion
                  items={[
                    {
                      id: '1',
                      name: 'Details',
                      component: (
                        <div className="flex flex-col space-y-1">
                          <div className="text-xs">
                            {product.variant?.attributes?.cookie_flavor && (
                              <div className="space-x-1">
                                <span>Assortment:</span>
                                <span>{product.variant?.attributes?.cookie_flavor?.label}</span>
                              </div>
                            )}
                            {product.variant?.attributes?.tin_color && (
                              <div className="space-x-1">
                                <span>Tin:</span>
                                <span>{product.variant?.attributes?.tin_color?.label}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      ),
                    },
                  ]}
                ></Accordion>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="ml-6">
            <DisplayPrice
              price={product.totalPrice}
              component={(displayPrice) => <div>{displayPrice.displayPrice}</div>}
            />
          </div>
        </div>
      ))}
      <div className="mb-4 space-y-4 border-y py-6 text-sm">
        <section className="flex justify-between">
          <div>Item Subtotals:</div>
          <DisplayPrice
            price={{
              centAmount: getSubTotal(),
              currencyCode: 'USD',
              fractionDigits: 2,
            }}
            component={(displayPrice) => <div className="text-base lg:text-sm">{displayPrice.displayPrice}</div>}
          />
        </section>
        {data.discountCodes.length !== 0 && (
          <section className="flex justify-between">
            <div>
              Discount:{' '}
              <span>
                {data.discountCodes[0]?.code === 'IMPLICIT' ? data.discountCodes[0]?.name : data.discountCodes[0]?.code}
              </span>
            </div>
            <DisplayPrice
              price={data.discountCodes[0]?.discountedAmount}
              component={(displayPrice) => (
                <div className="text-base text-primary-800 lg:text-sm">- {displayPrice.displayPrice}</div>
              )}
            />
          </section>
        )}
        {data && data.shippingInfo && (
          <section className="flex justify-between">
            {totalShippingPriceForPromo > 0 ? (
              <>
                {' '}
                <div>
                  {data?.discountCodes?.length !== 0 && data.discountCodes[0]?.discountedAmount?.centAmount > 0
                    ? 'Shipping3:'
                    : 'Shipping Promo Applied:'}
                </div>
                {globalLoading ? (
                  <CustomLoader />
                ) : (
                  <div className="flex justify-between">
                    <DisplayPrice
                      price={{
                        // @ts-ignore
                        centAmount: totalShippingPriceForPromo + totalShippingPrice,
                        currencyCode: 'USD',
                        fractionDigits: 2,
                      }}
                      component={(displayPrice) => (
                        <div className="mr-10 line-through">{displayPrice.displayPrice}</div>
                      )}
                    />
                    <DisplayPrice
                      price={{
                        // @ts-ignore
                        centAmount: totalShippingPrice,
                        currencyCode: 'USD',
                        fractionDigits: 2,
                      }}
                      component={(displayPrice) => <div className="ml-0">{displayPrice.displayPrice}</div>}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {' '}
                <div>Shipping:</div>
                <DisplayPrice
                  price={{
                    // @ts-ignore
                    centAmount: totalShippingPrice,
                    currencyCode: 'USD',
                    fractionDigits: 2,
                  }}
                  component={({ displayPrice }) => (
                    <div>
                      {globalLoading ? (
                        <CustomLoader />
                      ) : totalShippingPrice <= 0 ? (
                        'Calculated at next step'
                      ) : totalShippingDiscountedPrice > 0 ? (
                        <>
                          <span className="mr-2 line-through opacity-50">{displayPrice}</span>
                          <DisplayPrice
                            price={{
                              centAmount: totalShippingDiscountedPrice,
                              currencyCode: 'USD',
                              fractionDigits: 2,
                            }}
                            component={({ displayPrice }) => <>{displayPrice}</>}
                          />
                        </>
                      ) : (
                        displayPrice
                      )}
                    </div>
                  )}
                />
              </>
            )}
          </section>
        )}

        {cart.data &&
          cart.data.taxed &&
          totalTaxes !== 0 &&
          !globalLoading &&
          cart.data?.taxed?.taxPortions?.map(
            (tax) =>
              tax.amount.centAmount !== 0 && (
                <section className="flex justify-between" key={tax.amount?.centAmount}>
                  <div>Tax:</div>
                  <DisplayPrice
                    price={tax.amount}
                    component={(displayPrice) => <div>{displayPrice.displayPrice}</div>}
                  />
                </section>
              ),
          )}
      </div>

      {currentStep === 2 && (
        <div className="lg:hidden">
          <AddRemovePromo useCart={useCart as any} />
          <p className="mt-2.5 text-xs text-gray-700">Max one promo code per order</p>
          <hr className="my-4" />
        </div>
      )}

      {showTotalPrice && (
        <section className="flex justify-between font-bold">
          <div>Total:</div>
          <div>
            {globalLoading ? (
              <CustomLoader />
            ) : (
              <DisplayPrice
                price={data?.taxedPrice?.totalGross || data.sum}
                component={(displayPrice) => <div className="text-xl">{displayPrice.displayPrice}</div>}
              />
            )}
          </div>
        </section>
      )}
      {currentStep === 3 && (
        <section className="py-8">
          <Button disabled={placingOrder} variant="contained" fullWidth onClick={onPlaceOrder}>
            {placingOrder ? 'Placing Order...' : `Place Order`}
          </Button>
        </section>
      )}
    </div>
  );
};
