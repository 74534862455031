import Link from 'next/link';
import Image from 'frontastic/lib/image';

function ImageContainer({ data }) {
  const { image, backgroundImageLink } = data || {};

  const link =
    backgroundImageLink?.type === 'link'
      ? backgroundImageLink.link
      : backgroundImageLink?.type === 'page-folder'
      ? backgroundImageLink.pageFolder?._url
      : null;

  if (link) {
    const target = backgroundImageLink.openInNewWindow ? '_blank' : '_self';
    return (
      <div className="flex place-content-center">
        <Link href={link} target={target}>
          <a>
            <Image src={image?.media?.file} alt={image?.media?.name} className="cursor-pointer" />
          </a>
        </Link>
      </div>
    );
  }

  return (
    <div className="flex place-content-center">
      <Image src={image?.media?.file} alt={image?.media?.name} />
    </div>
  );
}

export default ImageContainer;
