import { LinkButton } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/LinkButton';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { ReferenceLink } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/templates/ReferenceLink';
import { cn } from '@ariessolutionsio/react-ecomm-ui/dist/utils/classNames';

export const HeroWithSinglePanel = ({ data }) => {
  const cta = data.callToAction[0];
  const ctaLabelWeight = cta.labelBold ? 'font-bold' : 'font-normal';
  const ctaLabelItalic = cta.labelItalic ? 'italic' : 'not-italic';
  const ctaLabelFontSize = cta.labelFontSize ? `text-[${cta.labelFontSize}px]` : 'text-xs';

  return (
    <div
      className="w-full px-8 py-10 lg:px-12 lg:py-[86px]"
      style={{ backgroundColor: data.backgroundColor ? `#${data.backgroundColor}` : '' }}
    >
      <div className="space-y-6">
        {data.eyebrow && (
          <Typography
            tagName="h4"
            variant="h5"
            className={cn('text-[32px] font-normal lg:text-[40px]', data.darkMode ? 'text-white' : 'text-[#2B2A2D]')}
          >
            {data.eyebrow}
          </Typography>
        )}
        {data.title && (
          <Typography tagName="h1" variant="h1" className={cn('', data.darkMode ? 'text-white' : 'text-black')}>
            {data.title}
          </Typography>
        )}
        {data.description && (
          <Typography tagName="p" className={cn('', data.darkMode ? 'text-white' : 'text-black')}>
            {data.description}
          </Typography>
        )}
        {data.callToAction[0].name !== '' && (
          <div className="space-y-3">
            {data.callToAction.map((item) => (
              <>
                {data.darkMode ? (
                  <div>
                    <ReferenceLink
                      link={item}
                      component={(linkProps) => (
                        <LinkButton
                          size="md"
                          palette="white"
                          shape="md"
                          href={linkProps.href}
                          target={linkProps.target}
                          className={cn(ctaLabelWeight, ctaLabelItalic, ctaLabelFontSize)}
                        >
                          {linkProps.text}
                        </LinkButton>
                      )}
                    />
                  </div>
                ) : (
                  <div>
                    <ReferenceLink
                      link={item}
                      component={(linkProps) => (
                        <LinkButton
                          size="md"
                          palette="primary"
                          shape="md"
                          href={linkProps.href}
                          target={linkProps.target}
                          className={cn(ctaLabelWeight, ctaLabelItalic, ctaLabelFontSize)}
                        >
                          {linkProps.text}
                        </LinkButton>
                      )}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
