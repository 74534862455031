import { FC, useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { Input, Text } from '@ariessolutionsio/primitives-richproducts';
import { Address, Configurator } from '@ariessolutionsio/providers-richproducts';
import { Select, useToast } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { Form } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/data-entry/form';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setCookie } from 'cookies-next';
import moment from 'moment';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import Datepicker from 'react-tailwindcss-datepicker';
import * as z from 'zod';
import { clearAllLocalData, clearSession } from '@/frontastic/lib/utils/FrontasticSessionData';
import { fetchApiHub } from 'frontastic';
import { useAccount } from 'frontastic/provider';
import { signUpInfoSchema } from './schemas/signup';
import { useSignInDialog } from '../../../../hooks/dialogs/auth/sign-in';
import Toast from 'react-hot-toast';

export const CreateAccount: FC = (props: any) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const { login, requestConfirmationEmail } = useAccount();
  const [sendingInfo, setSendingInfo] = useState(false);
  const { toast } = useToast();
  const { push } = useRouter();
  const { bypassVerification } = props.data;
  const signInDialog = useSignInDialog({
    title: 'Sign In',
    onSignIn: async (values) => {
      try {
        const response = await login(values.username, values.password);

        fetchApiHub('/action/account/getTokenDetails', { method: 'POST' }, {}).then((res) => {
          if (res.token) {
            setCookie('asio_session', JSON.stringify(res));

            Configurator.get().setSession({
              refreshToken: res.refreshToken,
              accessToken: res.token,
            });
          }
        });

        if (response.accountId) {
          signInDialog.close();
          return true;
        } else if (response.infoError) {
          await clearSession('frontastic-session');
          await clearAllLocalData();
          queryClient.removeQueries({ queryKey: ['get:account'] });
          return response.message;
        }
      } catch (error) {
        return error;
      }
    },
    requestConfirmationEmail: async (values) => {
      try {
        const response = await requestConfirmationEmail(values.username, values.password);
        if (response.success) {
          push('/my-account/success-email-verification-pending');
          return true;
        } else if (response.infoError) {
          return response.message;
        }
      } catch (error) {
        return error;
      }
    },
  });
  const router = useRouter();
  const { register, generateUniqueIdNumber } = useAccount();

  const s = props.data?.provinces?.dataSource?.value;
  const states = Object.keys(s).map((key) => ({ key, value: s[key] }));
  const form = useForm<z.infer<typeof signUpInfoSchema>>({
    resolver: zodResolver(signUpInfoSchema),
  });

  const unavailableDates = [
    {
      start: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
    },
    {
      start: moment().startOf('year').format('YYYY-MM-DD'),
      end: moment().endOf('year').format('YYYY-MM-DD'),
    },
    {
      start: moment().add(1, 'year').startOf('year').format('YYYY-MM-DD'),
      end: moment().add(1, 'year').endOf('year').format('YYYY-MM-DD'),
    },
  ];

  const signUpMutation = useMutation({
    mutationKey: ['signUp'],
    mutationFn: (values: any) => register(values),
    onSuccess: async (res: any) => {
      setSendingInfo(false);
      let result = res;
      if (res.body) {
        result = JSON.parse(res.body);
      }
      if (result.infoError) {
        setError(true);
        setErrorMsg(result.message ?? 'Unable to create account at this time. Please try later');
      } else {
        if (bypassVerification && res.confirmed) {
          router.push('/').then(() => {
            Toast.success('Your account is created successfully. Please login.', {
              duration: 10000,
            });
            signInDialog.open();
          });
          queryClient.removeQueries({ queryKey: ['get:account'] });
        } else {
          setCookie('email_to_verify', form.watch('email'));
          router.push('/my-account/success-email-verification-pending');
          queryClient.removeQueries({ queryKey: ['get:account'] });
        }
      }
    },
    onError: (error: any) => {
      queryClient.removeQueries({ queryKey: ['get:account'] });
      setErrorMsg('There is already an account with this email address.');
      setError(true);
      setSendingInfo(false);
    },
  });

  const formatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.includes('-') ? phoneNumber : phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  };

  const genGenIDNumber = async (entity: string) => {
    try {
      const response = await generateUniqueIdNumber(entity);
      let customerNumber;
      if (!response.hasError) {
        const { nextId } = response ?? { nextId: undefined };
        customerNumber = nextId;
      } else {
        customerNumber = undefined;
      }
      return customerNumber;
    } catch (error) {
      toast({
        title: 'Unable to generate customer number at this time, Please try again!',
        duration: 5000,
      });
      setSendingInfo(false);
      return undefined;
    }
  };

  const onSubmit = useCallback(
    async (values: z.infer<typeof signUpInfoSchema>) => {
      setError(false);
      setSendingInfo(true);
      const customerNumber = await genGenIDNumber('CUSTOMER');
      if (!customerNumber) {
        toast({
          title: 'Unable to generate customer number at this time, Please try again!',
          duration: 5000,
        });
        setSendingInfo(false);
        return;
      }
      const addressId = await genGenIDNumber('ADDRESS');
      if (!customerNumber) {
        toast({
          title: 'Unable to generate customer number at this time, Please try again!',
          duration: 5000,
        });
        setSendingInfo(false);
        return;
      }
      const { address } = values;
      signUpMutation.mutate({
        email: values.email,
        password: values.password.newPassword,
        firstName: values.firstName,
        lastName: values.lastName,
        customerNumber: customerNumber.toString() ?? undefined,
        phoneNumber: values.phoneNumber,
        metaData: {
          phoneNumber: values.phoneNumber,
          isSubscribedToNewsletter: undefined,
        },
        dateOfBirth: values.dateOfBirth?.startDate,
        byPassVerification: bypassVerification ?? false,
        addresses: [
          {
            // customData: {
            //   magento_entity_id: addressId.toString() ?? undefined,
            // },
            key: addressId.toString() ?? undefined,
            id: addressId ?? undefined,
            firstName: values.firstName,
            lastName: values.lastName,
            streetName: address.streetName,
            streetNumber: address.streetNumber,
            state: address.state,
            postalCode: address.postalCode,
            city: address.city,
            isDefaultBillingAddress: true,
            isDefaultShippingAddress: true,
            country: 'US',
          } as Address,
        ],
      });
    },
    [signUpMutation],
  );

  return (
    <div className="flex items-center justify-center lg:my-16">
      <div className="w-full rounded bg-white px-4 py-16 lg:max-w-[850px] lg:p-0">
        <div className="lg:px-24 lg:py-16">
          <section className="flex flex-col items-center justify-center space-y-3">
            <Typography variant="h3">Create Your Account</Typography>
            <div className="flex space-x-2 pb-8">
              <Typography variant="body">Already have an account?</Typography>
              <div className="cursor-pointer" onClick={() => signInDialog.open()}>
                <Typography variant="body" color="primary">
                  {' '}
                  Sign in here{' '}
                </Typography>
              </div>
            </div>
          </section>
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
              <Form.Section title="Pesonal Information">
                <div className="grid gap-4 md:grid-cols-2">
                  <Form.Control name="firstName" label="First Name" error={form.formState?.errors?.firstName?.message}>
                    <Input type="text" {...form.register('firstName')} max={11} />
                  </Form.Control>
                  <Form.Control name="lastName" label="Last Name" error={form.formState?.errors?.lastName?.message}>
                    <Input type="text" {...form.register('lastName')} />
                  </Form.Control>
                  <Form.Control
                    name="phoneNumber"
                    label="Phone Number"
                    error={form.formState?.errors?.phoneNumber?.message}
                  >
                    <Input
                      type="text"
                      {...form.register('phoneNumber')}
                      onChange={(e) => {
                        e.target.value = formatPhoneNumber(e.target.value);
                      }}
                      max={12}
                    />
                  </Form.Control>
                  <div className="flex items-center">
                    <div className="lg:border-l ">
                      <div className="px-4">
                        <Typography variant="body" tagName="p" className="pb-2 font-bold">
                          How do we use this info?
                        </Typography>
                        <Typography variant="body" tagName="p" className="text-xs">
                          Phone number will only be used for delivery questions.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <Controller
                  control={form.control}
                  name="dateOfBirth"
                  render={({ field: { onChange, value, name } }) => (
                    <Form.Control name={name} label="Birthday (Optional)">
                      <Datepicker
                        value={value as any}
                        onChange={(v) => {
                          onChange(v.startDate === null ? null : v);
                        }}
                        disabledDates={unavailableDates.map((date) => {
                          return {
                            startDate: date.start,
                            endDate: date.end,
                          };
                        })}
                        asSingle={true}
                        useRange={false}
                        displayFormat={'MM/DD'}
                      />
                    </Form.Control>
                  )}
                ></Controller>
              </Form.Section>
              <Form.Section title="Address Information">
                <Form.Control
                  name="address.streetName"
                  label="Street Address"
                  error={form.formState?.errors?.address?.streetName?.message}
                >
                  <Input type="text" {...form.register('address.streetName')} />
                </Form.Control>
                <Form.Control name="address.additionalStreetInfo" label="Apartment, suite, etc. (optional)">
                  <Input type="text" {...form.register('address.additionalStreetInfo')} />
                </Form.Control>
                <div className="flex flex-col space-x-0 space-y-3 lg:flex-row lg:space-x-3 lg:space-y-0">
                  <Form.Control name="address.city" label="City" error={form.formState?.errors?.address?.city?.message}>
                    <Input type="text" {...form.register('address.city')} />
                  </Form.Control>
                  <Controller
                    control={form.control}
                    name={'address.state'}
                    render={({ field: { onChange, value }, formState: { errors } }) => (
                      <Form.Control name="address.state" label="State" error={errors?.address?.state?.message}>
                        <Select value={value} onValueChange={onChange}>
                          <Select.Trigger aria-label="state">
                            <Select.Value placeholder="Select your state" />
                          </Select.Trigger>
                          <Select.Content>
                            <Select.Group>
                              {states.map((state, index) => (
                                <Select.Item key={`${state.key}-${index}`} value={state.key}>
                                  <div>{state.value}</div>
                                </Select.Item>
                              ))}
                            </Select.Group>
                          </Select.Content>
                        </Select>
                      </Form.Control>
                    )}
                  ></Controller>
                  <Form.Control
                    name="address.postalCode"
                    label="Zip Code"
                    error={form.formState?.errors?.address?.postalCode?.message}
                  >
                    <Input type="text" {...form.register('address.postalCode')} />
                  </Form.Control>
                </div>
              </Form.Section>
              <Form.Section title="Account Information">
                <div className="grid gap-4  md:grid-cols-2">
                  <div className="grid gap-4 ">
                    <div className="grid gap-2">
                      <Form.Control name="email" label="Email Address" error={form.formState?.errors?.email?.message}>
                        <Input type="email" {...form.register('email')} />
                      </Form.Control>
                      <span className="text-xs text-gray-500">This will be your username</span>
                    </div>
                    <Form.Control
                      name="password"
                      label="Password"
                      error={form.formState?.errors?.password?.newPassword?.message}
                    >
                      <Input.Password {...form.register('password.newPassword')} />
                    </Form.Control>
                    <Form.Control
                      name="confirmPassword"
                      label="Confirm Password"
                      error={form.formState?.errors?.password?.confirmPassword?.message}
                    >
                      <Input.Password {...form.register('password.confirmPassword')} />
                    </Form.Control>
                  </div>
                  <div className="flex items-center">
                    <div className="lg:border-l ">
                      <div className="px-4">
                        <Typography variant="body" tagName="p" className="pb-2 font-bold">
                          Password Requirements
                        </Typography>
                        <Typography variant="body" tagName="p">
                          8 characters long
                        </Typography>
                        <Typography variant="body" tagName="p">
                          At least one symbol
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Section>
              <div className="flex flex-col items-center justify-center space-y-2">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={sendingInfo}
                  className="disabled:opacity-50"
                >
                  {!sendingInfo ? `Create Account` : 'Loading...'}
                </Button>
                {error && <Text className="p-0 pl-1 text-primary-800">{errorMsg}</Text>}
              </div>
            </Form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};
