import { FC } from 'react';
import {
  ProductRecommendationsProps,
  WithProductRecommendations,
} from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/hero-sections/with-product-recommendations';
import { Product } from '@ariessolutionsio/react-ecomm-ui/dist/types/product/Product';

export const RecommendedProducts: FC<ProductRecommendationsProps> = ({ data }) => {
  const dataSource = (data as any).data.dataSource;

  const mapRawProductToProduct = (raw) => {
    if (!raw.obj)
      return {
        productId: 'Undefined',
        name: 'Undefined',
        slug: 'Undefined',
        variants: [
          {
            sku: 'Undefined',
            images: ['Undefined'],
            price: {
              fractionDigits: 0,
              centAmount: 0,
              currencyCode: 'USD',
            },
          },
        ],
      };

    const powerReviewsItem = raw?.obj?.masterData?.current?.masterVariant?.attributes?.find(
      (item) => item.name === 'PowerReviews_PageID',
    );

    const powerReviewsPageIDValue = powerReviewsItem ? powerReviewsItem.value : null;

    const _product: Product = {
      productId: raw.obj.key,
      name: raw.obj.masterData.current.name['en-US'],
      slug: raw.obj.masterData.current.slug['en-US'],
      variants: [
        {
          sku: raw.obj.masterData.current.masterVariant.sku,
          images: [raw.obj.masterData.current.masterVariant.images[0].url],
          imagesAltText: [raw.obj.masterData.current.masterVariant.images[0]?.label],
          price: raw.obj.masterData.current.masterVariant.prices[0].value,
          attributes: {
            PowerReviews_PageID: powerReviewsPageIDValue,
          },
        },
      ],
      attributes: undefined,
      _url: `/p/${raw.obj.masterData.current.slug['en-US']}`,
    };
    return _product;
  };

  const _products = dataSource.product.variants[0].attributes.recommendedProducts
    ? dataSource.product.variants[0].attributes.recommendedProducts.map((item) => mapRawProductToProduct(item))
    : [];

  if (_products.length === 0) return <></>;

  return (
    <div>
      <WithProductRecommendations
        data={{
          title: 'You Might Also Be Interested In',
          products: _products,
          arrows: false,
        }}
      />
    </div>
  );
};
