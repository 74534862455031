// Function returns the sorted order value
const getSortOrder = (value: string) => {
  console.log('value getSortOrder', value);
  const ascendingOrderItem = [
    'price: Low to High',
    'variants.attributes.recommendationRanking',
    'variants.attributes.reviewRatingStatistics',
  ];
  return ascendingOrderItem.includes(value) || value.includes('categoryOrderHints.') ? 'asc' : 'desc';
};

// Function returns the sorted Field value
const getSortedFieldValue = (value: string) => {
  return value?.indexOf('price') > -1 ? 'price' : value;
};

//Function to get sorted result values
export const getSortedItems = async (getSortedProducts, value, query) => {
  const result = await getSortedProducts({
    field: getSortedFieldValue(value),
    order: getSortOrder(value),
    category: query?.slugs.length > 0 ? query?.slugs[0] : query.category,
  });

  return result.items;
};
