import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { Form, Button, Input as AsioInput } from '@ariessolutionsio/primitives-richproducts';
import { Configurator } from '@ariessolutionsio/providers-richproducts';
import { Input, useToast } from '@ariessolutionsio/react-ecomm-ui/dist';
import { LinkElement } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Link';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { EMAIL_REGX } from '@ariessolutionsio/react-ecomm-ui/dist/utils/maps';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setCookie } from 'cookies-next';
import { useFormat } from 'helpers/hooks/useFormat';
import { FormProvider, useForm } from 'react-hook-form';
import Toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useAccount } from 'frontastic/provider';
import { fetchApiHub } from '@/frontastic';
import { clearAllLocalData, clearSession } from '@/frontastic/lib/utils/FrontasticSessionData';
import { useSignInDialog } from '@/hooks/dialogs/auth/sign-in';

const signUpInfoSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EMAIL_REGX, 'Please enter a valid email address')
    .required('Please enter a valid email address'),
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{8,})/,
      'Must contain 8 characters and at least one symbol.',
    ),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const CheckoutRegisterForm = (props) => {
  const { order, bypassVerification } = props;
  const router = useRouter();
  const { register, generateUniqueIdNumber, login, requestConfirmationEmail } = useAccount();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  //i18n messages
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const form = useForm({
    resolver: yupResolver(signUpInfoSchema),
    defaultValues: {
      email: order?.email,
      password: '',
      passwordConfirmation: '',
    },
  });

  const signInDialog = useSignInDialog({
    title: 'Sign In',
    onSignIn: async (values) => {
      try {
        const response = await login(values.username, values.password);

        fetchApiHub('/action/account/getTokenDetails', { method: 'POST' }, {}).then((res) => {
          if (res.token) {
            setCookie('asio_session', JSON.stringify(res));

            Configurator.get().setSession({
              refreshToken: res.refreshToken,
              accessToken: res.token,
            });
          }
        });

        if (response.accountId) {
          signInDialog.close();
          return true;
        } else if (response.infoError) {
          await clearSession('frontastic-session');
          await clearAllLocalData();
          queryClient.removeQueries({ queryKey: ['get:account'] });
          return response.message;
        }
      } catch (error) {
        return error;
      }
    },
    requestConfirmationEmail: async (values) => {
      try {
        const response = await requestConfirmationEmail(values.username, values.password);
        if (response.success) {
          router.push('/my-account/success-email-verification-pending');
          return true;
        } else if (response.infoError) {
          return response.message;
        }
      } catch (error) {
        return error;
      }
    },
  });

  const signUpMutation = useMutation({
    mutationKey: ['signUp'],
    mutationFn: (values: any) => register(values),
    onSuccess: (res) => {
      if (res.infoError) {
        toast({
          title: res.message,
          duration: 5000,
        });
        setLoading(false);
      } else {
        if (bypassVerification && res.confirmed) {
          router.push('/').then(() => {
            Toast.success('Your account is created successfully. Please login.', {
              duration: 10000,
            });
            signInDialog.open();
          });
          queryClient.removeQueries({ queryKey: ['get:account'] });
        } else {
          setCookie('email_to_verify', form.watch('email'));
          router.push('/my-account/success-email-verification-pending');
          queryClient.removeQueries({ queryKey: ['get:account'] });
        }
      }
    },
    onError: (error: Error) => {
      toast({
        title: error.message,
        duration: 5000,
      });
      setError(error.message);
      setLoading(false);
    },
  });

  const genGenIDNumber = async (entity: string) => {
    try {
      const response = await generateUniqueIdNumber(entity);
      let customerNumber;
      if (!response.hasError) {
        const { nextId } = response ?? { nextId: undefined };
        customerNumber = nextId;
      } else {
        customerNumber = undefined;
      }
      return customerNumber;
    } catch (error) {
      toast({
        title: 'Unable to generate customer number at this time, Please try again!',
        duration: 5000,
      });
      return undefined;
    }
  };

  const areAddressesEqual = (shipping: any, billing: any) => {
    return (
      shipping.firstName === shipping.firstName &&
      shipping.lastName === shipping.lastName &&
      shipping.city === shipping.city &&
      shipping.state === shipping.state &&
      shipping.postalCode === shipping.postalCode &&
      shipping.streetName === billing.streetName
    );
  };

  const onSubmit = useCallback(
    async (values) => {
      const { anonymousId, dateOfBirth, phone, shippingAddress, billingAddress } = order;
      setLoading(true);
      const customerNumber = await genGenIDNumber('CUSTOMER');
      if (!customerNumber) {
        toast({
          title: 'Unable to generate customer number at this time, Please try again!',
          duration: 5000,
        });
        setLoading(true);
        return;
      }
      let accountAddresses;
      if (areAddressesEqual(shippingAddress[0], billingAddress)) {
        const addressId = await genGenIDNumber('ADDRESS');
        accountAddresses = [
          {
            ...shippingAddress[0],
            isDefaultBillingAddress: true,
            isDefaultShippingAddress: true,
            // customData: {
            //   magento_entity_id: addressId.toString() || undefined,
            // },
            key: addressId.toString() ?? undefined,
          },
        ];
      } else {
        const shippingId = await genGenIDNumber('ADDRESS');
        const billingId = await genGenIDNumber('ADDRESS');
        accountAddresses = [
          {
            ...shippingAddress[0],
            isDefaultShippingAddress: true,
            // customData: {
            //   magento_entity_id: shippingId.toString() || undefined,
            // },
            key: shippingId.toString() ?? undefined,
          },
        ].concat([
          {
            ...billingAddress,
            isDefaultBillingAddress: true,
            // customData: {
            //   magento_entity_id: billingId.toString() || undefined,
            // },
            key: billingId.toString() ?? undefined,
            type: 'BILLING',
          },
        ]);
      }

      signUpMutation.mutate({
        email: values?.email,
        password: values?.password,
        anonymousId: anonymousId,
        firstName: shippingAddress[0]?.firstName ?? '',
        lastName: shippingAddress[0]?.lastName ?? '',
        customerNumber: customerNumber.toString() ?? undefined,
        phoneNumber: formatPhoneNumber(phone),
        byPassVerification: bypassVerification ?? false,
        metaData: {
          phoneNumber: phone,
          isSubscribedToNewsletter: undefined,
        },
        dateOfBirth: dateOfBirth ?? '1988-06-11',
        addresses: accountAddresses,
      });
    },
    [order, signUpMutation],
  );

  const formatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.includes('-') ? phoneNumber : phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  };

  return (
    <div className="">
      <div className="mt-8">
        <div className="mx-0 w-[100%] lg:w-[50%]">
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)} className="space-y-7">
              <div className="py-0 text-left">
                <Typography tagName="h3" variant="h3" className="text-black">
                  {formatCheckoutMessage({
                    id: 'orderconfirmation.register',
                    defaultMessage: 'Checkout faster next time',
                  })}
                </Typography>
                <Typography className="mt-4 inline-block w-[100%] font-normal text-[#2B2A2D]">
                  {formatCheckoutMessage({
                    id: 'orderconfirmation.register.description',
                    defaultMessage:
                      'Move through the checkout process faster, store multiple shipping addresses, view and track your orders and more! Start by choosing a password to create your account today.',
                  })}
                </Typography>
              </div>
              <Form.Section>
                <div className="w-[100%] space-y-5 lg:w-[55%]">
                  <Form.Control name="email" label="Email Address" error={form?.formState?.errors?.email?.message}>
                    <Input {...form.register('email')} readOnly={true} />
                  </Form.Control>
                  <Form.Control name="password" label="Password" error={form?.formState?.errors?.password?.message}>
                    <AsioInput.Password {...form.register('password')} />
                  </Form.Control>
                  <Form.Control
                    name="confirm-password"
                    label="Confirm Password"
                    error={form?.formState?.errors?.passwordConfirmation?.message}
                  >
                    <AsioInput.Password {...form.register('passwordConfirmation')} />
                  </Form.Control>
                </div>
                <Form.Control name="actions" error={error ? 'Invalid email or password' : null}>
                  <div className="mt-6 flex flex-row justify-between space-x-5 rounded-md">
                    <Button
                      type="submit"
                      className="w-[100%] bg-primary-800 uppercase text-white lg:w-[200px]"
                      disabled={loading}
                    >
                      Create Account
                    </Button>
                  </div>
                </Form.Control>
              </Form.Section>
            </Form>
          </FormProvider>
          <div className="mt-6 w-[100%] lg:w-[60%]">
            <p className="mt-4 inline text-xs text-neutral-600">
              By clicking &apos;Create Account&apos; you acknowledge you have read and agreed to our{' '}
              <LinkElement
                href="/customer-service/terms-conditions"
                className="font-medium text-primary-800 hover:underline"
                target="_blank"
              >
                {formatCheckoutMessage({ id: 'termsAndCondition', defaultMessage: 'Terms & Conditions' })}
              </LinkElement>{' '}
              and{' '}
              <LinkElement
                href="/customer-service/privacy-policy"
                className="font-medium text-primary-800 hover:underline"
                target="_blank"
              >
                {formatCheckoutMessage({ id: 'privacyPolicy', defaultMessage: 'Privacy Policy.' })}
              </LinkElement>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
