import { FC, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button, Form, Input as AsioInput } from '@ariessolutionsio/primitives-richproducts';
import { Configurator } from '@ariessolutionsio/providers-richproducts';
import { Input } from '@ariessolutionsio/react-ecomm-ui/dist';
import { yupResolver } from '@hookform/resolvers/yup';
import { setCookie } from 'cookies-next';
import { useForm } from 'react-hook-form';
import { useSWRConfig } from 'swr';
import * as Yup from 'yup';
import { fetchApiHub } from 'frontastic';
import { useAccount } from 'frontastic/provider';

const signInSchema = Yup.object().shape({
  username: Yup.string().email('Please enter a valid email address').required('Please enter a valid email address'),
  password: Yup.string().required('Please enter a password'),
});

type SignInSchemaType = Yup.InferType<typeof signInSchema>;

export type SignInDialogProps = {
  title: string;
  onSignIn?: (values: SignInSchemaType) => Promise<any>;
};

export const AccountVerifiedTastic: FC = () => {
  const { mutate } = useSWRConfig();
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const form = useForm({
    resolver: yupResolver(signInSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const { login, logout } = useAccount();

  const onSignIn = async (values) => {
    try {
      const response = await login(values.username, values.password);

      fetchApiHub('/action/account/getTokenDetails', { method: 'POST' }, {}).then((res) => {
        if (res.token) {
          setCookie('asio_session', JSON.stringify(res));

          Configurator.get().setSession({
            refreshToken: res.refreshToken,
            accessToken: res.token,
          });
        }
      });

      if (response.infoError) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const onSubmitCoFe = useCallback(async (values: SignInSchemaType) => {
    setLoading(true);

    const response = await onSignIn(values);

    if (response) {
      mutate('me');
      mutate('addresses');
      mutate('orders');
      router.push('/my-account');
    } else {
      setError('Invalid email or password');
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    // Ensure that upong successful redirect from email, no user info/token is cached or present on first render,
    // before asking the user to provide credentials.
    logout();
  }, []);

  return (
    <div className=" my-16 flex items-center justify-center ">
      <div className="w-full rounded bg-white px-4 py-16 lg:max-w-[850px] lg:p-0">
        <div className="my-16 flex flex-col items-center justify-center space-y-6 px-6 md:px-32 lg:px-48">
          <h3 className="text-center text-3xl font-extrabold">
            Your email address has been verified. Please sign in to continue.
          </h3>
          <Form onSubmit={form.handleSubmit(onSubmitCoFe)} className="w-full">
            <Form.Section>
              <Form.Control name="username" label="Email Address" error={form?.formState?.errors?.username?.message}>
                <Input {...form.register('username')} />
              </Form.Control>
              <Form.Control name="password" label="Password" error={form?.formState?.errors?.password?.message}>
                <AsioInput.Password {...form.register('password')} />
              </Form.Control>
              <Form.Control name="actions" error={error ? 'Invalid email or password' : null}>
                <div className="flex flex-row justify-between space-x-5 rounded-md">
                  <Button type="submit" className="w-full bg-primary-800 text-white" loading={loading}>
                    {loading ? 'SIGNING IN...' : 'SIGN IN'}
                  </Button>
                  <Button
                    type="button"
                    variant="link"
                    className="w-full text-primary-800"
                    onClick={() => {
                      router.push('/forgot-password');
                    }}
                  >
                    Forgot Password?
                  </Button>
                </div>
              </Form.Control>
            </Form.Section>
          </Form>
        </div>
      </div>
    </div>
  );
};
