import { FC } from 'react';
import { Cart } from '@ariessolutionsio/react-ecomm-ui/dist/types/cart/Cart';
import { UseCart } from '@ariessolutionsio/react-ecomm-ui/dist/types/cart/UseCart';

export interface CartHeaderProps {
  cart: UseCart;
}

export const CartHeader: FC<CartHeaderProps> = (props) => {
  const { cart } = props;

  const getTotalItemsFromCart = (cart: Cart) => {
    if (!cart?.cartId) return 0;
    return cart.lineItems?.reduce((acc, item) => (item.count ? acc + item.count : 0), 0);
  };

  return (
    <>
      <div className="flex py-4 text-2xl">
        <span className="mr-5">
          <svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.63312 1.61133L1.86328 6.63778V24.2304C1.86328 24.8969 2.12807 25.5362 2.59939 26.0075C3.07071 26.4788 3.70996 26.7436 4.37651 26.7436H21.9691C22.6357 26.7436 23.2749 26.4788 23.7462 26.0075C24.2175 25.5362 24.4823 24.8969 24.4823 24.2304V6.63778L20.7125 1.61133H5.63312Z"
              stroke="#2B2A2D"
              strokeWidth="2.81481"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.86328 6.6377H24.4823"
              stroke="#2B2A2D"
              strokeWidth="2.81481"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.1974 11.6641C18.1974 12.9972 17.6679 14.2757 16.7252 15.2183C15.7826 16.1609 14.5041 16.6905 13.171 16.6905C11.8379 16.6905 10.5594 16.1609 9.61675 15.2183C8.6741 14.2757 8.14453 12.9972 8.14453 11.6641"
              stroke="#2B2A2D"
              strokeWidth="2.81481"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className="mr-1">Shopping Cart</span>
        {getTotalItemsFromCart(cart.data as Cart) !== 0 && <span>({getTotalItemsFromCart(cart.data as Cart)})</span>}
      </div>
    </>
  );
};
