/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react';
import { Step } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Step';
import Image from 'frontastic/lib/image';
import { useCheckoutContext } from '../contexts/checkout.context';
import { cn } from '@ariessolutionsio/react-ecomm-ui/dist/utils/classNames';

const Carousel = forwardRef<any, any>(({ children }, ref) => {
  const [startIndex, setStartIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(null);
  const [dragTranslateX, setDragTranslateX] = useState(0);
  const [{ steps, currentStep }] = useCheckoutContext();

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragStartX(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const delta = e.clientX - dragStartX;
      setDragTranslateX(delta);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);

    if (dragTranslateX > 100) {
      setStartIndex((prevIndex) => (prevIndex === 0 ? children.length - 1 : prevIndex - 1));
    } else if (dragTranslateX < -100) {
      setStartIndex((prevIndex) => (prevIndex === children.length - 1 ? 0 : prevIndex + 1));
    }

    setDragTranslateX(0);
  };

  return (
    <div
      ref={ref}
      className=" relative w-[600px] overflow-hidden md:w-full"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div
        className={cn(
          `translate-x-[-${currentStep === 0 ? 0 : currentStep === 1 ? 110 : currentStep === 2 ? 220 : 330}px]`,
          'flex w-[600px] transition-transform duration-300 ease-in-out md:w-full md:translate-x-0',
        )}
      >
        {children}
      </div>
    </div>
  );
});

export const CheckoutHeader = () => {
  const [{ steps, currentStep }] = useCheckoutContext();
  const [{ carouselRef }] = useCheckoutContext();

  return (
    <div className="mx-auto items-center bg-white px-4 sm:px-6 lg:flex lg:border-b lg:border-gray-300 lg:px-8 lg:py-6">
      <a href="/">
        <Image
          src="/assets/images/checkoutLogo.png"
          alt="Christie Cookie logo"
          className="mx-auto my-3 lg:mx-0 lg:mr-7 lg:py-0"
        />
      </a>
      <div className="flex items-center border-y border-gray-300 px-2 lg:mr-[104px] lg:border-0">
        <span className="mr-5 text-xs lg:mr-[105px] lg:text-2xl">Checkout</span>
        <Carousel ref={carouselRef}>
          <div className="relative top-px mx-4 my-2 text-[11px] font-semibold uppercase tracking-wide text-gray-400 lg:mx-[26px] lg:text-sm">
            Cart
          </div>
          {steps.map((step, index) => (
            <Step key={`${step.id}-${index}`} id={step.id} label={step.label} selected={index === currentStep} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};
