import { FC } from 'react';
import { Footer as FooterOriginal } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/footer';
import { FooterWithTwoColumnsProps } from '@ariessolutionsio/react-ecomm-ui/dist/types/frontastic';

const Footer: FC<FooterWithTwoColumnsProps | any> = (props) => {
  const footerData = props;
  const newFooterData = footerData.data.socialMediaLinks.map((item: any) => {
    return { ...item, media: { ...item.media.media } };
  });

  footerData.data.socialMediaLinks = newFooterData;

  return <FooterOriginal {...footerData} />;
};

export { Footer };
