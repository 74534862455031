/* eslint-disable import/no-unresolved */
import { FC } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';

interface ShippingDateProps {
  value: any;
  onChange: (value: any) => void;
  unavailabledDates: any[];
}
export const ShippingDate: FC<ShippingDateProps> = ({ value, onChange, unavailabledDates = [] }) => {
  return (
    <div className="flex flex-col space-y-2">
      <div>Selected Shipping Date</div>
      <Datepicker
        value={value}
        disabledDates={unavailabledDates}
        displayFormat={'YYYY-MM-DD'}
        primaryColor="red"
        asSingle={true}
        readOnly
        useRange={false}
        onChange={(val) => {
          onChange(val);
        }}
      />
    </div>
  );
};
