import React, { useEffect } from 'react';
import router from 'next/router';
import { Input, Button as AsioButton } from '@ariessolutionsio/primitives-richproducts';
import { useAsio } from '@ariessolutionsio/providers-richproducts';
import { Form } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import Sidemenu from '../details/side-menu';
import Spinner from '@/components/commercetools-ui/spinner';
import { useAccount } from '@/frontastic/provider';
import { useGetAccount } from '@/hooks/my-account/use-get-account';

export const updateProfileInfoSchema = Yup.object({
  country: Yup.string(),
  id: Yup.string(),
  email: Yup.string()
    .required('Email is required')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email address'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  middleName: Yup.string(),
  dateOfBirth: Yup.string(),
  metadata: Yup.object({
    isSubscribedToNewsletter: Yup.boolean(),
    phoneNumber: Yup.string()
      .required('Phone Number is a required field')
      .matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/, 'You must enter a valid Phone Number'),
  }),
});

type UpdateProfileInfoSchemaType = Yup.InferType<typeof updateProfileInfoSchema>;

const EditParsonalInfoTastic: React.FC = () => {
  const queryClient = useQueryClient();
  const [{ customers }] = useAsio();
  const { loading } = useAccount();
  const { data, isLoading: dataIsLoading } = useGetAccount();

  const form = useForm({
    resolver: yupResolver(updateProfileInfoSchema),
    defaultValues: {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      dateOfBirth: data?.dateOfBirth,
    },
  });

  useEffect(() => {
    if (data) {
      form.setValue('firstName', data?.firstName);
      form.setValue('lastName', data?.lastName);
      form.setValue('dateOfBirth', data?.dateOfBirth);
      form.setValue('email', data?.email);
      form.setValue('metadata.phoneNumber', data?.metadata.phoneNumber ?? '');
    }
  }, [data, form]);

  const formatPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  };

  const onSubmit = async (values: UpdateProfileInfoSchemaType) => {
    try {
      const version = (await customers.me()).version;

      if (version) {
        const response = await customers.updateProfileInfo({
          customerVersion: version,
          customer: {
            version: version,
            id: values.id,
            firstName: values?.firstName,
            lastName: values?.lastName,
            dateOfBirth: values.dateOfBirth,
            metadata: {
              isSubscribedToNewsletter: false,
              phoneNumber: values.metadata?.phoneNumber ?? '',
            },
          },
        });

        if (!response) {
          toast.error('Error updating profile info');
        } else {
          queryClient.invalidateQueries({ queryKey: ['get:account'] });
          toast.success('Profile info updated');
          router.push('/my-account');
        }
      } else {
        toast.error('Error updating profile info');
      }
    } catch (error) {
      toast.error('Error updating profile info');
    }
  };

  return dataIsLoading ? (
    <Spinner />
  ) : (
    <Sidemenu backButton={{ title: 'My Account', href: '/my-account' }}>
      <Form
        onSubmit={form.handleSubmit(onSubmit)}
        className="mt-0 space-y-11 bg-white p-[30px] lg:ml-6 lg:space-y-[54px] lg:p-[60px]"
        actions={
          <nav className="flex w-full space-x-4">
            <Button
              variant="outlined"
              onClick={() => router.push('/my-account')}
              palette="black"
              className="font-semibold"
            >
              Cancel
            </Button>
            <AsioButton loading={loading} type="submit" className="bg-gray-900 text-white">
              {loading ? 'Saving...' : 'Save'}
            </AsioButton>
          </nav>
        }
      >
        <div>
          <p className="mb-[30px] font-['Akshar'] text-4xl font-bold lg:mb-10">Edit Personal Information</p>
          <Form.Section>
            <div className="w-full space-y-5 md:w-[70%]">
              <div className="mb-4 flex flex-col justify-between gap-5 md:flex-row">
                <div className="mr-6 flex w-full flex-col">
                  <Form.Control name="firstName" label="First Name" error={form?.formState?.errors?.firstName?.message}>
                    <Input type="text" {...form.register('firstName')} />
                  </Form.Control>
                </div>
                <div className="flex w-full flex-col">
                  <Form.Control name="lastName" label="Last Name" error={form?.formState?.errors?.lastName?.message}>
                    <Input type="text" {...form.register('lastName')} />
                  </Form.Control>
                </div>
              </div>
              <div className="grid gap-2">
                <Form.Control name="email" label="Email Address" error={form?.formState?.errors?.email?.message}>
                  <Input disabled type="text" {...form.register('email')} />
                </Form.Control>
                <span className="text-xs text-gray-500">This is your username</span>
              </div>
              <Form.Control
                name="phoneNumber"
                label="Phone Number"
                error={form?.formState?.errors?.metadata?.phoneNumber?.message}
              >
                <Input
                  type="tel"
                  {...form.register('metadata.phoneNumber')}
                  onChange={(e) => {
                    e.target.value = formatPhoneNumber(e.target.value);
                  }}
                  max={12}
                />
              </Form.Control>
              <Form.Control name="dateOfBirth" label="Birthday(Optional)">
                <Input
                  type="date"
                  {...form.register('dateOfBirth')}
                  required={false}
                  onChange={(e) => {
                    form.setValue('dateOfBirth', e.target.value || '');
                  }}
                />
              </Form.Control>
            </div>
          </Form.Section>
        </div>
      </Form>
    </Sidemenu>
  );
};

export default EditParsonalInfoTastic;
