import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Input } from '@ariessolutionsio/primitives-richproducts';
import { Form } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { EMAIL_REGX } from '@ariessolutionsio/react-ecomm-ui/dist/utils/maps';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useAccount } from 'frontastic';

const forgotPasswordSchema = Yup.object({
  email: Yup.string()
    .required('Email is a required field')
    .matches(EMAIL_REGX, 'You must enter a valid email')
    .max(60, 'Email must be less than 60 characters'),
});

const ForgotPasswordTastic = () => {
  const router = useRouter();
  const [isErrorOnResetLink, setIsErrorOnResetLink] = useState(false);
  const [isMailSent, setIsMailSent] = useState(false);
  const { requestPasswordReset } = useAccount();
  const form = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (values) => {
    if (isMailSent) {
      router.push({ pathname: '/', query: { bootLogin: true } });
      return;
    }
    const resp = await requestPasswordReset(values?.email);
    if (resp?.['success']) {
      setIsMailSent(true);
    } else {
      setIsErrorOnResetLink(true);
    }
  };

  return (
    <Form
      onSubmit={form.handleSubmit(onSubmit)}
      className="space-y-11 bg-white p-[30px] px-[10px] lg:mx-[20%] lg:mt-[5%] lg:space-y-[54px] lg:p-[60px]"
      actions={
        <nav className="flex w-full justify-center space-x-4 text-center">
          <Button palette="black" type="submit" className="bg-primary-800 text-[12px] font-bold text-white">
            {isMailSent ? 'Back to login' : 'Send Reset Link'}
          </Button>
        </nav>
      }
    >
      <div>
        <p className="mb-[23px] text-center font-['Akshar'] text-4xl font-bold lg:mb-10">Reset Your Password</p>
        {!isMailSent && (
          <div className="flex w-full items-center justify-center">
            <p className="font-['Galano Classic'] !m-8 mb-[16px] max-w-[508px] text-center font-normal lg:mb-10">
              Enter the email address associated with your Christie Cookie account to receive a password reset link.
            </p>
          </div>
        )}
        <Form.Section>
          <div className="space-y-5 md:mx-10 md:px-20 lg:mx-10">
            {isMailSent ? (
              <p className="text-1xl font-['Galano Classic'] !m-0 text-primary-800">
                A password reset email has been sent to {form.getValues().email}
              </p>
            ) : (
              <Form.Control name="email" label="Email Address" error={form?.formState?.errors?.email?.message}>
                <div className="relative">
                  <Input type="email" {...form.register('email')} onChange={() => setIsErrorOnResetLink(false)} />
                </div>
              </Form.Control>
            )}
            {isErrorOnResetLink && (
              <p className="text-1xl font-['Galano Classic'] !m-0 text-primary-800">Email address not found</p>
            )}
          </div>
        </Form.Section>
      </div>
    </Form>
  );
};

export default ForgotPasswordTastic;
