import { Account } from '@commercetools/frontend-domain-types/account';
import { useQuery } from '@tanstack/react-query';
import { getAccount } from './services';

export function useGetAccount() {
  return useQuery<
    Account & {
      dateOfBirth: string;
      metadata: { isSubscribedToNewsletter: boolean; phoneNumber: string };
      stores?: string[];
    }
  >({
    queryKey: ['get:account'],
    queryFn: getAccount,
  });
}
