import { useDialog } from '@ariessolutionsio/primitives-richproducts';
import {
  CreateAddressDialogProps,
  CreateAddressDialog,
} from '../../../components/common/dialogs/address/create-address.dialog';
import {
  UpdateAddressDialogProps,
  UpdateAddressDialog,
} from '../../../components/common/dialogs/address/update-address.dialog';

export function useCreateAddressDialog(props: CreateAddressDialogProps) {
  return useDialog<CreateAddressDialogProps>(<CreateAddressDialog {...props} />);
}

export function useUpdateAddressDialog(props: UpdateAddressDialogProps) {
  return useDialog<UpdateAddressDialogProps>(<UpdateAddressDialog {...props} />);
}
