import { FC } from 'react';

interface BlankPanelProps {
  data: {
    height: string;
  };
}

export const BlankPanel: FC<BlankPanelProps> = () => {
  return <div id="pr-review-display"></div>;
};
