import { FC } from 'react';
import { useIncrementDecrement } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/IncrementDecrementSelector';
import { LinkButton } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/LinkButton';
import { Variant } from '@ariessolutionsio/react-ecomm-ui/dist/types/product/Variant';
import { UseCart } from '@/frontastic/provider/frontastic/UseCart';
import { cn } from '@ariessolutionsio/react-ecomm-ui/dist/utils/classNames';

interface PDPAddToCartProps {
  useCart: UseCart;
  singleVariant: Variant;
  store?: string;
  isOnStock?: boolean;
  onAddToCart?: (variant: Variant, quantity: number, store?: string) => void;
  extended?: {
    addToBagIcon?: JSX.Element;
    addToBagIconPosition?: 'left' | 'right';
  };
}

export const PDPAddToCart: FC<PDPAddToCartProps> = ({
  useCart,
  singleVariant,
  extended,
  store,
  isOnStock,
  onAddToCart,
}) => {
  const { IncrementDecrement, selectedQty } = useIncrementDecrement({
    disabled: !isOnStock,
  });
  return (
    <div className="flex items-center sm:flex-row">
      <div className="mr-4">
        <IncrementDecrement />
      </div>

      <div
        onClick={() => {
          if (isOnStock) {
            useCart.addItem(singleVariant, selectedQty, store);
            if (onAddToCart) onAddToCart(singleVariant, selectedQty, store);
          }
        }}
        className="w-full"
      >
        <LinkButton
          fullWidth
          className={cn(
            'add-to-cart-button',
            !isOnStock ? 'text-black hover:text-black pointer-events-none bg-[#d1d5db] bg-[#d1d5db]' : '',
          )}
        >
          <div className="flex w-full items-center justify-center space-x-2">
            {extended?.addToBagIconPosition === 'left' && extended.addToBagIcon}
            <div>{!isOnStock ? 'SOLD OUT' : 'Add to Cart'}</div>
            {extended?.addToBagIconPosition === 'right' && extended.addToBagIcon}
          </div>
        </LinkButton>
      </div>
    </div>
  );
};
