import { useEffect, useState, useRef } from 'react';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { AddRemovePromo } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/shopping-carts/add-remove-promo';
import { cn } from '@ariessolutionsio/react-ecomm-ui/dist/utils/classNames';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './checkout-form';
import { useCart } from '@/frontastic/provider';

export const Payment = () => {
  const [elementOptions, setElementOptions] = useState(null);
  const cart = useCart() as any;
  const stripePromise = useRef<Stripe | null>(null);
  const totalPrice = cart.data?.taxedPrice?.totalGross || cart.data.sum;

  useEffect(() => {
    (async () => {
      stripePromise.current = await loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
      setElementOptions({
        stripe: stripePromise.current,
      });
    })();
  }, []);

  const hasImplicit = cart.data?.discountCodes?.some((discount) => discount.code?.toLocaleLowerCase() === 'implicit');
  const hasDiscount = cart.data?.discountCodes?.length > 0 && !hasImplicit;

  return (
    <div className="mt-8">
      <Typography tagName="h3" variant="h3" className="text-black ">
        Promo & Pay
      </Typography>
      <div className="mb-14 mt-12">
        <p className="mb-4 font-semibold lg:text-xl">Promotions</p>
        <div className={cn(hasDiscount && 'lg:w-2/6')}>
          <AddRemovePromo useCart={useCart as any} />
        </div>
        <p className="mt-2.5 text-xs text-gray-700">Max one promo code per order</p>
      </div>
      {elementOptions && (
        <Elements
          stripe={elementOptions.stripe}
          options={{
            mode: 'payment',
            amount: totalPrice.centAmount,
            currency: 'usd',
            externalPaymentMethodTypes:
              process.env.NEXT_STRIPE_ALLOW_PAYPALPAYMENT === 'true' ? ['external_paypal'] : [],
          }}
        >
          <CheckoutForm stripe={elementOptions.stripe} />
        </Elements>
      )}
    </div>
  );
};
