import { WithThreePanels7525 as WithThreePanels7525Original } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/hero-sections/hero-with-three-panels-7525';

export const WithThreePanels7525 = (props) => {
  const ctaLabelWeight = props.data?.labelBold ? 'font-bold' : 'font-normal';
  const ctaLabelItalic = props.data?.labelItalic ? 'italic' : 'not-italic';
  const ctaLabelFontSize = props.data?.labelFontSize ? `text-[${props.data?.labelFontSize}px]` : 'text-xs';

  return (
    <div className="marketing-components-container lg:py-8">
      <WithThreePanels7525Original
        {...props}
        extendedClasses={{ cta: `${ctaLabelWeight} ${ctaLabelItalic} ${ctaLabelFontSize}` }}
      />
    </div>
  );
};
