import { FC } from 'react';
import { WithProductRecommendations } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/hero-sections/with-product-recommendations';

export const CarouselWithProductRecommendations: FC<any> = (props) => {
  return (
    <div>
      <WithProductRecommendations
        data={{
          title: props.data.title,
          background: props.data.background,
          products: props.data.data.dataSource.items,
          arrows: props.data.data.dataSource.items.length > 4 ? true : false,
        }}
      />
    </div>
  );
};
