import { FC } from 'react';
import {
  CategoryLandingBanner,
  CategoryLandingBannerProps,
} from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/landing-sections/category-landing-banner';

interface PageFolder {
  dataSourceConfigurations: {
    preloadedValue: {
      imageBannerMobile?: string | null;
      imageBannerDesktop?: string | null;
    };
  }[];
}

export const CategoryLandingPageBanner: FC<CategoryLandingBannerProps & { pageFolder: PageFolder }> = ({
  data,
  pageFolder,
}) => {
  const preloadedValue = pageFolder.dataSourceConfigurations[0]?.preloadedValue ?? {};

  if (preloadedValue.imageBannerMobile || preloadedValue.imageBannerDesktop) {
    /*
      If either imageBannerMobile or imageBannerDesktop is present, 
      avoid rendering this component and instead render the 
      CategoryLandingPageBannerDynamic component from the 
      frontend/frontastic/tastics/category-landing/category-landing-banner-dynamic package.
    */
    return null;
  }

  const { title, description, background_desktop, background_mobile } = data ?? {};

  return (
    <div>
      <CategoryLandingBanner
        data={{
          title,
          description,
          background_desktop,
          background_mobile,
        }}
      />
    </div>
  );
};
