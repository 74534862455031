/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';
import { useRouter } from 'next/router';
import { Button } from '@ariessolutionsio/primitives-richproducts';
import { DisplayPrice } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/templates/DisplayPrice';
import { LineItem } from '@Types/cart/LineItem';
import { useAccount, useCart } from 'frontastic/provider';
import { CheckoutProvider } from 'frontastic/tastics/checkout/contexts/checkout.context';
import { OrderSummary } from 'frontastic/tastics/order/summary';
import { send_event_begin_checkout } from '../../checkout/components/gtm_helper';

const CartOrderSummaryTastic = (props) => {
  const cart = useCart() as any;
  const { loggedIn } = useAccount();
  const router = useRouter();

  if (!cart.data || !cart.data.lineItems || cart.data.lineItems.length === 0) {
    return <></>;
  }
  const { lineItems, discountCodes, orderId, taxed, shippingInfo } = cart.data;
  const estimatedSubtotal = lineItems.reduce((acc, item) => (acc += item.totalPrice.centAmount), 0);
  const discountAmount = discountCodes.reduce((acc, item) => (acc += item.discountedAmount?.centAmount), 0);
  const products = lineItems.map((product: LineItem) => {
    return {
      id: product.productId,
      name: product.name,
      price: product.price.centAmount,
      quantity: product.count,
    };
  });

  function BeginCheckout(e) {
    e.preventDefault();
    router.push('/checkout');

    send_event_begin_checkout(cart.data);
  }

  return (
    <>
      <CheckoutProvider
        states={[]}
        shippingMethods={''}
        shipSpeedData={[]}
        shippingInfo=""
        unavailabledDates={[]}
        beyondTodayDate={0}
        undatePattern={[]}
      >
        <div className="mt-7 w-full px-6 lg:mt-0 lg:px-10">
          <p className="pt-5 text-xl font-semibold lg:mt-[75px] lg:text-2xl">Order Summary</p>
          <OrderSummary cart={cart} showTotalPrice={false} />
          <p className="text-sm italic">{props.data?.message}</p>
          <hr className="my-4" />
          <div className="flex items-center justify-between">
            <span className="text-sm font-bold">Estimated Total:</span>
            <span className="text-2xl font-semibold lg:text-xl">
              <DisplayPrice
                price={cart?.data?.taxedPrice?.totalGross || cart?.data.sum}
                component={({ displayPrice }) => <>{displayPrice}</>}
              />
            </span>
          </div>

          <Button
            className="mt-4 inline-flex w-full text-xs font-bold uppercase tracking-wide text-white hover:bg-[#F1B53D]"
            onClick={BeginCheckout}
          >
            {loggedIn ? 'Checkout' : 'Guest Checkout'}
          </Button>
        </div>
      </CheckoutProvider>
    </>
  );
};

export default CartOrderSummaryTastic;
