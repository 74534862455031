import { FC } from 'react';
import { Button } from '@ariessolutionsio/primitives-richproducts';
import { Address } from '@ariessolutionsio/providers-richproducts';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { PencilIcon } from '@heroicons/react/24/solid';
import { Incrementer } from './incrementer';

interface RecipientTableMobileProps {
  addresses: any[];
  onSingleSelect?: (address: Address) => void;
  onClose: () => void;
  allowIncrement?: boolean;
  editCallback: any;
  onChangeSelectable?: (address: Address, checked: boolean) => void;
  onSelectedItemInMultiSelect?: (address: Address, value: number) => void;
}

export const RecipientTableMobile: FC<RecipientTableMobileProps> = ({
  addresses,
  onSingleSelect,
  onClose,
  allowIncrement,
  editCallback,
  onChangeSelectable,
  onSelectedItemInMultiSelect,
}) => {
  return (
    <div>
      <div className="space-x-2 bg-[#F3EBE0] p-2">
        <Typography variant="body" className="font-bold">
          Sort By:
        </Typography>
        <Typography variant="body">Last Name</Typography>
      </div>
      <div className="">
        <div className=" h-[350px] overflow-y-auto">
          {addresses.map((address, index) => (
            <div className="px-2 py-3 text-[14px]" key={`${address.id} ${index} receipients`}>
              <div className="flex justify-between">
                <div>
                  <div className="font-bold">
                    {address.firstName} {address.lastName}
                  </div>
                  <div>{`${address.streetName}, ${address.city} ${address.state} ${address.postalCode}`}</div>
                </div>
                {onSingleSelect && (
                  <div>
                    <div
                      onClick={() => {
                        onSingleSelect(address);
                        onClose();
                      }}
                    >
                      <Icon />
                    </div>
                  </div>
                )}
                {!onSingleSelect && (
                  <div>
                    <input
                      type="checkbox"
                      checked={address.isSelectable}
                      key={`${address.id}`}
                      onChange={(e) => onChangeSelectable(address, e.target.checked)}
                    />
                  </div>
                )}
              </div>

              <div className="flex justify-between">
                <div
                  onClick={() => editCallback.open({ addressId: address.id })}
                  className="flex space-x-1 pt-2 text-red-700"
                >
                  <PencilIcon className="h-5 w-5" />
                  <div>Edit</div>
                </div>

                {!onSingleSelect && address.isSelectable && (
                  <div className="flex w-20 justify-center">
                    <Incrementer
                      initialValue={address.quantitySelected}
                      allowIncrement={allowIncrement}
                      onChange={(value) => onSelectedItemInMultiSelect(address, value)}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-6 w-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5"
      />
    </svg>
  );
};
