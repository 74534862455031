// @ts-nocheck
import { FC, useEffect, useMemo, useState } from 'react';
import { Checkbox, Form, Input } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { useForm } from 'react-hook-form';
import { useCart } from 'frontastic/provider';
import { ProductTiles } from './product-tiles';
import { useCheckoutContext } from '../../../contexts/checkout.context';
import useCheckoutLSData from '../../../hooks/useCheckoutLSData';

interface GiftSingleMessageProps {
  show?: boolean;
}

export const GiftSingleMessage: FC<GiftSingleMessageProps> = ({ show }) => {
  const [{ checkoutData }, { nextStep, prevStep, setCheckoutData }] = useCheckoutContext();
  const [processing, setProcessing] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const { checkoutSLData, updateCheckoutData } = useCheckoutLSData();

  const emojisSymbolsRanges = /[^a-zA-Z0-9!@#$%^&*()-=+<>~,.? ]/g;

  const [errorMessage, setErrorMessage] = useState('');

  const validateInput = (e) => {
    const { value } = e.target;
    const sanitizedValue = removeEmojis(value);
    if (value !== sanitizedValue) {
      e.target.value = sanitizedValue;
    }
    if (emojisSymbolsRanges.test(value)) {
      setErrorMessage(
        'Only letters, numbers, and the following symbols are permitted for this field: !@#$%^&*()-=+<>~,.?',
      );
    } else {
      setErrorMessage('');
    }
  };

  const removeEmojis = (text) => {
    return text.replace(emojisSymbolsRanges, '');
  };

  const cart = useCart();
  const { data: order } = cart;

  useEffect(() => {
    if (!show) {
      reset({
        giftMessage1: '',
        giftMessage2: '',
        giftMessage3: '',
        giftMessage4: '',
        giftMessage5: '',
      });
    }
  }, [show, reset]);

  useMemo(() => {
    if (checkoutSLData?.giftMessages) {
      setTimeout(() => {
        reset(checkoutSLData.giftMessages);
        // setShow((checkoutSLData as any).giftMessageCheckbox?.show);
      }, 100);
    }
  }, [checkoutSLData, reset]);

  const getGiftMessage = (values) => {
    return Object.values(values)
      .filter(Boolean)
      .filter((item: string) => item.trim() !== '')
      .map((item: string) => item.trim())
      .join('|');
  };

  const submitGiftForm = async (values) => {
    setProcessing(true);
    const giftMessage = getGiftMessage(values).trim();
    const _addressInCart = cart.data.shippingAddress.find(
      (a) => a.externalId === checkoutData?.shipping.shipping.addressKey,
    );
    try {
      const shippingPayload = {
        addressKey: _addressInCart.addressId,
        shippingMethod: (checkoutData as any)?.shipping?.shipSpeed?.id,
        giftMessage: giftMessage ?? '',
        shipByDate: (checkoutData as any)?.shipping?.shippingDate?.startDate?.split('T')[0],
      } as any;
      updateCheckoutData({
        ...checkoutSLData,
        giftMessages: show
          ? {
              ...values,
            }
          : {},
        giftMessageCheckbox: {
          show,
        },
      });

      setCheckoutData({
        ...checkoutData,
        shipping: {
          ...checkoutData.shipping,
          giftMessageCheckbox: {
            show,
          },
          giftMessages: show
            ? {
                ...values,
              }
            : {},
        },
      });

      await cart.setCartShippingMethod(shippingPayload);
      nextStep();
    } catch (e) {
      setProcessing(false);
    }
  };

  return (
    <div className="pb-4">
      <ProductTiles />
      {order && order.lineItems.length > 0 && (
        <>
          <Form className="relative mt-4" onSubmit={handleSubmit(submitGiftForm)} id="gift-message-prev-next-btns">
            {show && (
              <div className="rounded-lg border border-[#B4B2B7] lg:p-2.5 lg:px-10 lg:py-8">
                <div className="mb-4 p-2.5 pb-0 lg:px-0">
                  <p className="font-semibold">My Gift Message for</p>
                  <p>{`${checkoutData.shipping.shipping.firstName} ${checkoutData.shipping.shipping.lastName}`}</p>
                </div>
                <Form.Section>
                  <div className="gift-message-card-bg bg-norepeat bg-center pb-8 pt-24">
                    <div className="mx-auto w-[85%] space-y-2">
                      <Form.Control name="giftMessage1">
                        <Input
                          placeholder="Add your message"
                          {...register('giftMessage1')}
                          maxLength={40}
                          onChange={(e) => validateInput(e)}
                        />
                      </Form.Control>
                      <Form.Control name="giftMessage2">
                        <Input {...register('giftMessage2')} maxLength={40} onChange={(e) => validateInput(e)} />
                      </Form.Control>
                      <Form.Control name="giftMessage3">
                        <Input {...register('giftMessage3')} maxLength={40} onChange={(e) => validateInput(e)} />
                      </Form.Control>
                      <Form.Control name="giftMessage4">
                        <Input {...register('giftMessage4')} maxLength={40} onChange={(e) => validateInput(e)} />
                      </Form.Control>
                      <Form.Control name="giftMessage5">
                        <Input {...register('giftMessage5')} maxLength={40} onChange={(e) => validateInput(e)} />
                      </Form.Control>
                      <p className="mt-4 text-xs">Total 200 character limit. 40 character limit on each row.</p>
                      {errorMessage && <p className="mt-4 text-xs text-primary-800">{errorMessage}</p>}
                    </div>
                  </div>
                </Form.Section>
              </div>
            )}
            <nav className="mt-8 flex w-full justify-end space-x-4">
              <Button variant="text" onClick={prevStep} type="button" palette="none" className="font-semibold">
                Previous
              </Button>
              <Button type="submit" disabled={processing}>
                {processing ? 'Loading...' : 'Next'}
              </Button>
            </nav>
          </Form>
        </>
      )}
    </div>
  );
};
