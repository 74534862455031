import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import QuestionMarkIcon from '../../../../../components/icons/question-mark';

export const ShippingInformation = () => {
  return (
    <div className="mt-8 md:flex">
      <div className=" border-t border-gray-300 md:ml-4 md:h-full md:border-l"></div>
      <div className="mt-8 flex flex-col justify-center space-y-1 md:mt-4 md:px-4">
        <QuestionMarkIcon />
        <Typography variant="body" tagName="p" className="mt-2 font-semibold">
          How do we use this info?
        </Typography>

        <ul className="ml-4">
          <li className="mt-2 list-disc">
            <Typography variant="menu" className="">
              Email is for your order confirmation
            </Typography>
          </li>
          <li className="mt-2  list-disc">
            <Typography variant="menu" className="">
              Phone number will only be used for delivery questions
            </Typography>
          </li>
        </ul>
      </div>
    </div>
  );
};
