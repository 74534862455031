import React from 'react';

type HelloWorldTasticProps = {
  data: any;
};

const HelloWorldTastic: React.FC<HelloWorldTasticProps> = ({ data }) => {
  return <h1>Data available: {JSON.stringify(data, null, 2)}</h1>;
};

export default HelloWorldTastic;
