import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import Sidemenu from '../side-menu';

const PaymentMethodsTastic: React.FC<{}> = () => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const contentTitle = formatAccountMessage({ id: 'payment_methods', defaultMessage: 'Payment Methods' });

  return (
    <>
      <Sidemenu hash={'/my-account/payment-methods'} contentTitle={contentTitle}>
        {'WIP: Payment Methods section here'}
      </Sidemenu>
    </>
  );
};

export default PaymentMethodsTastic;
