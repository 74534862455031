import { FC, useState } from 'react';
import { Button, Dialog, useDialogContext, useDialog, useToast } from '@ariessolutionsio/primitives-richproducts';
import { Checkbox, Form, Input } from '@ariessolutionsio/react-ecomm-ui/dist';
import { useErrorDialog } from 'hooks/dialogs/error-gift-message';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { useCart } from 'frontastic/provider';
import { useCheckoutContext } from 'frontastic/tastics/checkout/contexts/checkout.context';
import { OrderDeliveryItemGiftMessage } from './order-delivery-item';

interface GiftMessageMultiShippingProps {
  show?: boolean;
}
export const GiftMessageMultiShipping: FC<GiftMessageMultiShippingProps> = ({ show }) => {
  const [{ orderDelivery, checkoutData }, { nextStep, prevStep, setCheckoutData }] = useCheckoutContext();
  const [processing, setProcessing] = useState(false);
  const form = useForm();
  const cart = useCart();
  const { toast } = useToast();

  const ErrorDialog = useErrorDialog();

  const onNext = async () => {
    try {
      setProcessing(true);
      let finalList = [];

      for (const i in orderDelivery) {
        const orderItem = orderDelivery[i];
        for (const j in orderItem.shippingPlanning) {
          const sp = orderItem.shippingPlanning[j];
          finalList = [
            ...finalList,
            {
              addressKey: sp.address.key,
              shippingMethod: sp.shippingMethod,
              shipByDate: sp.shippingDate,
              quantity: sp.quantity,
              itemId: orderItem.lineItem.lineItemId,
              giftMessage: [
                sp.giftMessageLine1,
                sp.giftMessageLine2,
                sp.giftMessageLine3,
                sp.giftMessageLine4,
                sp.giftMessageLine5,
              ]
                .filter((line) => line)
                .join('|'),
            },
          ];
        }
      }
      await cart.setMultiShippingMethod(finalList);
      nextStep();
    } catch (err) {
      setProcessing(false);
      toast({
        title: 'Error',
        description: err.message,
      });
    }
  };

  return (
    <div>
      {show &&
        orderDelivery.map((item, index) => (
          <OrderDeliveryItemGiftMessage
            orderItem={item}
            key={item.lineItem.lineItemId}
            indexOrderItem={index}
            form={form}
          />
        ))}
      <nav className="mt-8 flex w-full justify-end space-x-4">
        <Button onClick={prevStep} className="bg-transparent font-semibold uppercase">
          Previous
        </Button>
        <Button loading={processing} onClick={onNext} className="uppercase text-white">
          Next
        </Button>
      </nav>
    </div>
  );
};
