/* eslint-disable tailwindcss/no-custom-classname */
import { FC, useEffect } from 'react';
import { UIProduct } from '../../../../components/commercetools-ui/products/product-details';

interface ProductReviewSummaryProps {
  powerReviewsPageId: string;
  product: UIProduct;
}
export const ProductReviewSummary: FC<ProductReviewSummaryProps> = ({ powerReviewsPageId, product }) => {
  useEffect(() => {
    const productVariants = product.variants.map((variant) => {
      return {
        name: product?.name,
        image_url: variant?.images[0],
        upc: '',
        page_id_variant: variant?.sku,
        manufacturer_id: '',
        description: product?.description,
      };
    });
    window['pwr_product_data'] = {
      page_id: powerReviewsPageId,
      product: {
        name: product?.name,
        url: window?.location?.href,
        image_url: product?.variants[0]?.images[0],
        description: product?.description,
        category_name: product?.categories[0]?.name,
        brand_name: 'Christie Cookies',
        price: product?.variants[0]?.price?.centAmount / 100,
        in_stock: '1',
        variants: productVariants,
      },
      subject: {
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: product?.name,
        description: product?.description,
        url: window?.location?.href,
        image: product?.variants[0]?.images[0],
        sku: product?.variants[0]?.sku,
        brand: '',
        category: product?.categories[0]?.name,
        weight: '',
        offers: {
          '@type': 'Offer',
          priceCurrency: 'USD',
          url: window?.location?.href,
          price: product?.variants[0]?.price?.centAmount / 100,
          priceValidUntil: '',
          availability: 'https://schema.org/InStock',
        },
      },
    };
  }, [product, powerReviewsPageId]);

  return <div id="pr-review-snippet" className="pr-review-snippet-styles"></div>;
};
