import { MutableRefObject, ReactElement, useState } from 'react';
import { Form, Input } from '@ariessolutionsio/react-ecomm-ui/dist';
import { useFormContext } from 'react-hook-form';
import { GiftMessage } from '../components/gift-message';
import { Payment } from '../components/payment';
import { ReviewAndSummary } from '../components/review-summary';
import { ShippingDetails } from '../components/shipping';

const TestForm = () => {
  const form = useFormContext();

  return (
    <Form.Section title="Test">
      <Form.Control name="city">
        <Input {...form.register('shipping.contactInfo.email')} />
      </Form.Control>
    </Form.Section>
  );
};

export type CheckoutStepId = 'shipping' | 'gift' | 'payment' | 'review';

export type CheckoutStep = {
  id: CheckoutStepId;
  label: string;
  component: ReactElement;
};

const steps: CheckoutStep[] = [
  {
    id: 'shipping',
    label: 'Shipping',
    component: <ShippingDetails />,
  },
  {
    id: 'gift',
    label: 'Gift Message',
    component: <GiftMessage />,
  },
  {
    id: 'payment',
    label: 'Promo & Pay',
    component: <Payment />,
  },
  {
    id: 'review',
    label: 'Review & Submit',
    component: <ReviewAndSummary />,
  },
];

export const useCheckoutSteps = (carouselRef: MutableRefObject<any>) => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const nextStep = () => {
    if (!Boolean(currentStep >= steps.length - 1) && !Boolean(currentStep < 0)) {
      setCurrentStep((prevValue) => prevValue + 1);
    }
  };

  const prevStep = () => {
    if (currentStep === 0) {
      window.location.href = '/cart';
      return;
    }
    if (!Boolean(currentStep <= 0) && !Boolean(currentStep > steps.length)) {
      setCurrentStep((prevValue) => prevValue - 1);
    }
  };

  return {
    steps,
    currentStep,
    setCurrentStep,
    nextStep,
    prevStep,
  };
};
