// @ts-nocheck
import { Checkbox } from '@ariessolutionsio/primitives-richproducts';
import { Address, Customer } from '@ariessolutionsio/providers-richproducts';
import { Text } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { useAccount } from 'frontastic/provider';
import { ShippingForm } from 'frontastic/tastics/checkout/schemas/shipping.schema';
import { useRecipientsBookDialog } from '../../../../../../hooks/dialogs/recipients-book/recipients-book';
import { useCheckoutContext } from '../../../contexts/checkout.context';

interface AddressBoxProps {
  checkboxToggle: () => void;
  chooseDefaultBillingAddress: boolean;
  defaultBillingAddress: Address;
  setDefaultBillingAddress: React.Dispatch<React.SetStateAction<Address | any | undefined>>;
  meData: andy | Customer;
}

export const AddresBox = ({
  checkboxToggle,
  chooseDefaultBillingAddress,
  defaultBillingAddress,
  setDefaultBillingAddress,
  meData,
}: AddressBoxProps) => {
  const [{ sameAddress, checkoutData, provinces }, { setSameAddress }] = useCheckoutContext();
  const { loggedIn } = useAccount();

  const RecipientsBookDialog = useRecipientsBookDialog({
    title: 'Select Recipients',
    states: provinces,
    onSingleSelect: (address) => {
      setDefaultBillingAddress({ ...address });
    },
  });

  const showBillingAddressToggle: boolean = loggedIn && !!meData && !!defaultBillingAddress;
  const showShippingAddressToggle: boolean =
    (loggedIn && meData && !chooseDefaultBillingAddress) ||
    !loggedIn ||
    (loggedIn && defaultBillingAddress === undefined);
  return (
    <>
      <div className="space-y-[22px]">
        <div className="font-semibold lg:text-xl">Billing Address</div>
        {showBillingAddressToggle && (
          <div className="my-7 flex h-auto flex-wrap justify-between p-4 pl-0 align-middle">
            <div className="flex gap-4">
              <Checkbox
                id="default-billing-address"
                checked={chooseDefaultBillingAddress}
                onClick={() => checkboxToggle()}
                className="mt-1"
              />
              <div>
                <p className="font-bold">{`${defaultBillingAddress?.firstName} ${
                  defaultBillingAddress?.lastName ?? ''
                }`}</p>
                <p>{`${defaultBillingAddress?.company ?? ''} `}</p>
                <p>{`${defaultBillingAddress?.streetNumber ?? ''} ${defaultBillingAddress?.streetName}`}</p>
                <p>{`${defaultBillingAddress?.additionalStreetInfo ?? ''} `}</p>
                <p>{`${defaultBillingAddress?.city}, ${defaultBillingAddress?.state} ${
                  defaultBillingAddress?.postalCode ?? defaultBillingAddress?.zipCode
                }`}</p>
              </div>
            </div>

            <Button
              type="button"
              variant="text"
              onClick={() => RecipientsBookDialog.open()}
              className="mx-7 my-4 h-[48px] bg-black-100 px-4 py-2 text-xs font-semibold uppercase text-white lg:mx-3"
            >
              Add/Edit Address
            </Button>
          </div>
        )}
        {showShippingAddressToggle && (
          <>
            <div className="flex-col items-center justify-between rounded-md p-4 pl-0 lg:py-[30px] lg:pr-[18px]">
              <div className="mb-4 flex items-center justify-start space-x-3">
                <Checkbox
                  checked={sameAddress}
                  onClick={() => setSameAddress((prevValue) => !prevValue)}
                  id="same-billing-address"
                />
                <div className="flex flex-col">
                  <label htmlFor="same-billing-address" className="text-black font-semibold">
                    My billing and my shipping address are the same
                  </label>
                </div>
              </div>
              {checkoutData.shipping.shipping && sameAddress ? (
                <div className="pl-8">
                  <Text className="text-base font-bold text-[#3D4352]">{`${
                    (checkoutData.shipping.shipping as ShippingForm).firstName
                  } ${(checkoutData.shipping.shipping as ShippingForm).lastName}`}</Text>
                  <Text className="text-base text-[#3D4352]">
                    {(checkoutData.shipping.shipping as any).streetAddress as any}
                  </Text>
                  <Text className="text-base text-[#3D4352]">{`${
                    (checkoutData.shipping.shipping as ShippingForm).city
                  }, ${(checkoutData.shipping.shipping as ShippingForm).state} ${
                    (checkoutData.shipping.shipping as ShippingForm).zipCode
                  }`}</Text>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </>
  );
};
