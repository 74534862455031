// @ts-nocheck

import { FC } from 'react';
import dynamic from 'next/dynamic';
const Markdown = dynamic(() => import('@ariessolutionsio/primitives-richproducts').then((mod) => mod.Markdown), {
  ssr: false,
});

export const MarkDownTastic: FC = ({ data }: any) => {
  const _markdown = data.markdown;
  return (
    <div className=" prose w-full px-4 lg:prose-xl prose-h1:pt-16 prose-h2:pt-12 prose-h3:pt-8 prose-p:text-justify lg:px-8">
      <Markdown className="w-full">{_markdown}</Markdown>
    </div>
  );
};
