/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable tailwindcss/no-arbitrary-value */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { DisplayPrice } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/templates/DisplayPrice';
import { CreditCardIcon } from '@heroicons/react/24/solid';
import DownloadDocIcon from 'components/icons/download-doc';
import { getOrderByNumber } from 'frontastic/actions/order';
import Image from 'frontastic/lib/image';
//import mockOrderDetails from './mockOrderDetail.json';

interface orderDetailProps {
  detailInfo: { orderData: unknown; show: boolean };
}

const OrderDetails: React.FC<orderDetailProps> = ({ detailInfo }) => {
  const [orderData, setOrderData] = useState<any>([]);
  const orderNumber = detailInfo?.orderData?.['orderNumber'];

  useEffect(() => {
    getOrderByNumber(orderNumber as string)
      .then((result) => {
        setOrderData(result);
      })
      .catch((error) => {});
  }, [orderNumber]);

  const { lineItems, taxedPrice, sum, payments, billingAddress } = orderData;

  let { shippingInfo } = orderData;

  shippingInfo = shippingInfo?.map((item) => {
    return {
      ...item,
      lineItemData: lineItems?.filter((lItem: any) =>
        lItem?.shippingDetails?.targets?.find((lineItem: any) => lineItem?.shippingMethodKey == item?.shippingKey),
      ),
    };
  });

  const getSubTotal = () => {
    return lineItems?.reduce((a, b: any) => a + b?.variant?.price?.centAmount * b?.count, 0);
  };

  const getShippingTotal = () => {
    return shippingInfo?.reduce((a, b: any) => a + b?.shippingInfo?.price?.centAmount, 0);
  };

  const getTrackingLink = (deliveries: any) => {
    const trackingData = deliveries?.[0]?.parcels?.[0]?.trackingData;
    const carrier = trackingData?.carrier?.toLowerCase();
    const trackingId = trackingData?.trackingId;
    let trackingLink = '';
    switch (carrier) {
      case 'usps':
        trackingLink = `https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${trackingId}`;
        break;
      case 'ups':
        trackingLink = `https://www.ups.com/mobile/track?trackingNumber=${trackingId}`;
        break;
      case 'fedex':
        trackingLink = `https://www.fedex.com/fedextrack/?trknbr=${trackingId}`;
        break;
      default:
        trackingLink = `https://www.fedex.com/fedextrack/?trknbr=${trackingId}`;
        break;
    }
    return trackingLink;
  };

  return (
    <div className="mt-8 h-auto px-4 pb-[40px] pt-[10px] lg:ml-32 lg:w-[1190px] lg:pl-[40px]">
      <div className="items-center justify-between lg:flex">
        <div>
          <h5 className="text-[20px] font-normal md:text-[24px]">
            {new Date(orderData?.createdAt)?.toLocaleDateString() ?? 'NA'}
          </h5>
          <h1 className="mt-4 text-[32px] font-bold md:text-[65px]">Order #{orderData?.orderId ?? 'NA'}</h1>
          <h5 className="mt-4 text-[20px]  font-normal md:text-[24px]">Status: {orderData?.orderState ?? 'NA'} </h5>
        </div>
        <div>
          <button className="border-black mt-4 flex w-full justify-center rounded-lg border px-[28px] py-[13px] align-middle">
            {' '}
            <div className="flex">
              <DownloadDocIcon />{' '}
              <span
                className="ml-1 font-galanoClassic text-[12px] font-bold uppercase"
                onClick={() => {
                  window.print();
                }}
              >
                Download Invoice
              </span>
            </div>
          </button>
        </div>
      </div>
      <div className="mt-8 h-auto rounded-lg border border-[#b4b2b7] bg-white px-8 py-[32px] lg:w-[1190px] lg:px-[0px] lg:py-[64px]">
        <div className="h-auto justify-between sm:ml-2 lg:ml-24 lg:flex lg:w-[1001px]">
          <div className="h-auto lg:w-[305px]">
            <span className="text-xl font-semibold"> Order Summary </span>
            <hr className="mt-5 w-[275px]"></hr>
            {lineItems?.map((item) => (
              <div key={item?.lineItemId} className="mt-4 flex h-auto w-[275px]">
                <div className="mr-4 h-8 w-8">
                  <Image className="h-8 w-8" alt="line-item-image" src={item?.variant?.images[0]} />
                </div>
                <div className="mr-4 h-auto w-40">
                  <div className="text-sm">{item?.name ?? 'NA'}</div>
                  <div className="text-sm">
                    Quantity: <span className="font-semibold">{item?.count ?? 'NA'}</span>
                  </div>
                  {item?.variant?.attributes?.assortment?.label ? (
                    <div className="text-sm">
                      Assortment:{' '}
                      <span className="font-semibold">{item?.variant?.attributes?.assortment?.label ?? ''}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {item?.variant?.attributes?.tin_color?.label ? (
                    <div className="text-sm">
                      Tin: <span className="font-semibold">{item?.variant?.attributes?.tin_color?.label ?? ''}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="h-12 w-12 font-semibold">
                  {' '}
                  <DisplayPrice price={item?.price} component={({ displayPrice }) => <span>{displayPrice}</span>} />
                </div>
              </div>
            ))}
            <hr className="mt-5 w-[275px]"></hr>
            <div className="mt-5  flex w-[275px]">
              <div className="w-60">
                <div className="mt-2">Item Subtotal:</div>
                <div className="mt-2">Shipping:</div>
                {orderData?.discountedCodes?.[0]?.code ? <div className="mt-2">Discounts:</div> : <></>}
              </div>
              <div className="w-12">
                <div className="mt-2">
                  <span>
                    <DisplayPrice
                      price={{
                        centAmount: getSubTotal(),
                        currencyCode: 'USD',
                        fractionDigits: 2,
                      }}
                      component={({ displayPrice }) => <span>{displayPrice}</span>}
                    />
                  </span>
                </div>
                <div className="mt-2">
                  <span>
                    <DisplayPrice
                      price={{
                        centAmount: getShippingTotal(),
                        currencyCode: 'USD',
                        fractionDigits: 2,
                      }}
                      component={({ displayPrice }) => <span>{displayPrice}</span>}
                    />
                  </span>
                </div>
                {orderData?.discountedCodes?.[0]?.code ? (
                  <div className="mt-2">
                    <span>
                      <DisplayPrice
                        price={{
                          centAmount: orderData?.discountCodes[0]?.discountedAmount?.centAmount,
                          currencyCode: 'USD',
                          fractionDigits: 2,
                        }}
                        component={(displayPrice) => <div>- {displayPrice.displayPrice}</div>}
                      />
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <hr className="mt-5 w-[275px]"></hr>
            <div className="mt-5 flex w-[275px] font-bold">
              <div className="w-60">Total Price:</div>
              <div className="w-12">
                <span>
                  <DisplayPrice
                    price={taxedPrice?.totalGross || sum}
                    component={(displayPrice) => <div>{displayPrice.displayPrice}</div>}
                  />
                </span>
              </div>
            </div>
            <hr className="mt-5 w-[275px]"></hr>
            <div className="mt-5 flex w-[275px] font-bold">
              <div className="w-60">Balance Paid: </div>
              <div className="w-12">
                <span>
                  <DisplayPrice
                    price={payments?.[0]?.amountPlanned}
                    component={({ displayPrice }) => <span>{displayPrice}</span>}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="h-auto w-64">
            <div className="mt-8 md:mt-0">
              <span className="text-xl font-semibold"> Payment Type </span>

              <span className="mt-8 flex text-base">
                {' '}
                <CreditCardIcon className="mr-2 h-6 w-5" />{' '}
                <span>**** **** **** {payments?.[0]?.paymentType ?? 'NA'} </span>
              </span>
            </div>
            <div className="mt-8">
              <span className="mt-8 text-xl font-semibold"> Billing Address </span>

              <div className="mt-4 text-base">
                <div>
                  {billingAddress?.firstName ?? 'NA'} {billingAddress?.lastName ?? 'NA'}{' '}
                </div>
                <div>{billingAddress?.streetName ?? 'NA'}</div>
                <div>
                  {billingAddress?.city ?? 'NA'}, {billingAddress?.state ?? 'NA'}
                </div>
                <div>{billingAddress?.postalCode ?? 'NA'}</div>
              </div>
            </div>
          </div>
          <div className="h-24 w-64">
            {' '}
            <div className="mt-4 text-xl font-semibold lg:mt-0"> Shipping Contact</div>
            <div className="mt-4 text-base">{shippingInfo?.[0]?.shippingInfo?.shippingContact?.email ?? 'NA'}</div>
            <div className="mt-1 text-base">{shippingInfo?.[0]?.shippingInfo?.shippingContact?.phone ?? 'NA'}</div>
          </div>
        </div>
      </div>
      <h3 className="mt-8 text-4xl font-semibold">
        {' '}
        {shippingInfo && shippingInfo?.length > 1 ? 'Multi' : ''} Shipment Summary{' '}
      </h3>
      {shippingInfo &&
        shippingInfo?.length > 0 &&
        shippingInfo?.map((shipment) => (
          <div
            key={shipment?.shippingKey}
            className="mt-8 rounded-lg border border-solid border-[#b4b2b7] p-8 lg:w-[1190px]"
          >
            <div className="ml-4 h-auto justify-between lg:ml-24 lg:flex lg:w-[1001px]">
              <div className="h-auto w-[305px]">
                {shipment?.lineItemData?.map((item) => (
                  <div key={item?.lineItemId} className="mb-4 flex h-auto w-[275px]">
                    <div className="mr-4 h-[44px] w-[44px] md:h-24 md:w-24">
                      <Image
                        className="h-[44px] w-[44px] md:h-24 md:w-24"
                        alt="line-item-image"
                        src={item?.variant?.images?.[0]}
                      />
                    </div>
                    <div className="mr-4 h-auto w-40">
                      <div className="text-sm">{item?.name}</div>
                      <div className="text-sm">
                        Quantity: <span className="font-semibold">{item?.count}</span>
                      </div>
                      {item?.variant?.attributes?.assortment?.label ? (
                        <div className="text-sm">
                          Assortment:{' '}
                          <span className="font-semibold">{item?.variant?.attributes?.assortment?.label ?? ''}</span>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item?.variant?.attributes?.tin_color?.label ? (
                        <div className="text-sm">
                          Tin:{' '}
                          <span className="font-semibold">{item?.variant?.attributes?.tin_color?.label ?? ''}</span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-8 h-auto w-64 md:mt-0">
                <div>
                  <div className="text-xl font-semibold"> Shipping Address </div>

                  <div className="mt-5">
                    {' '}
                    {shipment?.shippingAddress?.firstName ?? 'NA'} {shipment?.shippingAddress?.lastName ?? 'NA'}
                  </div>
                  <div className="mt-1"> {shipment?.shippingAddress?.streetName ?? 'NA'} </div>
                  <div className="mt-1">
                    {' '}
                    {shipment?.shippingAddress?.city ?? 'NA'}, {shipment?.shippingAddress?.state ?? 'NA'}{' '}
                    {shipment?.shippingAddress?.postalCode ?? 'NA'}
                  </div>
                </div>
                <div className="mt-8">
                  <div className="mt-8 text-base font-semibold"> Ship Method </div>

                  <div className="mt-2 text-base font-normal">{shipment?.shippingInfo?.shippingMethodName ?? 'NA'}</div>
                </div>
                <div className="mt-8">
                  <div className="mt-8 text-base font-semibold"> Shipped On </div>

                  <div className="mt-2 text-base font-normal">{shipment?.shippingInfo?.shippingDate ?? 'NA'}</div>
                </div>
                <div className="mt-8">
                  <div className="mt-8 text-base font-semibold"> Tracking # </div>

                  <div className="mt-2 text-base font-normal text-red-700 underline">
                    <a
                      className="cursor-pointer"
                      rel="noreferrer"
                      href={getTrackingLink(shipment?.shippingInfo?.deliveries)}
                      target="_blank"
                    >
                      {shipment?.shippingInfo?.deliveries?.[0]?.parcels?.[0]?.trackingData?.trackingId ?? 'NA'}
                    </a>
                  </div>
                </div>
              </div>
              <div className="h-auto w-64">
                {' '}
                <div className="mt-4 text-xl font-semibold lg:mt-0"> Gift Message</div>
                <div className="mt-4 text-base">{shipment?.shippingInfo?.giftMessage ?? 'NA'}</div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default OrderDetails;
