import { useState } from 'react';

export interface InputCheckboxProps {
  label: string;
  id: string;
  name: string;
  value: string;
  className?: string;
  error?: string;
  inputClass?: string;
  errorClass?: string;
  handleOnChange?: (e) => void;
  register?: any;
  restProps?: any;
}

export interface InputProps {
  label: string;
  id: string;
  className?: string;
  error?: string;
  inputClass?: string;
  errorClass?: string;
  register?: any;
  restProps?: any;
}
export const InputCheckBox = ({
  label,
  id,
  name,
  value,
  inputClass,
  register,
  handleOnChange,
  restProps,
}: InputCheckboxProps) => {
  const [ischecked, setChecked] = useState(false);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    setChecked(!ischecked);
    const data = {
      value,
      name,
      checked,
    };

    handleOnChange && handleOnChange(data);
  };
  return (
    <div className="flex">
      <input
        id={id}
        name={name}
        value={value}
        type="checkbox"
        onChange={handleChange}
        checked={ischecked}
        className={`mx-2 h-5 w-5 rounded-sm border-gray-300 p-3 focus:ring-accent-500 ${inputClass}`}
        {...register}
        {...restProps}
      />
      <label>{label}</label>
    </div>
  );
};

export const Input = ({ label, id, className, error, inputClass, errorClass, register, restProps }: InputProps) => {
  return (
    <div>
      <div className={`flex items-center ${className}`}>
        <label className="font-bold" id={id}>
          {label}
        </label>
        <input
          className={`border-black ml-2 rounded-md border-2 px-4 py-1 ${inputClass}`}
          {...register}
          {...restProps}
          autoFocus
        />
      </div>
      {error && <span className={`text-sm text-red-600/100 ${errorClass}`}>{error}</span>}
    </div>
  );
};
