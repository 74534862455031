import SimpleHeadlineBlock from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/headline-blocks/simple-headline-block';

function HeadlineBlockTastic({ data }) {
  let args = {
    containerStyle: 'px-4 py-4 md:px-16 bg-[#D5d5d5]',
    eyebrowStyle: 'text-[32px] lg:text-[40px] font-normal text-primary-500 pb-8 text-[#B7002A]',
    titleStyle: 'font-bold text-[54px] md:text-[65px] lg:text-[65px]',
    descriptionStyle: 'font-normal text-[16px] leading-[25.6px] pt-8 font-medium',
  };

  switch (data?.theme) {
    case 'double_tree':
      {
        args = {
          containerStyle: 'px-4 py-4 md:px-16 bg-[#D5d5d5]',
          eyebrowStyle: 'text-[32px] lg:text-[40px] font-normal pb-8 text-white',
          titleStyle: 'font-bold text-[54px] md:text-[65px] lg:text-[65px] !text-[#A3CD39] font-[Rockwell]',
          descriptionStyle: 'font-normal text-[16px] leading-[25.6px] pt-8 font-medium !text-white font-proximaNova',
        };
      }

      break;
    case 'christie_cookies':
      {
        args = {
          containerStyle: 'px-4 py-4 md:px-16 bg-[#D5d5d5]',
          eyebrowStyle: 'text-[32px] lg:text-[40px] font-normal text-primary-500 pb-3 text-[#B7002A]',
          titleStyle: 'font-bold text-[54px] md:text-[65px] lg:text-[65px]',
          descriptionStyle: 'font-normal text-[16px] leading-[25.6px] pt-3 font-medium',
        };
      }
      break;
    default:
      args = {
        containerStyle: 'px-4 py-4 md:px-16 bg-[#D5d5d5]',
        eyebrowStyle: 'text-[32px] lg:text-[40px] font-normal text-primary-500 pb-8 text-[#B7002A]',
        titleStyle: 'font-bold text-[54px] md:text-[65px] lg:text-[65px]',
        descriptionStyle: 'font-normal text-[16px] leading-[25.6px] pt-8 font-medium',
      };
      break;
  }

  return (
    <SimpleHeadlineBlock
      data={{ ...data }}
      extendedClasses={{
        container: 'px-4 py-4 md:px-16',
        title: args.titleStyle,
        description: args.descriptionStyle,
        eyebrow: args.eyebrowStyle,
      }}
    />
  );
}

export default HeadlineBlockTastic;
