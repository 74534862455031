import { FC } from 'react';
import { AddressForm } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/forms/address';

interface SingleShippingAddressFormProps {
  form: any;
  provinces: any[];
}

export const SingleShippingAddressForm: FC<SingleShippingAddressFormProps> = ({ form, provinces }) => {
  return (
    <>
      <AddressForm form={form} title="" schemaKey="shipping" states={provinces} />
      <div className="mt-5"></div>
    </>
  );
};
