import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8811 6.62688C10.4992 6.62688 8.96097 8.06591 8.82864 9.88537L10.8962 10.2989C11.0782 9.20721 11.7729 8.56212 12.8811 8.56212C13.9397 8.56212 14.7005 9.32299 14.7005 10.2658C14.7005 11.2417 13.9562 11.986 12.9142 11.986H11.6571V15.3107H13.6585V13.6401C15.5441 13.4085 16.8343 12.2837 16.8343 10.3154C16.8343 8.09899 15.1471 6.62688 12.8811 6.62688ZM12.633 16.9813C11.8721 16.9813 11.3263 17.5767 11.3263 18.2549C11.3263 18.9661 11.8721 19.512 12.633 19.512C13.3938 19.512 13.9397 18.9661 13.9397 18.2549C13.9397 17.5767 13.3938 16.9813 12.633 16.9813Z"
      fill="#69676D"
      className={className}
    />
    <circle cx="12.75" cy="12.75" r="11.7162" stroke="#69676D" strokeWidth="2.06757" />
  </svg>
);

export default Icon;
