import { FC, useCallback, useRef, useState } from 'react';
import { Checkbox, Form, Input } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { IdealBankElement } from '@stripe/react-stripe-js';
import { update } from 'lodash';
import { useForm } from 'react-hook-form';
import { OrderDeliveryItem, useCheckoutContext } from 'frontastic/tastics/checkout/contexts/checkout.context';

interface ShippingPlaningProps {
  orderItem: OrderDeliveryItem;
  indexOrderItem: number;
  form: any;
}

interface MessageWriteProps {
  messageFor: string[];
  shippingPlandIndex: number;
}

export const OrderDeliveryItemGiftMessage: FC<ShippingPlaningProps> = ({ orderItem, indexOrderItem, form }) => {
  const [oneForAll, setOneForAll] = useState(
    !orderItem.shippingPlanning[indexOrderItem <= orderItem.shippingPlanning.length - 1 ? indexOrderItem : 0]
      .oneMessagePerRecipients,
  );
  const [inputId, setInputId] = useState(' ');
  const [{ orderDelivery }, { setOrderDelivery }] = useCheckoutContext();

  const emojisSymbolsRanges = /[^a-zA-Z0-9!@#$%^&*()-=+<>~,.? ]/g;

  const [errorMessage, setErrorMessage] = useState('');

  const validateInput = (e) => {
    const { value } = e.target;
    const sanitizedValue = removeEmojis(value);
    if (value !== sanitizedValue) {
      e.target.value = sanitizedValue;
    }
    if (emojisSymbolsRanges.test(value)) {
      setErrorMessage(
        'Only letters, numbers, and the following symbols are permitted for this field: !@#$%^&*()-=+<>~,.?',
      );
    } else {
      setErrorMessage('');
    }
  };

  const removeEmojis = (text) => {
    return text.replace(emojisSymbolsRanges, '');
  };

  const updateOneMessagePerRecipients = (value) => {
    const newOrderDelivery = [...orderDelivery];
    newOrderDelivery[indexOrderItem].shippingPlanning[indexOrderItem].oneMessagePerRecipients = !value;
    setOrderDelivery(newOrderDelivery);
    setOneForAll(value);
  };

  const MessageWriter: FC<MessageWriteProps> = ({ messageFor, shippingPlandIndex }) => {
    const _idx = shippingPlandIndex !== -1 ? shippingPlandIndex : 0;

    const updateMessage = (value, lineNumber, id) => {
      const newOrderDelivery = [...orderDelivery];

      if (shippingPlandIndex === -1) {
        for (const i in newOrderDelivery[indexOrderItem].shippingPlanning) {
          newOrderDelivery[indexOrderItem].shippingPlanning[i][`giftMessageLine${lineNumber}`] = value;
        }
      } else {
        newOrderDelivery[indexOrderItem].shippingPlanning[_idx][`giftMessageLine${lineNumber}`] = value;
      }
      setOrderDelivery(newOrderDelivery);

      setInputId(id);
    };

    const _inputId = `orderIdx ${indexOrderItem} sp ${_idx} `;

    return (
      <div className="flex flex-col  border-t border-dotted px-6  py-8 lg:flex-row">
        <div className="lg:w-3/12">
          <Typography variant="body" className="font-bold">
            My Gift Message for
          </Typography>

          <div className="space-y-2 pt-2">
            {messageFor.map((item) => (
              <div key={`${item}`}>{item}</div>
            ))}
          </div>
        </div>
        <div className="lg:w-9/12">
          <>
            <Form className="relative mt-4" id="gift-message-prev-next-btns">
              <div className=" lg:p-2.5 lg:px-10 ">
                <Form.Section>
                  <div className="gift-message-card-bg bg-norepeat bg-center pb-8 pt-24">
                    <div className="mx-auto w-[85%] space-y-2">
                      <Form.Control name="giftMessage1">
                        <Input
                          placeholder="Add your message"
                          maxLength={40}
                          value={
                            orderDelivery[indexOrderItem].shippingPlanning[
                              shippingPlandIndex !== -1 ? shippingPlandIndex : 0
                            ].giftMessageLine1
                          }
                          autoFocus={inputId === _inputId + '1'}
                          onChange={(e) => {
                            validateInput(e);
                            updateMessage(e.target.value, 1, _inputId + '1');
                          }}
                        />
                      </Form.Control>
                      <Form.Control name="giftMessage2">
                        <Input
                          maxLength={40}
                          value={
                            orderDelivery[indexOrderItem].shippingPlanning[
                              shippingPlandIndex !== -1 ? shippingPlandIndex : 0
                            ].giftMessageLine2
                          }
                          autoFocus={inputId === _inputId + '2'}
                          onChange={(e) => {
                            validateInput(e);
                            updateMessage(e.target.value, 2, _inputId + '2');
                          }}
                        />
                      </Form.Control>
                      <Form.Control name="giftMessage3">
                        <Input
                          maxLength={40}
                          value={
                            orderDelivery[indexOrderItem].shippingPlanning[
                              shippingPlandIndex !== -1 ? shippingPlandIndex : 0
                            ].giftMessageLine3
                          }
                          autoFocus={inputId === _inputId + '3'}
                          onChange={(e) => {
                            validateInput(e);
                            updateMessage(e.target.value, 3, _inputId + '3');
                          }}
                        />
                      </Form.Control>
                      <Form.Control name="giftMessage4">
                        <Input
                          maxLength={40}
                          value={
                            orderDelivery[indexOrderItem].shippingPlanning[
                              shippingPlandIndex !== -1 ? shippingPlandIndex : 0
                            ].giftMessageLine4
                          }
                          autoFocus={inputId === _inputId + '4'}
                          onChange={(e) => {
                            validateInput(e);
                            updateMessage(e.target.value, 4, _inputId + '4');
                          }}
                        />
                      </Form.Control>
                      <Form.Control name="giftMessage5">
                        <Input
                          maxLength={40}
                          value={
                            orderDelivery[indexOrderItem].shippingPlanning[
                              shippingPlandIndex !== -1 ? shippingPlandIndex : 0
                            ].giftMessageLine5
                          }
                          autoFocus={inputId === _inputId + '5'}
                          onChange={(e) => {
                            validateInput(e);
                            updateMessage(e.target.value, 5, _inputId + '5');
                          }}
                        />
                      </Form.Control>
                      <p className="mt-4 text-xs">Total 200 character limit. 40 character limit on each row.</p>
                      {errorMessage && <p className="mt-4 text-xs text-primary-800">{errorMessage}</p>}
                    </div>
                  </div>
                </Form.Section>
              </div>
            </Form>
          </>
        </div>
      </div>
    );
  };

  return (
    <div className="my-8 rounded-lg border">
      <section className=" px-6 py-8">
        <div className="flex space-x-3">
          {orderItem.lineItem.variant?.images && (
            <div className="h-[80px] w-[80px] flex-none">
              <img
                src={orderItem.lineItem.variant?.images[0]}
                alt={orderItem.lineItem.name}
                className="h-full w-full object-cover"
              />
            </div>
          )}
          <div className="flex w-full flex-col space-y-1 lg:flex-row lg:justify-between">
            <div>
              <p className="text-lg">{orderItem.lineItem.name}</p>
              {orderItem.lineItem.variant?.attributes?.cookie_flavor?.label && (
                <p className="text-sm">
                  <span>Assortment: </span>
                  <span className="font-semibold">{orderItem.lineItem.variant?.attributes.cookie_flavor.label}</span>
                </p>
              )}
              {orderItem.lineItem.variant?.attributes?.tin_color?.label && (
                <p className="text-sm">
                  <span>Tin Color: </span>
                  <span className="font-semibold">{orderItem.lineItem.variant?.attributes.tin_color.label}</span>
                </p>
              )}
            </div>
            <Typography
              variant="body"
              className="font-semibold text-black-900"
            >{`${orderItem.quantity} Gift Recipients`}</Typography>
          </div>
        </div>

        <div className="flex w-full items-center justify-end space-x-2">
          <input
            type="checkbox"
            checked={oneForAll}
            onChange={() => updateOneMessagePerRecipients(!oneForAll)}
            className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-400"
          />
          <Typography variant="body" className="ml-2">
            Write One Gift Message for All Recipients
          </Typography>
        </div>
      </section>

      {oneForAll && (
        <MessageWriter
          shippingPlandIndex={-1}
          messageFor={orderItem.shippingPlanning.map((sp) => `${sp.address.firstName} ${sp.address.lastName}`)}
        />
      )}

      {!oneForAll &&
        orderItem.shippingPlanning.map((sp, index) => (
          <MessageWriter
            shippingPlandIndex={index}
            key={sp.address.id}
            messageFor={[`${sp.address.firstName} ${sp.address.lastName}`]}
          />
        ))}
    </div>
  );
};
