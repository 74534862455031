import { HeroDoubleWithMediaExtended } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/hero-sections/double-with-media';

export const HeroDoubleWithMedia = ({ data }) => {
  const formatData = {
    ...data,
    callToAction: data?.callToAction?.[0]?.name ? data?.callToAction : null,
  };
  const cta = data?.callToAction[0];
  const ctaLabelWeight = cta.labelBold ? 'font-bold' : 'font-normal';
  const ctaLabelItalic = cta.labelItalic ? 'italic' : 'not-italic';
  const ctaLabelFontSize = cta.labelFontSize ? `text-[${cta.labelFontSize}px]` : 'text-xs';

  return (
    <HeroDoubleWithMediaExtended
      data={formatData}
      extendedClasses={{ ctaLabel: `${ctaLabelWeight} ${ctaLabelItalic} ${ctaLabelFontSize}` }}
    />
  );
};
