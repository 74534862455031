import React, { useState } from 'react';

const CustomLoader = ({ width = 70, height = 40, color = '#b7012a' }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const renderImages = () => {
    if (imageError) {
      return (
        <>
          <circle cx="18" cy="12" r="0" fill={color}>
            <animate
              attributeName="r"
              begin=".67"
              calcMode="spline"
              dur="1.5s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
          <circle cx="12" cy="12" r="0" fill={color}>
            <animate
              attributeName="r"
              begin=".33"
              calcMode="spline"
              dur="1.5s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
          <circle cx="6" cy="12" r="0" fill={color}>
            <animate
              attributeName="r"
              begin="0"
              calcMode="spline"
              dur="1.5s"
              keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
              repeatCount="indefinite"
              values="0;2;0;0"
            />
          </circle>
        </>
      );
    } else {
      return (
        <>
          <image
            href="https://res.cloudinary.com/dlwdq84ig/image/upload/v1683222302/igqopbphrqhdtp1gcuwj.png"
            x="2"
            y="8"
            width="20"
            height="8"
            fill={color}
            onError={handleImageError}
          >
            <animate attributeName="opacity" begin="0s" dur="1.5s" values="0;1;0;0" repeatCount="indefinite" />
          </image>
          <image
            href="https://res.cloudinary.com/dlwdq84ig/image/upload/v1683222302/igqopbphrqhdtp1gcuwj.png"
            x="12"
            y="8"
            width="20"
            height="8"
            fill={color}
            onError={handleImageError}
          >
            <animate attributeName="opacity" begin=".33s" dur="1.5s" values="0;1;0;0" repeatCount="indefinite" />
          </image>
          <image
            href="https://res.cloudinary.com/dlwdq84ig/image/upload/v1683222302/igqopbphrqhdtp1gcuwj.png"
            x="22"
            y="8"
            width="20"
            height="8"
            fill={color}
            onError={handleImageError}
          >
            <animate attributeName="opacity" begin=".67s" dur="1.5s" values="0;1;0;0" repeatCount="indefinite" />
          </image>
        </>
      );
    }
  };

  return (
    <div className="flex h-0 items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="12 0 20 20">
        {renderImages()}
      </svg>
    </div>
  );
};

export default CustomLoader;
