import moment from 'moment';

const durationRegex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function parseISO8601Pattern(pattern) {
  let [repetitions, startDate, duration, endDate] = pattern.split('/');

  const weekendsOnly = repetitions.toUpperCase().startsWith('W');
  repetitions = repetitions.replace(/W/i, '').replace('R', '');

  let rep = repetitions ? parseInt(repetitions, 10) : 1;

  // Handling 'W/' case
  if (weekendsOnly && !startDate && !duration && !endDate) {
    startDate = moment().startOf('year');
    endDate = moment().add(1, 'year');
  }

  if (!startDate && !duration && endDate) {
    startDate = moment().format();
  }

  if (repetitions && moment(repetitions, moment.ISO_8601, true).isValid()) {
    startDate = repetitions;
    rep = 1;
  }

  if (startDate && typeof startDate === 'string' && startDate.startsWith('P')) {
    duration = startDate;
    startDate = null;
  }

  let period;
  if (duration && duration.startsWith('P')) {
    period = durationRegex.exec(duration);
  }

  startDate = startDate ? moment(startDate) : moment();

  if (!endDate && period) {
    endDate = startDate.clone().add({
      years: period[1] || 0,
      months: period[2] || 0,
      weeks: period[3] || 0,
      days: period[4] || 0,
      hours: period[5] || 0,
      minutes: period[6] || 0,
      seconds: period[7] || 0,
    });
  }

  if (!startDate && duration && endDate) {
    startDate = moment(endDate).subtract({
      years: period[1] || 0,
      months: period[2] || 0,
      weeks: period[3] || 0,
      days: period[4] || 0,
      hours: period[5] || 0,
      minutes: period[6] || 0,
      seconds: period[7] || 0,
    });
  }

  endDate = endDate ? moment(endDate) : startDate.clone().add(1, 'year');

  const result = weekendsOnly
    ? calculateWeekendsOnly(startDate, endDate, rep)
    : [{ startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') }];

  if (result.length === 1) {
    return result[0];
  }

  return result;
}

function calculateWeekendsOnly(startDate, endDate, rep) {
  const result = [];
  const current = startDate.clone();

  while (current <= endDate) {
    if (weekdays[current.day()] === 'Friday' && (rep === 1 || result.length < rep)) {
      result.push({
        startDate: current.format('YYYY-MM-DD'),
        endDate: current.clone().add(2, 'days').format('YYYY-MM-DD'), // Adding 2 days to cover Friday to Sunday
      });
      current.add(2, 'days'); // Skip Saturday and Sunday after adding the weekend
    } else {
      current.add(1, 'days');
    }
  }
  return result;
}
