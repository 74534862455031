import { RemoveAccountAddressPayload } from '@commercetools/frontend-composable-commerce/lib/types/payloads/AccountPayloads';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sdk } from '@/sdk';

export function useRemoveAddress() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['delete:address'],
    mutationFn: (payload: RemoveAccountAddressPayload) => sdk.composableCommerce.account.removeAddress(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get:account'],
      });
    },
  });
}
