import { Markdown } from '@ariessolutionsio/primitives-richproducts';

export const CategoryLandingPageBannerText = ({ pageFolder }) => {
  const extraDescription = pageFolder.dataSourceConfigurations[0].preloadedValue.extraDescription
    ? pageFolder.dataSourceConfigurations[0].preloadedValue.extraDescription
    : null;

  if (extraDescription) {
    return (
      <div className="relative w-full space-y-5 p-8 md:px-20 md:py-14">
        <Markdown>{extraDescription}</Markdown>
      </div>
    );
  } else {
    return null;
  }
};
