import React, { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { LinkElement } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Link';
import { LinkButton } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/LinkButton';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { cn } from '@ariessolutionsio/react-ecomm-ui/dist/utils/classNames';
import { useAccount } from 'frontastic/provider';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import AccountTabsMobile from '../account-tabs-mobile';

export interface AccountTab {
  label: string;
  value: string;
}

interface SidemenuProps {
  contentTitle?: string;
  hash?: string;
  backButton?: {
    title?: string;
    href?: string;
  };
}

const Sidemenu: React.FC<React.PropsWithChildren<SidemenuProps>> = ({ children, contentTitle, hash, backButton }) => {
  const { push } = useRouter();
  const { loggedIn, loading } = useAccount();

  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const tabs = useMemo<AccountTab[]>(() => {
    return [
      { label: formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' }), value: '/my-account' },
      {
        label: formatAccountMessage({ id: 'address.book', defaultMessage: 'Address Book' }),
        value: '/my-account/addresses',
      },
      { label: formatAccountMessage({ id: 'my.orders', defaultMessage: 'My Orders' }), value: '/my-account/orders' },
      {
        label: formatAccountMessage({ id: 'account.catalog', defaultMessage: 'Corporate Catalogs' }),
        value: '/my-account/catalog',
      },
    ];
  }, [formatAccountMessage]);

  const tabButtonLabelClassNames = useCallback(
    (tab: AccountTab) => {
      const valueclass = `hover:underline ${tab.value === hash ? 'font-bold' : 'text-current'}`;
      return valueclass;
    },
    [hash],
  );

  // Redirect to create account page if user is not logged in
  useEffect(() => {
    if (!loggedIn && !loading) {
      push('/my-account/create');
    }
  }, [loggedIn, loading]);

  return (
    <>
      <div className="relative flex lg:my-20">
        <div className={cn('sticky w-0 self-start lg:w-[25%]')}>
          <div className="hidden h-full w-full flex-col justify-between lg:block">
            <div className="flex flex-col px-10">
              {tabs.map((tab) => (
                <LinkButton
                  key={tab.label}
                  variant="text"
                  palette="none"
                  className={cn(['justify-start whitespace-nowrap px-0 text-sm capitalize'])}
                  onClick={() => push(tab.value)}
                >
                  {
                    <Typography className={cn(tabButtonLabelClassNames(tab))} fontSize={16}>
                      {tab.label}
                    </Typography>
                  }
                </LinkButton>
              ))}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col lg:items-start lg:border-l lg:border-neutral-400">
          <div className="w-full pb-12">
            <div className="mt-10 lg:mt-0 lg:px-12">
              <div className="mx-auto mb-3.5 block text-center lg:text-left">
                <Typography
                  variant="h2"
                  tagName="h2"
                  className={cn([`${hash === '/my-account' ? 'lg:hidden' : 'lg:block'} text-black`])}
                >
                  {contentTitle}
                </Typography>
              </div>
              {hash === '/my-account' && (
                <Typography fontSize={14} className="block text-center lg:hidden">
                  {formatAccountMessage({
                    id: 'account.desc',
                    defaultMessage: 'Manage your account & subscriptions.',
                  })}
                </Typography>
              )}
              {contentTitle && <AccountTabsMobile hash={hash} tabs={tabs} className="my-6" />}
              {backButton && (
                <div className="my-5 ml-8 flex items-center text-sm font-bold uppercase tracking-wide lg:hidden">
                  <span className="mr-4">
                    <svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.01678 20.1248C8.81136 20.1255 8.6084 20.0801 8.4228 19.9921C8.23721 19.9041 8.07369 19.7755 7.94428 19.616L1.30303 11.366C1.10079 11.12 0.990234 10.8114 0.990234 10.4929C0.990234 10.1744 1.10079 9.8658 1.30303 9.61977L8.17803 1.36977C8.41142 1.08897 8.7468 0.912387 9.11038 0.878865C9.47397 0.845343 9.83598 0.957628 10.1168 1.19102C10.3976 1.42441 10.5742 1.75979 10.6077 2.12337C10.6412 2.48696 10.5289 2.84897 10.2955 3.12977L4.14928 10.4998L10.0893 17.8698C10.2574 18.0716 10.3642 18.3174 10.3971 18.578C10.4299 18.8386 10.3874 19.1032 10.2746 19.3404C10.1617 19.5777 9.98331 19.7776 9.76041 19.9166C9.53751 20.0556 9.27945 20.1278 9.01678 20.1248Z"
                        fill="#2B2A2D"
                      />
                    </svg>
                  </span>
                  <LinkElement href={backButton.href}>{backButton.title}</LinkElement>
                </div>
              )}
              <div key={hash}>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidemenu;
