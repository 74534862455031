import { FC, useState } from 'react';
import { Button } from '@ariessolutionsio/primitives-richproducts';
import { Address } from '@ariessolutionsio/providers-richproducts';
import { ChevronUpDownIcon, PencilIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import type { ColumnDef } from '@tanstack/react-table';
import { add } from 'lodash';
import { Incrementer } from './incrementer';

export const getColumns = (
  editCallback: any,
  onSingleSelect: any,
  onSelectedItemInMultiSelect?: any,
  onChangeSelectable?: any,
  allowIncrement?: boolean,
  close?: any,
) => {
  interface AddressRow extends Address {
    isSelectable: boolean;
    quantitySelected: number;
  }

  {
    /* <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')} className="w-32">
          Last Name
          <ChevronUpDownIcon className="ml-1 h-4 w-4" />
        </Button> */
  }
  const columns: ColumnDef<AddressRow>[] = [
    {
      accessorKey: 'firstName',
      cell: ({ row }) => <div className="font-semibold">{row.original.firstName}</div>,
      header: ({ column }) => <div>First Name</div>,
    },
    {
      accessorKey: 'lastName',
      cell: ({ row }) => <div className="font-semibold">{row.original.lastName}</div>,
      header: ({ column }) => <div>Last Name</div>,
    },
    {
      accessorKey: 'company',
      header: () => <div>Company</div>,
      cell: ({ row }) => <div>{row.original.company}</div>,
    },
    {
      accessorFn: (row) => `${row.streetNumber ?? ''} ${row.streetName ?? ''}`,
      header: 'Address',
    },
    {
      accessorKey: 'city',
      header: ({ column }) => <div>City</div>,
      cell: ({ row }) => <div>{row.original.city}</div>,
    },
    {
      accessorKey: 'state',
      cell: ({ row }) => <div>{row.original.state}</div>,
      header: ({ column }) => <div>State</div>,
    },
    {
      accessorKey: 'postalCode',
      header: 'Zip',
    },
    {
      id: 'edit',
      header: ({ column }) => <div className="ml-1 h-4 w-4" />,
      cell: ({ row }) => (
        <Button
          variant="link"
          onClick={() => editCallback.open({ addressId: row.original.id })}
          className="text-red-700"
        >
          <PencilIcon className="mr-1 h-5 w-5" />
          Edit
        </Button>
      ),
    },
    {
      id: 'select',
      header: ({ column }) => <div className="ml-1"></div>,
      cell: ({ row }) => (
        <Button
          variant="default"
          onClick={() => {
            onSingleSelect(row.original);
            close();
          }}
          className="text-semibold bg-[#2B2A2D] text-white hover:bg-[#2B2A2D] hover:text-white"
        >
          SELECT
        </Button>
      ),
    },
    {
      id: 'incrementer',
      header: ({ column }) => <div className="ml-1 h-4 w-4" />,
      cell: ({ row }) =>
        row.original.isSelectable ? (
          <div className="flex w-20 justify-center">
            <Incrementer
              initialValue={row.original.quantitySelected}
              allowIncrement={allowIncrement}
              onChange={(value) => onSelectedItemInMultiSelect(row.original, value)}
            />
          </div>
        ) : (
          <div className="w-20"></div>
        ),
    },
    {
      id: 'individualSelect',
      header: ({ column }) => <div className="ml-1 w-8"></div>,
      cell: ({ row }) => (
        <input
          type="checkbox"
          checked={row.original.isSelectable}
          key={`${row.original.id}`}
          onChange={(e) => onChangeSelectable(row.original, e.target.checked)}
        />
      ),
    },
  ];

  return !onSelectedItemInMultiSelect
    ? columns.filter((c) => c.id !== 'incrementer').filter((c) => c.id !== 'individualSelect')
    : columns.filter((c) => c.id !== 'select');
};
