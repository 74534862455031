import { useState } from 'react';
import { NewsletterSubscriptionComponent } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/newsletter-subscription/newsletter-subscription';
import { SubscriptionConfirmationComponent } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/subscription-confirmation/newsletter-suscription-confirmation';
import { ErrorMsgIcon } from '@ariessolutionsio/cc-custom-components/dist/utils/icons';
import { useKlaviyo } from 'frontastic/provider';

const NewsLetterSubscriptionTastic: React.FC = (props: any) => {
  const {
    data: {
      title,
      helpingText,
      legalUrl,
      legalText,
      errorMsg,
      buttonText,
      successIcon,
      successTitle,
      successHelpingText,
    },
  } = props;

  const [isSubscribed, setIsSubscribed] = useState(false);
  const { subscribeToNewsletter } = useKlaviyo();
  const signUpFunction = async (emailAddress: string) => {
    {
      const response = await subscribeToNewsletter({ email: emailAddress });
      if (response.success) {
        setIsSubscribed(true);
      }
    }
  };

  return (
    <>
      {!isSubscribed ? (
        <NewsletterSubscriptionComponent
          data={{
            title,
            helpingText,
            legalUrl,
            legalText,
            errorMsg,
            buttonText,
            errorMsgIcon: ErrorMsgIcon,
            signUpFunction,
          }}
        />
      ) : (
        <SubscriptionConfirmationComponent
          data={{
            icon: successIcon,
            title: successTitle,
            helpingText: successHelpingText,
          }}
        />
      )}
    </>
  );
};

export default NewsLetterSubscriptionTastic;
