import { FC, useMemo, useState } from 'react';
import { Text } from '@ariessolutionsio/primitives-richproducts';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { Typography } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Typography';
import { getCookie } from 'cookies-next';
import { useAzure } from 'frontastic/provider';
import { EmailResponse } from 'frontastic/provider/frontastic/UseAzure';

export const SuccessVerifyEmailPendingTastic: FC = (props: any) => {
  const { title } = props.data;

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);

  const { sendAccountVerificationEmail } = useAzure();

  const handleResendOnclick = async () => {
    setLoading(true);
    try {
      const response: EmailResponse = await sendAccountVerificationEmail();
      if (response && response.success) setMessage('Email sent successfully!');
      else {
        setMessage(response.message ?? 'Failed to send email at this time.');
      }
    } catch (err) {
      setError(true);
      setMessage('Invalid request or email already verified.');
    } finally {
      setLoading(false);
    }
  };

  // This method will call the nextjs api whichis in the FE
  // Not used(we may need later)
  const handleResendOnclickByPassCoFe = async () => {
    setLoading(true);
    try {
      const response: any = await sendAccountVerificationEmail();
      if (response && response.success) {
        console.log('send resp', response);
        fetch('/api/sendEmailAzureApi', {
          method: 'POST',
          body: JSON.stringify({
            message: response.body.message,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((response) => response.json())
          .then(async (data) => {
            console.log('fin rese email', data);
          })
          .catch((error) => {
            console.error('Unable send email:', error);
          });
        //setMessage('Email sent successfully!');
      }
    } catch (err) {
      setError(true);
      setMessage('Invalid request or email already verified.');
    } finally {
      setLoading(false);
    }
  };

  const renderEmailStatusMessage = (msg: string) => {
    return <Text className={`text-md font-semibold ${error ? `text-primary-800` : `text-green-600`}`}>{msg}</Text>;
  };

  const memoizedEmailStatusMessage = useMemo(() => renderEmailStatusMessage(message), [message]);
  const email = getCookie('email_to_verify') || '';

  return (
    <div className=" my-16 flex items-center justify-center ">
      <div className="w-full rounded bg-white px-2 py-16 lg:max-w-[850px] lg:p-0">
        <div className="flex flex-col items-center justify-center space-y-6 px-4 py-16 md:px-32">
          <Typography variant="h3" className="text-center leading-10">
            {title}
          </Typography>
          <Typography variant="body" className="text-center">
            {`We sent an email to ${email} with the link to verify. If you don’t see this email, please check your spam folder.`}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleResendOnclick}>
            {loading ? 'RESENDING...' : 'RESEND EMAIL'}
          </Button>
          {message !== '' && memoizedEmailStatusMessage}
        </div>
      </div>
    </div>
  );
};
