// @ts-nocheck
import { Form, Text } from '@ariessolutionsio/react-ecomm-ui/dist';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useFormContext } from 'react-hook-form';
import { PaymentForm } from 'frontastic/tastics/checkout/schemas/payment.schema';
import { useStripeElementOptions } from './use-stripe-element-options';
import CardIcon from '../../../../../../components/icons/card';

export const CheckoutPaymentForm = () => {
  const {
    control,
    formState: { errors },
    setError,
    setValue,
  } = useFormContext<PaymentForm>();
  const { cardNumberOptions, cardExpiryOptions, cardCvcElementOptions } = useStripeElementOptions();

  let stripePromise = null;

  const paymentOptions = {
    mode: 'setup',
    currency: 'usd',
  };

  if (process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY) {
    stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY);
  }
  return (
    <div>
      {stripePromise && (
        <Elements stripe={stripePromise} options={paymentOptions}>
          <PaymentElement />
        </Elements>
      )}
    </div>
  );

  /*
  return (
    <Form.Section title="Payment Details">
      <div className="max-w-[512px] flex-1 flex-col space-y-[14px] rounded-lg border border-slate-300 px-[31px] py-[20px]">
        <div className="flex flex-row items-center space-x-[15px] align-middle">
          <input type="radio" checked={true} color="#B7002A" className="text-[#000000]" />
          <CardIcon className="fill-[#B7002A] stroke-[#B7002A]" />
          <Text className="text-[#B7002A]">Card</Text>
        </div>
        <Form.Control
          name="paymentDetails.cardNumber"
          label="Card number"
          error={errors?.paymentDetails?.cardNumber?.message}
          control={control}
        >
          <CardNumberElement
            onChange={(e) => {
              if (e.complete) {
                setValue('paymentDetails.cardNumber', true);
                setError('paymentDetails.cardNumber', {
                  message: null,
                });
              } else {
                setValue('paymentDetails.cardNumber', false);
              }

              if (e.error) {
                setValue('paymentDetails.cardNumber', false);
                setError('paymentDetails.cardNumber', {
                  message: 'The entered number is not valid',
                });
              } else {
                setError('paymentDetails.cardNumber', null);
              }

              return e;
            }}
            options={cardNumberOptions}
            className="block w-full rounded-md border border-slate-300 bg-white px-3 py-2 shadow-sm placeholder:text-slate-400 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none sm:text-sm"
          />
        </Form.Control>
        <div className="grid grid-cols-1 gap-[21px] sm:md:grid-cols-2">
          <Form.Control
            name="cardExpiry"
            label="Expiration"
            error={errors?.paymentDetails?.cardExpiry?.message}
            control={control}
          >
            <CardExpiryElement
              onChange={(e) => {
                if (e.complete) {
                  setValue('paymentDetails.cardExpiry', true);
                  setError('paymentDetails.cardExpiry', {
                    message: null,
                  });
                } else {
                  setValue('paymentDetails.cardExpiry', false);
                }

                if (e.error) {
                  setValue('paymentDetails.cardExpiry', false);
                  setError('paymentDetails.cardExpiry', {
                    message: 'The entered number is not valid',
                  });
                } else {
                  setError('paymentDetails.cardExpiry', null);
                }

                return e;
              }}
              options={cardExpiryOptions}
              className="block w-full rounded-md border border-slate-300 bg-white px-3 py-2 shadow-sm placeholder:text-slate-400 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none sm:text-sm"
            />
          </Form.Control>
          <Form.Control name="cardCvc" label="CVC" error={errors?.paymentDetails?.cardCvc?.message} control={control}>
            <CardCvcElement
              onChange={(e) => {
                if (e.complete) {
                  setValue('paymentDetails.cardCvc', true);
                  setError('paymentDetails.cardCvc', {
                    message: null,
                  });
                } else {
                  setValue('paymentDetails.cardCvc', false);
                }

                if (e.error) {
                  setValue('paymentDetails.cardCvc', false);
                  setError('paymentDetails.cardCvc', {
                    message: 'The entered number is not valid',
                  });
                } else {
                  setError('paymentDetails.cardCvc', null);
                }

                return e;
              }}
              options={cardCvcElementOptions}
              className="block w-full rounded-md border border-slate-300 bg-white px-3 py-2 shadow-sm placeholder:text-slate-400 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none sm:text-sm"
            />
          </Form.Control>
        </div>
      </div>
    </Form.Section>
  );*/
};
