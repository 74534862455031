/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Order, useAsio } from '@ariessolutionsio/providers-richproducts';
import { DisplayPrice } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/templates/DisplayPrice';
import Spinner from 'components/commercetools-ui/spinner';
import { useFormat } from 'helpers/hooks/useFormat';
import { getAttributeLabel } from 'helpers/utils/utils';
import useSWR from 'swr';
import Image from 'frontastic/lib/image';

export interface Props {
  showOrderDetail: (detailInfo: { show: boolean; orderData: unknown }) => void;
}

const OrdersHistory: FC<Props> = ({ showOrderDetail }) => {
  const [{ orders }] = useAsio();
  const { data: ordersData, isValidating } = useSWR('orders', () => orders.getOrders({ limit: 499, offset: 0 }));

  const ordersFormated = useMemo(() => {
    if (ordersData && ordersData.results.length > 0) {
      return ordersData.results.sort((a: Order, b: Order) => {
        const date1 = new Date(a?.createdAt).valueOf();
        const date2 = new Date(b?.createdAt).valueOf();

        return date2 - date1;
      });
    }

    return [];
  }, [ordersData]);

  //18in messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  return (
    <div>
      <div className="m-4 lg:m-0">
        {isValidating ? (
          <div className="flex items-stretch justify-center px-12 py-10">
            <Spinner />
          </div>
        ) : ordersFormated && ordersFormated.length > 0 ? (
          ordersFormated.map((order) => (
            <div key={order?.id} className="mt-10 rounded-lg bg-white p-8 lg:min-h-[407px] lg:w-[1065px] lg:p-16">
              <div className="items-center justify-between rounded-lg lg:flex lg:min-h-[46px] lg:min-w-[955px]">
                {' '}
                <div>
                  <div className="text-sm font-bold uppercase">
                    {' '}
                    {formatAccountMessage({
                      id: 'orders.id',
                      defaultMessage: 'Order #',
                    })}
                    {order?.orderNumber}
                  </div>
                  <div className="mt-2 text-[16px] font-normal">
                    {' '}
                    <h5 className="text-[16px] font-normal">
                      {new Date(order?.createdAt)?.toLocaleDateString() ?? 'NA'}
                    </h5>
                  </div>
                  <div className="mt-2  text-[16px] font-normal">
                    {formatAccountMessage({
                      id: 'orders.total.amount',
                      defaultMessage: 'Order Total: ',
                    })}
                    <DisplayPrice
                      price={order?.totalPrice}
                      component={({ displayPrice }) => <span>{displayPrice}</span>}
                    />
                  </div>
                </div>
                <div>
                  <button
                    className="border-black mt-2 min-h-[45px] w-full rounded-lg border border-solid text-[12px] font-bold uppercase md:min-w-[166px] lg:mt-0"
                    onClick={() => {
                      showOrderDetail({ show: true, orderData: order });
                    }}
                  >
                    <span className="font-galanoClassic">
                      {formatAccountMessage({
                        id: 'orders.view.invoice',
                        defaultMessage: 'View Invoice',
                      })}
                    </span>
                  </button>
                </div>
              </div>
              <div className="md:flex md:overflow-auto">
                {order?.lineItems?.map((item) => (
                  <div className="mr-12 mt-8 min-h-[166px] w-full md:min-w-[332px]" key={item?.id}>
                    <div className="flex">
                      <div className="mr-2 h-16 w-16">
                        <a href={item?.url}>
                          <Image className="h-16 w-16" alt="line-item-image" src={item?.variant?.images[0]?.url} />
                        </a>
                      </div>
                      <div>
                        <div className="text-[14px] font-semibold">{item?.name ?? 'NA'}</div>
                        <div className="text-[14px] font-normal">
                          {formatAccountMessage({
                            id: 'orders.qnty',
                            defaultMessage: 'Qnty:',
                          })}{' '}
                          {item?.quantity ?? 'NA'}
                        </div>
                        {getAttributeLabel(item?.variant?.attributes, 'assortment') ? (
                          <div className="text-[14px] font-normal">
                            {getAttributeLabel(item?.variant?.attributes, 'assortment')}
                          </div>
                        ) : (
                          <></>
                        )}
                        {getAttributeLabel(item?.variant?.attributes, 'tin_color') ? (
                          <div className="text-[14px] font-normal">
                            {getAttributeLabel(item?.variant?.attributes, 'tin_color')}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="text-[14px] font-normal">
                          <DisplayPrice
                            price={item?.price?.value}
                            component={({ displayPrice }) => <span>{displayPrice}</span>}
                          />
                        </div>
                        <a href={item?.url} className="cursor-pointer text-red-700 underline">
                          {formatAccountMessage({
                            id: 'orders.view.product',
                            defaultMessage: 'View Product',
                          })}
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p className="mt-10 max-w-2xl text-sm text-gray-500">
            {formatAccountMessage({
              id: 'orders.no.orders',
              defaultMessage: 'You have not placed any orders yet! ',
            })}
          </p>
        )}
      </div>
    </div>
  );
};

export default OrdersHistory;
