import { CategoryNavigationCarousel } from '@ariessolutionsio/cc-custom-components/dist/components/PageSections/marketing/hero-sections/category-carousel';

function CategoryCarouselTastic({ data }) {
  const { categories } = data;
  if (data.categories.length === 0) return null;

  return (
    <div className="max-width-[100%]">
      <CategoryNavigationCarousel
        data={{
          categories,
        }}
        extendedClass={{
          root: 'my-0',
          menuItem: 'h-[105px]  w-[170px]',
          menuIcon: 'h-[65px]',
          menuLink: 'pt-[5px]',
          activeText: 'text-[#B7002A]',
        }}
      />
    </div>
  );
}

export default CategoryCarouselTastic;
