// @ts-nocheck
import { addressFormSchema } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/Ecommerce/forms/schemas/address.schema';
import * as Yup from 'yup';

export const paymentSchema = Yup.object().shape({
  billing: addressFormSchema,
  confirmationToken: Yup.string(),
});

export type PaymentForm = Yup.InferType<typeof paymentSchema>;
