import { FC } from 'react';
import { WithVideoBackground } from '@ariessolutionsio/react-ecomm-ui/dist/components/PageSections/marketing/hero-sections/with-video-background';

export const HeroWithVideoBackground: FC = (props: any) => {
  const { data } = props;
  const ctaLabelWeight = data.labelBold ? 'font-bold' : 'font-normal';
  const ctaLabelItalic = data.labelItalic ? 'italic' : 'not-italic';
  const ctaLabelFontSize = data.labelFontSize ? `text-[${data.labelFontSize}px]` : 'text-xs';

  const callToAction = data?.callToAction?.[0]?.name ? data?.callToAction : null;
  const options = {
    autoPlay: data.videoAutoplay,
    controls: data.videoControls,
    loop: data.videoLoop,
    muted: data.videoMuted,
  };

  return (
    <div className="marketing-components-container">
      <WithVideoBackground
        data={{
          ...data,
          callToAction,
          options,
        }}
        extendedClasses={{
          title: 'py-4 md:max-w-xl',
          description: 'pb-4 md:max-w-lg',
          mediaHeight: 'lg:h-[660px]',
          ctaLabelStyle: `${ctaLabelWeight} ${ctaLabelItalic} ${ctaLabelFontSize}`,
        }}
      />
    </div>
  );
};
