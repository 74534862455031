import React, { useEffect, useState } from 'react';
import router, { useRouter } from 'next/router';
import { Input } from '@ariessolutionsio/primitives-richproducts';
import { Form } from '@ariessolutionsio/react-ecomm-ui/dist';
import { Button } from '@ariessolutionsio/react-ecomm-ui/dist/components/atomic/atoms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useAccount } from 'frontastic';

const recoverPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, 'Password must be a minimum of 8 characters long and contain a symbol')
    .matches(/^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/, 'Password must contain a symbol')
    .required('New Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Re-enter your password'),
});

export const ResetPasswordTastic = () => {
  const { push, asPath, query } = useRouter();
  const [isErrorOnResetLink, setIsErrorOnResetLink] = useState(false);
  const { token } = query;
  const { resetPassword, loggedIn } = useAccount();
  const form = useForm({
    resolver: yupResolver(recoverPasswordSchema),
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    if (!token || loggedIn) {
      push('/');
    }
  }, [asPath]);

  const onSubmit = async (values) => {
    let resetToken;
    if (Array.isArray(token)) {
      resetToken = token?.[0];
    } else {
      resetToken = token;
    }
    try {
      const resp = await resetPassword(resetToken, values?.newPassword);
      if (!resp?.infoError) {
        toast.success('Your password updated successfully', {
          duration: 5000,
        });
        push('/my-account');
      } else if (resp?.infoError && !resp?.accountConfirmed) {
        toast.success('Your password updated successfully, Please verify your Email.', {
          duration: 5000,
        });
        push('/');
      } else {
        setIsErrorOnResetLink(true);
      }
      // router.push({ pathname: '/', query: { bootLogin: true } });
    } catch (err) {
      toast.error('Error ocurred on resetting the password', {
        duration: 5000,
      });
      console.log('Error ocurred on resetting the password', err);
    }
  };

  const handleInputTypeChange = () => {
    setIsErrorOnResetLink(false);
  };

  return (
    <Form
      onSubmit={form.handleSubmit(onSubmit)}
      className="space-y-11 bg-white py-[30px] md:p-[30px] lg:mx-[20%] lg:mt-[5%] lg:space-y-[54px] lg:p-[60px]"
      actions={
        <nav className="flex w-full justify-center space-x-4 text-center">
          <Button
            variant="outlined"
            onClick={() => router.push('/')}
            palette="black"
            className="font-semibold"
            type="reset"
          >
            Cancel
          </Button>
          <Button palette="black" type="submit" className="bg-gray-900 text-white">
            Save
          </Button>
        </nav>
      }
    >
      <div>
        <p className="mb-[30px] text-center font-['Akshar'] text-4xl font-bold lg:mb-10">Change Your Password</p>
        <Form.Section>
          <div className="space-y-5 px-5 md:mx-10 lg:mx-10  lg:px-20">
            <Form.Control name="newPassword" label="New Password" error={form?.formState?.errors?.newPassword?.message}>
              <div className="relative">
                <Input.Password {...form.register('newPassword')} onChange={handleInputTypeChange} />
              </div>
            </Form.Control>
            <Form.Control
              name="confirmPassword"
              label="Confirm Password"
              error={form?.formState?.errors?.confirmPassword?.message}
            >
              <div className="relative">
                <Input.Password {...form.register('confirmPassword')} onChange={handleInputTypeChange} />
              </div>
            </Form.Control>
            {isErrorOnResetLink && (
              <p className="text-1xl !m-0 font-['Akshar'] text-primary-800">
                An issue at our end. Please try resetting after sometime.
              </p>
            )}
          </div>
        </Form.Section>
      </div>
    </Form>
  );
};

export default ResetPasswordTastic;
