import React from 'react';
import { markdown } from 'markdown';
import { Log } from 'helpers/errorLogger';

interface Props {
  text: string;
  className?: string;
  breakLines: boolean;
}

const Markdown: React.FC<Props> = ({ text, className, breakLines }) => {
  if (typeof text !== 'string') {
    Log.error(`Markdown: Invalid text property. Expected string but received ${typeof text}`);
    return <></>;
  }

  return (
    <>
      <style>
        {`
        .markdown > * {
          margin: revert;
          padding: revert;
          font-size: revert;
          font-weight: revert;
        }
      `}

        {breakLines == true
          ? `
        .markdown  strong {
            display: block;
          }
        `
          : null}
      </style>

      <div className="markdown mx-auto px-8" dangerouslySetInnerHTML={{ __html: markdown.toHTML(text) }}></div>
    </>
  );
};

export default Markdown;
